import React from "react"
import {PencilSquare} from 'react-bootstrap-icons';

function EditTest (props) {

    return (
        <button className="btn btn-outline-primary" size="sm" ><PencilSquare /></button>
    )
}

export default EditTest;
