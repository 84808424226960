import React, { useState } from "react";
import { Row, Col, Table as TablaBoot, Card, ListGroup, Button, Form, Navbar,Container, Image, InputGroup } from "react-bootstrap";
import axios from "axios";
import fileDownload from 'js-file-download'
import f_logo from '../../imagenes/logo.bmp';
import "@fontsource/roboto";
import './IngresoClientes.css';
import SelectParamTest from "../selectores/SelectParamTest";

function Informe03 (props) {

  const c = new Date().toLocaleString("es-CL", {timeZone: "America/Santiago"});
  const fechoy = c.substring(6,10) + '-' + c.substring(3,5) + '-' + c.substring(0,2);
  //const fechoy = new Date().toISOString().substring(0, 10);


  const [formConsulta, setFormConsulta] = useState({
    'fecha_ini': fechoy,
    'fecha_fin': fechoy,
    'empresa': '99999',
  });
  const [nombres, setNombres] = useState({'ensayo':'', 'elemento':''})
  const [errorForm, setErrorForm] = useState();
  const [mensajeImagen, setMensajeImagen] = useState();
  const [codigoEnsayo, setCodigoEnsayo] = useState();
  const [datosTabla, setDatosTabla] = useState();

  function cambioEnFecha (event) {
    const {value, name} = event.target;
    if (name === 'fecha_ini') {
        setFormConsulta(prevNote => ({...prevNote, [name]: value, ['fecha_fin']: fechoy}))
    }else {
      setFormConsulta(prevNote => ({...prevNote, [name]: value}))
    }
    setErrorForm(null);
    //setMaxFechaIn(value);
    //setValorFechaIn('');
    //console.log(value, name);
  }
  /*
  function cambioEnFechaIn (event) {
    const {value, name} = event.target;
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
    setValorFechaIn(value);
    //console.log(value, name);
  }
  */
  function cambioEnForm (event) {
    //console.log('formConsulta: ', formConsulta)
    const {value, name} = event.target;
    //console.log('cambio form encabezado', name, value);
    setErrorForm(null);
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
    if (name=='elemento'){
      if (value!=''){
      setNombres({'ensayo':'LAT-'.concat(value), 'elemento':value});
    }else {
      setNombres({'ensayo':'', 'elemento':''});
    }
    }
  }
  

  function limpia() {
    //setPdfBase64(null);
    //setNombres({'ensayo':'', 'elemento':''});
    setErrorForm(null);
    setDatosTabla(null);

    /*
    setFormConsulta({
      'elemento': '',
      'valorensayo': '',
      'valorelemento': ''
    })*/
    setFormConsulta({
      'fecha_ini': fechoy,
      'fecha_fin': fechoy,
      'empresa': '99999',
    });
    setMensajeImagen(null);
    setCodigoEnsayo(null);
  }

  async function get_estadistica() {
    //console.log('formConsulta: ', formConsulta)
    await axios.request({
      url: "/api/estadistica",
      method: "POST",
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: formConsulta
  }).then((res) => {
    //console.log('respuesta axios: ', res.data)
    setDatosTabla(res.data.datos)
  }).catch((error) => {
    if (error.response) {
      setErrorForm(error.response.data.msg)
      //console.log(error.response.data.msg)
      //console.log(error.response.status)
      //console.log(error.response.headers)
      }
    });
  }

  async function get_estadistica_xls() {
    //console.log('formConsulta: ', formConsulta)
    await axios.request({
      url: "/api/estadistica_xls",
      method: "POST",
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: formConsulta
  }).then((res) => {
    //console.log('respuesta axios: ', res.data)
    fileDownload(res.data, formConsulta.fecha_ini + '_' + formConsulta.fecha_fin  + ".xlsx");
  }).catch((error) => {
    if (error.response) {
      //console.log(error.response.data.msg)
      //console.log(error.response.status)
      //console.log(error.response.headers)
      }
    });
  }

  return (
    <>
            {props.mostrarCabecera?
            <>
            <Navbar className="bg-white">
              <Container>
                <Navbar.Brand href="#home"><img  src={f_logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras-grande">Laboratorio Ensayo Equipos Dieléctricos</span></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                  <Button onClick={() => {setCodigoEnsayo(null) ;setMensajeImagen(null); setFormConsulta(null); setErrorForm(null); props.setMuestraMenu(true); props.setOpcion1(0)}} >volver</Button>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            <hr />
            </>:null}
        <Row >
          <Col md={{ span: 3, offset: 1 }} >
          <span className="letras-negras">Módulo de Estadísticas de Elementos Ensayados</span>
          </Col>
        </Row>
        <hr />
        <Row>
              {/******************** COLUMNA INICIAL ***********************/}
              <Col xs={2} md={1}></Col>
              <Col xs={12} md={6}>
                      <p><span className = "mensaje-error">{errorForm}</span></p>
              </Col>
                    </Row>
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>

          {/******************** COLUMNA FECHA INICIO ***********************/}
          <Col xs={8} md={4}>
                <Form>
                      {props.perfil=='superusuario'?<>
                        <Form.Group as={Row} className="mb-3" controlId="TipoEmpresa">
                              <Form.Label column sm="4">
                              <span className="campo">
                                Empresa
                              </span>
                              </Form.Label>
                              <Col sm="8">
                                <SelectParamTest parametro="empresa" valorDefault={formConsulta.empresa} cambioEnForm={cambioEnForm} />
                              </Col>
                            </Form.Group>
                      </>:null}
                    <Form.Group as={Row} className="mb-3" controlId="FecIni">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Fecha Inicio
                      </span>
                      </Form.Label>
                      <Col sm="8">
                      <Form.Control value={formConsulta.fecha_ini} max={fechoy} type="date" name="fecha_ini" placeholder="" onChange={cambioEnFecha} />
                      </Col>
                      </Form.Group>

                    {/******************** COLUMNA FECHA FIN ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="FecFin">
                      <Form.Label column sm="4">
                        <span className="campo">Fecha Fin</span>
                      </Form.Label>
                      <Col sm="8">
                      <Form.Control value={formConsulta.fecha_fin} min={formConsulta.fecha_ini} max={fechoy} type="date" name="fecha_fin" placeholder="" onChange={cambioEnFecha} />
                      </Col>
                    </Form.Group>

                  </Form>
                  </Col>
        </Row>
        <Row>
                          {/******************** COLUMNA INICIAL ***********************/}
                          <Col xs={2} md={1}></Col>
                          {/******************** BOTON BUSCAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => get_estadistica()} >Buscar</Button>
                          </Col>

                          {/******************** BOTON LIMPIAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => limpia() } >Limpiar</Button>
                          </Col>
                         
        </Row>
        <hr />
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>
          <Col xs={8} md={4}>
          {mensajeImagen?
          <p><span className = "comentarios">{mensajeImagen}</span></p>:null}
          </Col>
        </Row>
        {datosTabla?
        <div className='contenedor-tabla'>
        <TablaBoot striped bordered hover >
            <thead>
              <tr>
                <th>
                  Elemento
                </th>
                <th>
                  Unidades Ensayadas
                </th>
                <th>
                  Unidades Aprobadas
                </th>
                <th>
                  Unidades Rechazadas
                </th>
                <th>
                  Tasa Falla (%)
                </th>
              </tr>
            </thead>
            <tbody>
              {datosTabla.map((detalle) => 
              <tr key={detalle.nombre} >
                <td>{detalle.nombre}</td>
                <td>{detalle.total}</td>
                <td>{detalle.aprobado}</td>
                <td>{detalle.rechazado}</td>
                <td>{detalle.tasa_falla}</td>
              </tr>
              )
              }
            </tbody>
        </TablaBoot></div>:null
        }
        {datosTabla?<Button onClick={() => get_estadistica_xls()}  >Exporta a Excel</Button>:null}
    </>
  )
};

export default Informe03;