import React from "react"
import {useState} from 'react';
import {Button, Modal, Form, Dropdown, ButtonGroup, Container, Row, Col, Badge, InputGroup, Card} from 'react-bootstrap';
import {Table as TablaBoot} from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';
import axios from 'axios';
import '../estilos/AddNegocio.css';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import '../estilos/AddEnsayo.css';
import {PencilSquare} from 'react-bootstrap-icons';
import {PlusSquare} from 'react-bootstrap-icons';
import ListadoDetalle from "./ListadoDetalle";
import SelectParam from "./selectores/SelectParam";
import SelectParamTest from "./selectores/SelectParamTest";
import fileDownload from 'js-file-download'
//import FormDetalleLoad from "./FormDetalleLoad";
import ListadoDetalleJmp from "./ListadoDetalleJmp";
import ListadoDetalleIngreso from "./ListadoDetalleIngreso";
import EditaCodEnsayo from "./EditaCodEnsayo";

function EditEnsayo (props) {
  //console.log('tipo ensayo: ', props.nombre_tabla);
  //props.nombre_tabla.split("/")[2],
  const rutaCompleta = props.nombre_tabla;

  const today = new Date();
  const [show, setShow] = useState(false);
  const [apruebaVisual, setApruebaVisual] = useState(false);
  const [editaForm, setEditaForm] = useState(true);
  //const [valorTec, setValorTec] = useState({"valor": ""});
  const [maxFechaIn, setMaxFechaIn] = useState(formateaFecha(today));
  const [valorFechaIn, setValorFechaIn] = useState('');
  const [showDetalle, setShowDetalle] = useState(false);
  const [detalleEnsayo, setDetalleEnsayo] = useState([]);
  const [detalleEnsayoJmp, setDetalleEnsayoJmp] = useState([]);
  const [listaDetalleCompleta, setListaDetalleCompleta] = useState(false);
  const [listaDetalleCompletaJmp, setListaDetalleCompletaJmp] = useState(false);
  const [deshabilitaBotonGrabar, setDeshabilitaBotonGrabar] = useState(true);
  const [labelCodEnsayo, setLabelCodEnsayo] = useState(props.celdas.codigo);
  const [serieFormEpp, setSerieFormEpp] = useState('');
  const [validacionEpp, setValidacionEpp] = useState({"error":false, "msg":""});
  const [formDetalleLoadB, setDetalleLoadB] = useState(
    {"serie_epp": '',
    "parches": '',
    "fuga1": '',
	"serie_fabrica": '',
	"patron1": '',
	"patron2": '',
	"patron3": '',
	"tension1": '',
	"tension1": '',
	"tension1": '',
	"medida1": '',
	"medida2": '',
	"AR1": 'R',
	"AR2": 'R',
	"AR3": 'R',
	"ensayoresul": 'RECHAZADO',
	"carcaz":'R',
	"gancho":'R',
	"cancla":'R',
	"contop":'R',
	"apertu":'R',
	"anillo":'R',
	"extiro":'R',
	"citiro":'R',
	"seguro":'R',
	"cubier":'R',
	"contad":'R',
	"insresultado": 'RECHAZADO',
	"resultado": 'RECHAZADO'}
  );
//**************************************************************************** */
  const [formClienteDetalle, setFormClienteDetalle] = useState({
    'serie': '',
    'parches': '',
    'tension': '',
    'fuga1': '',
    'resultado': 'RECHAZADO'
  });
//**************************************************************************** */
  const [erroformClienteDetalle, setErrorformClienteDetalle] = useState({
    'serie': '',
    'parches': '',
    'tension': '',
    'fuga1': '',
  });
//**************************************************************************** */
  const [formClienteDetalleJmp, setFormClienteDetalleJmp] = useState({
    'serie':'',
    'fuga1':'',
    'tension':'',
    'visual': false,
    'dieresul': '',
    'tramo': '',
    'seccion':'',
    'longitud':'',
    'resismedida':'',
    'resismax':'',
    'resisresul':false
  });
//**************************************************************************** */
  const [errorformClienteDetalleJmp, setErrorformClienteDetalleJmp] = useState();

//**************************************************************************** */
  const [formCliente, setFormCliente] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'tipo_ensayo': props.nombre_tabla.split("/")[2],
    'orden_compra': '',
    'dir_ensayo': 'Leonidas Vial 1109, San Miguel, Santiago.',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'estado': '2'
  });
//**************************************************************************** */
  const [errorForm, setErrorForm] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'orden_compra': '',
    'dir_ensayo': '',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
  });
//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
  function cambioEnEppLoad (event) {
    const {value, name} = event.target;
    setSerieFormEpp(value);
}
//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
async function validarEpp (){
  //if (props.formDetalleLoadB.serie.length > 5) {
      if (serieFormEpp > 5) {
      setValidacionEpp({
          error: true,
          msg: 'El valor debe tener máximo 5 cifras'
      })
  }else {
      //let serie_epp = props.formDetalleLoadB.serie.padStart(5, '0')
      let serie_epp = serieFormEpp.padStart(5, '0');
      //setSerieFormEpp(serie_epp)
      
      serie_epp = props.cod_epp.concat('-', serie_epp);
      //console.log('validar', serie_epp);
      await axios({
          method: "POST",
          url:"/api/chequea_epp",
          data: {
            "serie_epp": serie_epp,
          }
        })
        .then((response) => {

          const mensajeBueno = "Serie Epp: ".concat(serie_epp,' Serie de Fábrica: ',response.data.datos);
          setValidacionEpp({
              error: false,
              msg: mensajeBueno
          })
          setDetalleLoadB(prevNote => ({...prevNote, ['serie_fabrica']: response.data.datos, ['serie_epp']: serie_epp}));

          //setMensajeOK('mensaje ok');
          //setValorTec({"valor": response.data.datos[0].tecnico})
          //console.log('llega respuesta de axios', response.data.datos)
          
          setDeshabilitaBotonGrabar(false);
          //setDetalleLoadB(prevNote => ({...prevNote, ['serie_fabrica']: response.data.datos, ['serie_epp']: serie_epp}));
          
          //setDetalleLoadB(prevNote => ({...prevNote, ['serie_epp']: serie_epp}));
          //console.log("Serie Epp: ".concat(serie_epp,' Serie de Fábrica: ',response.data.datos))
          
          //setMensajeOK('mensaje ok');
          //console.log('despues de msj ok');
          
          
          

          //setSerieFormEpp('');
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              //setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
              console.log('axios: error 1: ', error.response.data.msg)
              setDeshabilitaBotonGrabar(true);
              setValidacionEpp({
                  error: true,
                  msg: error.response.data.msg
              })
            } else if (error.response.data.datos) {
              //setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
              console.log('axios: error 2: ', error.response.data.datos)
              setDeshabilitaBotonGrabar(true);
              setValidacionEpp({
                  error: true,
                  msg: error.response.data.datos
              })
            }
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });
      
  }
  
}

//**************************************************************************** */
//  ########################### Funciones para LDB ######################////

function cambioDetalleLoad (event) {
  const {value, name} = event.target;
  if (name=='tension1'){
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (kV)').replace('.',','));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (kV)').replace('.',',')}));
  }else if (name=='tension2') {
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (kV)').replace('.',',')}));
  }else if (name=='tension3') {
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (μV)').replace('.',',')}));
  }else if (name=='medida1') {
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (TΩ)').replace('.',',')}));
  }else if (name=='medida2') {
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (μΩ)').replace('.',',')}));
  }else if (name=='fuga1') {
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (mA)').replace('.',',')}));
  }else if (name=='contad') {
      //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value.padStart(4, '0')}));
  }else {
      setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
  }
  console.log('nombre: ', name, 'valor: ', value);
  

}
//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
function cambiaCheck (event) {
  const {checked, name} = event.target;
  if (checked){
      setDetalleLoadB(prevNote => ({...prevNote, [name]: 'A'}));
  }else{
      setDetalleLoadB(prevNote => ({...prevNote, [name]: 'R'}));
  }
  //console.log('nombre: ', name, 'valor: ', checked);
}

//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
function cambiaCheckResult (event) {
  const {checked, name} = event.target;
  if (checked){
      setDetalleLoadB(prevNote => ({...prevNote, [name]: 'APROBADO'}));
  }else{
      setDetalleLoadB(prevNote => ({...prevNote, [name]: 'RECHAZADO'}));
  }
  //console.log('nombre: ', name, 'valor: ', checked);
}

//**************************************************************************** */
  function agregaTest (formClienteDetalleIngreso) {
    //console.log('form: ', formClienteDetalleIngreso);
  }
//**************************************************************************** */
  function MostrarListado (props){
    //if (props.cod_epp == 'LDB'){
    //  return <FormDetalleLoad setDetalleLoadB={setDetalleLoadB} cod_epp={props.cod_epp} setDeshabilitaBotonGrabar={setDeshabilitaBotonGrabar} deshabilitaBotonGrabar={deshabilitaBotonGrabar} />;
    //}else 
    if (props.cod_epp == 'JMP'){
      return <ListadoDetalleJmp datos={detalleEnsayoJmp} borraElementoDetalle={borraElementoDetalleJmp} setListaDetalleCompleta={setListaDetalleCompletaJmp} />
    }else {
      return <ListadoDetalleIngreso datos={detalleEnsayo} 
                            borraElementoDetalle={borraElementoDetalle} 
                            setListaDetalleCompleta={setListaDetalleCompleta} 
                            cod_epp={props.cod_epp}
                            detalleEnsayo={detalleEnsayo} 
                            setDetalleEnsayo={setDetalleEnsayo}
                            formCliente={formCliente}
                            />;
      /* return <ListadoDetalle datos={detalleEnsayo} borraElementoDetalle={borraElementoDetalle} setListaDetalleCompleta={setListaDetalleCompleta} />; */
    }
  }
//**************************************************************************** */
  function bajarArchivo () {

    axios.request({
      url: "/api/getpdf",
      method: "POST",
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: {'ensayopdf': props.celdas.codigo, 'verpng': false,}
  }).then((res) => {fileDownload(res.data, props.celdas.codigo + ".pdf")
  }).catch(function (error) {
      //console.log(error);
  }); }
//**************************************************************************** */
  async function emitir () {
    await axios.request({
      url: "/api/emitecert",
      method: "POST",
      data: {"cod_ensayo": props.celdas.codigo}
      }).then((response) => {
        //setValorTec({"valor": response.data.datos[0].tecnico})
        //setDetalleEnsayo(response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      //window.location.reload(false);
      const aleatorio = Math.floor(Math.random()*1000).toString()
      setShow(false); //cierra la ventana popup
      props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
  }
//**************************************************************************** */
  function cambiaVisual (event) {
    const {checked, name} = event.target;
    setApruebaVisual(checked);
  }
//**************************************************************************** */
  function cambiaModificaDatos (event) {
    const {checked, name} = event.target;
    //console.log('cambiaModificaDatos', checked)
    setEditaForm(checked);
  }
//**************************************************************************** */
  function formateaFecha (fecha) {
    
    let dia = fecha.getDate();
    let mes = fecha.getMonth() +1;
    let yearX = fecha.getFullYear();

    let diaStr = "";
    if (dia < 10) {
      diaStr = diaStr.toString().concat("0", dia.toString());
    }else {
      diaStr = dia.toString();
    }
    //console.log(diaStr)
    let mesStr = "";
    if (mes < 10) {
      mesStr = mesStr.toString().concat("0", mes.toString())
    } else {
      mesStr = mes.toString();
    }
    //console.log(mesStr)
    //let todo = diaStr.concat("-", mesStr, "-", yearX.toString())
    let todo = yearX.toString().concat("-", mesStr, "-", diaStr);
    //console.log(todo)
    return todo;
    
   //return "hola";
  }
//**************************************************************************** */
  function cambioEnFecha (event) {
    const {value, name} = event.target;
    console.log('cambio fecha', value);
    setFormCliente(prevNote => ({...prevNote, [name]: value}));
    setMaxFechaIn(value);
    setValorFechaIn('');
    //console.log(value, name);
  }
//**************************************************************************** */
  function cambioEnFechaIn (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
    setValorFechaIn(value);
    //console.log(value, name);
  }
//**************************************************************************** */
function borraElementoDetalle (elemento) {
  let newData = detalleEnsayo.filter(e => e.serie !== elemento);
  //console.log(newData);
  setDetalleEnsayo(newData);
}
//**************************************************************************** */
function borraElementoDetalleJmp (elemento) {
  let newData = detalleEnsayoJmp.filter(e => e.serie !== elemento);
  //console.log(newData);
  setDetalleEnsayoJmp(newData);
}
//**************************************************************************** */
  async function agregaDetalleJmp (){
    //console.log('primero', formClienteDetalleJmp);
    let formParaLista = formClienteDetalleJmp;
    let hay = false;
    for (const [key, value] of Object.entries(formClienteDetalleJmp)) {
      //console.log(`${key}: ${value}`);
        if (key!=='dieresul' && key!=='resisresul' && key!=='visual'){
          if (!value) {
            hay = true;
            let nombreVariable = key;
            if (key=='fuga1') {
              nombreVariable='Corriente de Fuga';
            }else if (key=='tension') {
              nombreVariable='Tension de ensayo';
            }else if (key=='seccion') {
              nombreVariable='Seccion conductor';
            }else if (key=='resismedida') {
              nombreVariable='Resistencia Medida';
            }else if (key=='resismax') {
              nombreVariable='Resistencia Máxima';
            }
            setErrorformClienteDetalleJmp(''.concat('Complete el valor de: ',nombreVariable));
          }else{
            if (key == 'serie'){
              if (value<=0 || value>99999){
                hay = true;
                setErrorformClienteDetalleJmp('El valor de la serie Epp debe ser de máximo 5 cifras y mayor a 0');
              }else {
                //chequear epp en la base
                formParaLista['serie_epp'] = props.cod_epp.concat('-', value.padStart(5, 0));
                    await axios({
                      method: "POST",
                      url:"/api/chequea_epp",
                      data: {
                        "serie_epp": formParaLista['serie_epp'],
                        "cliente": formCliente.cliente,
                        "negocio": formCliente.negocio,
                        "sucursal": formCliente.sucursal,
                      }
                    })
                    .then((response) => {
                      //setValorTec({"valor": response.data.datos[0].tecnico})
                      // todo ok
                    }).catch((error) => {
                      if (error.response) {
                        if (error.response.data.msg) {
                          setErrorformClienteDetalleJmp(error.response.data.msg)
                        } else if (error.response.data.datos) {
                          setErrorformClienteDetalleJmp(error.response.data.datos)
                        }
                        hay = true;
                        //console.log(error.response)
                        //console.log(error.response.status)
                        //console.log(error.response.headers)
                        }
                    });
              }
            }
          }
        }else if (key == 'visual'){
          if (value=='on'){
            formParaLista['visual'] = 'APROBADO';
          }else{
            formParaLista['visual'] = 'RECHAZADO';
          }
        }else if (key == 'dieresul'){
          if (value=='on'){
            formParaLista['dieresul'] = 'APROBADO';
          }else{
            formParaLista['dieresul'] = 'RECHAZADO';
          }
        }else if (key == 'resisresul'){
          if (value=='on'){
            formParaLista['resisresul'] = 'APROBADO';
          }else{
            formParaLista['resisresul'] = 'RECHAZADO';
          }
        }
    }
    if (!hay){
      const datosNew = [formParaLista, ...detalleEnsayoJmp]
      //console.log('segundo', datosNew);
      setDetalleEnsayoJmp(datosNew);
      setShowDetalle(false);
    }
  }
  //**************************************************************************** */
  async function agregaDetalle () {
    //chequear los datos
    //primero limpiar los errores los errores 
    //console.log('inicia agrega detalle')
    setErrorformClienteDetalle({
      'serie': '',
      'parches': '',
      'tension': '',
      'fuga1': '',
      'resultado': 'RECHAZADO'
    });
    let formParaLista = formClienteDetalle;
    let hayError = false;
    if (apruebaVisual) {
      //console.log('aprueba visual')
      if (!formClienteDetalle.serie) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalle.serie <= 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalle.serie.padStart(5, '0')
          if (a.length > 5) {
            setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
              //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      }
      if (!formClienteDetalle.parches) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["parches"]: "Ingrese cantidad de parches (0 si no tiene)"}))
        hayError = true;
      } else {
        if (formClienteDetalle.parches < 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["parches"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
      if (!formClienteDetalle.tension) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["tension"]: "Ingrese tensión de ensayo"}))
        hayError = true;
      } else {
        if (formClienteDetalle.tension < 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["tension"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
   // aquí va el chequeo corrientes
    } else {
      //console.log('No aprueba visual')
      if (!formClienteDetalle.serie) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalle.serie <= 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalle.serie.padStart(5, '0')
          if (a.length > 5) {
            setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
            //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      } 
      if (!hayError) {
        //console.log('no hubo error')
        formParaLista['parches'] = '--';
        formParaLista['tension'] = '--';
        formParaLista['fuga1'] = '--';
        formParaLista['resultado'] = 'RECHAZADO';
      }
    }

      if (!hayError) {
        let fuga_max = 5; //default
        await axios({
          method: "POST",
          url:"/api/chequea_epp",
          data: {
            "serie_epp": formParaLista['serie'],
            "cliente": formCliente.cliente,
            "negocio": formCliente.negocio,
            "sucursal": formCliente.sucursal,
          }
        })
        .then((response) => {
          //setValorTec({"valor": response.data.datos[0].tecnico})
          //console.log('llega respuesta de axios')
          if (isNaN(response.data.datos)) {
            //console.log('no es numero')
            setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: response.data.datos}))
            hayError = true;
            //console.log('error en guante: ')
          } else {
              //console.log('Es numero')
              fuga_max = response.data.datos
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
            } else if (error.response.data.datos) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
            }
            hayError = true;
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });

        //console.log('valor de hayerror: ', hayError)
        if (!hayError) {
          if (apruebaVisual) {

            /*
            if (props.cod_epp == 'GNT'){
              //calcular promedio
              let prom = ((parseInt(formParaLista['fuga3']))).toFixed(2);
              if (prom < fuga_max) {
                //ok
                formParaLista['resultado'] = 'APROBADO'
              } else {
                  formParaLista['resultado'] = 'RECHAZADO'
              }
            }else {
              //cuando no es guante no hay maximo de corriente de fuga
              formParaLista['resultado'] = formClienteDetalle['resultado']
            }
            */
            formParaLista['resultado'] = formClienteDetalle['resultado']

          }
          const datosNew = [formParaLista, ...detalleEnsayo]
          setDetalleEnsayo(datosNew);
          //setShowDetalle(false);
          setFormClienteDetalle({})
        } 
      }
  }

//********************************************************************************* */
  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  function handleClose () {
    setFormClienteDetalle({});
    setFormClienteDetalleJmp({});
    setErrorformClienteDetalle({});
    setErrorformClienteDetalleJmp();
    setDetalleEnsayo([]);
    setDetalleEnsayoJmp([]);
    setDeshabilitaBotonGrabar(true)
    const aleatorio = Math.floor(Math.random()*1000).toString()
    setShow(false); //cierra la ventana popup
    props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
  }

  async function handleShow () {
    setErrorForm({
      'cod_ensayo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'temperatura': '',
      'humedad': '',
      'tecnico': '',
      'patron': '',
      'orden_compra': '',
      'dir_ensayo': '',
      'fecha_ejecucion': '',
      'fecha_ingreso': '',
    });

    await axios({
      method: "POST",
      url:"/api/formensayos",
      data: {
        "id_batea": props.celdas.id,
      }
    })
    .then((response) => {
      //setValorTec({"valor": response.data.datos[0].tecnico})
      //console.log('respuesta form: ',response.data.datos);
      setErrorForm({
      'cod_ensayo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'temperatura': '',
      'humedad': '',
      'tecnico': '',
      'patron': '',
      'orden_compra': '',
      'dir_ensayo': '',
      'fecha_ejecucion': '',
      'fecha_ingreso': '',
    });
      setFormCliente({
        'cod_ensayo': props.celdas.codigo,
        'cliente': response.data.datos[0].cliente,
        'sucursal': response.data.datos[0].sucursal,
        'negocio': response.data.datos[0].negocio,
        'temperatura': response.data.datos[0].temperatura,
        'humedad': response.data.datos[0].humedad,
        'tecnico': response.data.datos[0].tecnico,
        'patron': response.data.datos[0].patron,
        'tipo_ensayo': props.nombre_tabla.split("/")[2],
        'fecha_ejecucion': response.data.datos[0].fecha_ejecucion,
        'fecha_ingreso': response.data.datos[0].fecha_ingreso,
        'orden_compra': response.data.datos[0].orden_compra,
        'dir_ensayo': response.data.datos[0].dir_ensayo,
        'estado': '2',
      })
      setMaxFechaIn(response.data.datos[0].fecha_ejecucion);
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });

    await axios({
      method: "POST",
      url:"/api/listadetalle",
      data: {
        "id_batea": props.celdas.id,
        "cod_epp": props.cod_epp,
      }
    })
    .then((response) => {
      //setValorTec({"valor": response.data.datos[0].tecnico})
      if (props.cod_epp=='JMP'){
        setDetalleEnsayoJmp(response.data.datos);
      }else{
        setDetalleEnsayo(response.data.datos);
      }
      
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });
    
    setShow(true);
    setEditaForm(false);
  }

  function cambioEnForm (event) {
    //console.log('cambio form encabezdo')
    setErrorForm({
      'cod_ensayo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'temperatura': '',
      'humedad': '',
      'tecnico': '',
      'patron': '',
      'orden_compra': '',
      'dir_ensayo': '',
      'fecha_ejecucion': '',
      'fecha_ingreso': '',
    });
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
  }
  function cambioEnFormDetalle (event) {
    const {value, name} = event.target;
    setFormClienteDetalle(prevNote => ({...prevNote, [name]: value}))
  }

  function cambioEnFormDetalleJmp (event) {
    const {value, name} = event.target;
    setFormClienteDetalleJmp(prevNote => ({...prevNote, [name]: value}))
  }

  async function grabaEncabezado () {
    //graba la parte de encabezado si es que hubo algún cambio
    //console.log('form encabezado: ', formCliente)
    setErrorForm({
      'cod_ensayo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'temperatura': '',
      'humedad': '',
      'tecnico': '',
      'patron': '',
      'orden_compra': '',
      'dir_ensayo': '',
      'fecha_ejecucion': '',
      'fecha_ingreso': '',});
      //console.log(formCliente);
    let hayError = false;
    if (!formCliente.cod_ensayo) {
      setErrorForm(prevNote => ({...prevNote, ["cod_ensayo"]: "Código de ensayo no definido"}))
      hayError = true;
      //console.log('error en cod_ennsayo: ', formCliente.cod_ensayo)
    }
    if (!formCliente.cliente) {
      if (formCliente.cliente == "0"){
        // esta ok el cliente codigo 0
      } else {
        setErrorForm(prevNote => ({...prevNote, ["cliente"]: "Seleccione Cliente"}))
        hayError = true;
        //console.log('error en cliente: ', formCliente.cliente)
      }
    }
    if (!formCliente.sucursal) {
      setErrorForm(prevNote => ({...prevNote, ["sucursal"]: "Seleccione Sucursal"}))
      hayError = true;
      //console.log('error en sucursal: ', formCliente.sucursal)
    }
    if (!formCliente.negocio) {
      setErrorForm(prevNote => ({...prevNote, ["negocio"]: "Seleccione Negocio"}))
      hayError = true;
      //console.log('error en negocio: ', formCliente.negocio)
    }
    if (!formCliente.temperatura) {
      setErrorForm(prevNote => ({...prevNote, ["temperatura"]: "Ingrese Temperatura"}))
      hayError = true;
      //console.log('error en temperatura: ', formCliente.temperatura)
    }else {
      if (isNaN(formCliente.temperatura)) {
        setErrorForm(prevNote => ({...prevNote, ["temperatura"]: "Temperatura debe ser un número"}))
        hayError = true;
        //console.log('error en temperatura: ', formCliente.temperatura)
      }
    }
    if (!formCliente.humedad) {
      setErrorForm(prevNote => ({...prevNote, ["humedad"]: "Ingrese Humedad"}))
      hayError = true;
      //console.log('error en humedad: ', formCliente.humedad)
    }else {
      if (isNaN(formCliente.humedad)) {
        setErrorForm(prevNote => ({...prevNote, ["humedad"]: "Humedad debe ser un número"}))
        hayError = true;
        //console.log('error en humedad: ', formCliente.humedad)
      }
    }
    if (!formCliente.tecnico) {
      setErrorForm(prevNote => ({...prevNote, ["tecnico"]: "Seleccione Técnico"}))
      hayError = true;
      //console.log('error en tecnico: ', formCliente.tecnico)
    }
    if (!formCliente.patron) {
      setErrorForm(prevNote => ({...prevNote, ["patron"]: "Seleccione Patrón"}))
      hayError = true;
      //console.log('error en patron: ', formCliente.patron)
    }
    if (!formCliente.fecha_ingreso) {

      //console.log('fecha in: ', formCliente.fecha_in)
      setErrorForm(prevNote => ({...prevNote, ["fecha_in"]: "Seleccione fecha ingreso"}))
      hayError = true;
      //console.log('error en fecha_in: ', formCliente.fecha_ingreso)
    }
    if (!formCliente.fecha_ejecucion) {
      //console.log('fecha ej: ', formCliente.fecha_ej)
      setErrorForm(prevNote => ({...prevNote, ["fecha_ej"]: "Seleccione fecha ejecución"}))
      hayError = true;
      //console.log('error en fecha_ej: ', formCliente.fecha_ejecucion)
    }
    if (!formCliente.orden_compra) {
      //console.log('fecha ej: ', formCliente.fecha_ej)
      setErrorForm(prevNote => ({...prevNote, ["orden_compra"]: "Seleccione Orden de compra"}))
      hayError = true;
      //console.log('error en fecha_ej: ', formCliente.fecha_ejecucion)
    }
    if (!formCliente.dir_ensayo) {
      setErrorForm(prevNote => ({...prevNote, ["dir_ensayo"]: "Ingrese dirección del ensayo"}))
      hayError = true;
      //console.log('error en fecha_ej: ', formCliente.fecha_ejecucion)
    }
    if (!hayError) {
      //console.log('llama a guradar form ' + JSON.stringify(formCliente));
      await axios({
        method: "POST",
        url:"/api/grabaensayo",
        data: formCliente
      })
      .then((response) => {
        //console.log('axios grabaensayo: ', response.data.msg);
      }).catch((error) => {
        if (error.response) {
          //console.log('error axios: ', error.response.data.msg)
          setErrorForm(prevNote => ({...prevNote, ["orden_compra"]: error.response.data.msg}))
          hayError = true;
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      if (hayError) {
        console.log('retorna falso 1');
        return false;
        
      }else {
        console.log('retorna true');
        return true;
      }
    } else {
      //console.log('hubo error en graba encabezado')
      console.log('retorna falso 2');
      return false;
    }
  };

  async function handCloseGraba () {
    //setShow(false);
    if (props.cod_epp == 'LDB') {
        //console.log('Graba ldb', formDetalleLoadB);
        const salidaDet = {"detalle": [formDetalleLoadB]}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };
        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });
    }else if (props.cod_epp == 'JMP') {
        //console.log('Graba JMP', detalleEnsayoJmp);
        const salidaDet = {"detalle": detalleEnsayoJmp}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };
        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });
    }else {
      const detalleDb = detalleEnsayo.map((e) => {
        return {"serie_epp": e.serie,
                "fuga1": e.fuga1,  
                "parches": e.parches,  
                "tension": e.tension, 
                "resultado": e.resultado}});
      const salidaDet = {"detalle": detalleDb}
      const datoGrabar = {
        id_batea: props.celdas.id,
        detalle: JSON.stringify(salidaDet),
      };
      //console.log(datoGrabar);
      //grabadetensayo
      //console.log('funcion guardaForm detalle' + JSON.stringify(formCliente));
      await axios({
        method: "POST",
        url:"/api/grabadetensayo",
        data: datoGrabar
      })
      .then((response) => {
        //console.log('axios grabadetensayo: ', response.data.msg);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });}
      let se_hizo_bien = await grabaEncabezado();
      console.log('se hizo bien: ', se_hizo_bien);
      if (se_hizo_bien) {
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
        //window.location.reload(false);
      }
      
  }
  function EditarCodEnsayo(props){
    //console.log('editar cod ensayo');
    return <EditaCodEnsayo 
      codEnsayo={props.codEnsayo} 
      setLabelCodEnsayo = {props.setLabelCodEnsayo}
      rutaCompleta={props.rutaCompleta}
      setRuta={props.setRuta}
       />;
  };
  return (
    <>
     <button className="btn btn-outline-primary" size="sm" onClick={handleShow}><PencilSquare /></button>
      <Modal show={show} fullscreen={true} onHide={handleClose} size="lg" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Editar {props.titulo}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form id="form-principal" >
        <Container>
            <Row>
            <Col xs={15} md={10} >
                <h4>
                  {/* Texto donde va el codigo ensayo */}
                  <Badge bg="secondary">{labelCodEnsayo}</Badge>
                  </h4>
                  <EditarCodEnsayo 
                  codEnsayo={labelCodEnsayo} 
                  setLabelCodEnsayo={setLabelCodEnsayo} 
                  rutaCompleta={rutaCompleta} 
                  setRuta={props.setRuta} />
                  
                
            </Col>
              <Col xs={3} md={2} >
                <Button variant="primary" onClick={emitir} >Emitir Certificado</Button>
              </Col>
            </Row>
            <hr />
              <Row>
                <Col xs={6} md={4} >
                    <Form.Group className="mb-3" controlId="form-14">
                    <Form.Label><span className="campo">Cliente</span></Form.Label>
                      
                        <SelectParamTest parametro="cliente" valorDefault={formCliente.cliente} cambioEnForm={cambioEnForm} />
                     
                    <p><span className = "mensaje-error">{errorForm?.cliente}</span></p>
                    </Form.Group>
                    
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group  className="mb-3" controlId="form-16">
                    <Form.Label><span className="campo">Sucursal</span></Form.Label>
                        <SelectParamTest parametro="sucursal" valorDefault={formCliente.sucursal} cambioEnForm={cambioEnForm} />
                    <p><span className = "mensaje-error">{errorForm?.sucursal}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-17">
                    <Form.Label><span className="campo">Negocio</span></Form.Label>
                        <SelectParamTest parametro="negocio" valorDefault={formCliente.negocio} cambioEnForm={cambioEnForm} />
                    <p><span className = "mensaje-error">{errorForm?.negocio}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-1">
                    <Form.Label><span className="campo">Temperatura</span></Form.Label>
                    <Form.Control
                      defaultValue={formCliente.temperatura}
                      name="temperatura"
                      type="text"
                      placeholder="T°"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.temperatura}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-2">
                    <Form.Label><span className="campo">Humedad</span></Form.Label>
                    <Form.Control
                      defaultValue={formCliente.humedad}
                      name="humedad"
                      type="text"
                      placeholder="°C"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.humedad}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-3">
                    <Form.Label><span className="campo">Técnico</span></Form.Label>
                        <SelectParamTest parametro="tecnico" valorDefault={formCliente.tecnico} cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.tecnico}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="clase">
                    <Form.Label><span className="campo">Seleccione Patrón</span></Form.Label>
                        <SelectParamTest parametro="patron" valorDefault={formCliente.patron} cambioEnForm={cambioEnForm} />
                    <p><span className = "mensaje-error">{errorForm?.patron}</span></p>
                    </Form.Group>
                </Col>
                {/******************************************************** */}
                {/*********************   FECHA EJECUCION***************** */}
                <Col xs={6} md={4}>
                  <Form.Label><span className="campo">Fecha ejecución</span></Form.Label>
                    <Form.Group  controlId="fec_ej">
                      <Form.Control value={formCliente.fecha_ejecucion} type="date" name="fecha_ejecucion" placeholder="" onChange={cambioEnFecha} />
                      <p><span className = "mensaje-error">{errorForm?.fecha_ej}</span></p>
                    </Form.Group>
                </Col>
                {/******************************************************** */}
                <Col xs={6} md={4}>
                  <Form.Label><span className="campo">Fecha ingreso</span></Form.Label>
                  <Form.Group  controlId="fec_in">
                      <Form.Control value={valorFechaIn} max={maxFechaIn} type="date" name="fecha_ingreso" placeholder="Date of Birth" onChange={cambioEnFechaIn} />
                      <p><span className = "mensaje-error">{errorForm?.fecha_in}</span></p>
                  </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                  <Form.Group className="mb-3" controlId="form-4">
                    <Form.Label><span className="campo">Documento de Referencia</span></Form.Label>
                    <Form.Control
                     
                      defaultValue={formCliente.orden_compra}
                      name="orden_compra"
                      type="text"
                      placeholder="Documento"
                      
                      />
                      <p><span className = "mensaje-error">{errorForm?.orden_compra}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="form-5">
                    <Form.Label><span className="campo">Dirección del ensayo</span></Form.Label>
                    <Form.Control
                      
                      defaultValue={formCliente.dir_ensayo}
                      name="dir_ensayo"
                      type="text"
                      placeholder="Dirección"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.dir_ensayo}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
              {/* Formulario de ingreso de detalle */}
              {/* *********************************************************** */}
            <Col xs={15} md={10}>

            </Col>
              <Col xs={3} md={2}>
                {props.cod_epp == 'LDB' || props.cod_epp == 'JMP'?null:
                  <Button disabled={listaDetalleCompleta} variant="primary" onClick={() => {setApruebaVisual(false); setShowDetalle(true)}}>
                  + Agregar Detalle
                  </Button>
                }
                {props.cod_epp == 'JMP'?
                  <Button disabled={listaDetalleCompleta} variant="primary" onClick={() => {setApruebaVisual(false); setShowDetalle(true)}}>
                  + Agregar Detalle
                  </Button>:null
                }
                <Modal
                  show={showDetalle}
                  onHide={() => {setShowDetalle(false); setFormClienteDetalle({})}}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                      <h3>Ingresar Detalle</h3>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {/* Elemento es jumper */}
{/* ********************************************************************************** */}
                  {props.cod_epp=='JMP'/* Elemento jumper*/?
                    <Form id="form-interno-2">
                      <Container>
                          <Row>
                          <Card >
                            <Card.Header><span className="titulo-card">Ensayo dieléctrico</span></Card.Header>
                          </Card>
                          </Row>
                          <hr />
                        <Row >
                        <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Serie Epp</span></Form.Label>
                            <InputGroup id="serie-epp" >
                              <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                                <Form.Control
                                onChange={cambioEnFormDetalleJmp}
                                name="serie"
                                type="number"
                                placeholder="Serie epp"
                                />
                            </InputGroup>
                        </Col>
                        <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Tensión Ensayo</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="tension"
                            type="number"
                            placeholder="kV"
                            />
                        </Col>
                        <Col xs={6} md={4}>
                          <Form.Label><span className="campo">Corriente de fuga</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="fuga1"
                            type="number"
                            placeholder="mA" />
                        </Col>
                        
                        </Row>
                        <hr />
                        <Row>
                        <Col xs={6} md={4}>
                          <Form.Label><span className="campo">Inspección Visual</span></Form.Label>
                            <Form.Check  value="AR1" type="checkbox" id="ck2" >
                            <Form.Check.Input name="visual" type="checkbox" isValid onChange={cambioEnFormDetalleJmp}  />
                            <Form.Check.Label>Aprobado</Form.Check.Label>
                            </Form.Check>
                        </Col>
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Resultado E. dielectrico</span></Form.Label>
                            <Form.Check  value="AR1" type="checkbox" id="ck1" >
                            <Form.Check.Input name="dieresul" type="checkbox" isValid onChange={cambioEnFormDetalleJmp} />
                            <Form.Check.Label>Aprobado</Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row>
                        <hr />
                          <Row>
                            <Card >
                              <Card.Header><span className="titulo-card">Ensayo resistencia eléctrica</span></Card.Header>
                            </Card>
                            </Row>
                            <hr />
                          <Row >
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Tramo</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="tramo"
                            type="text"
                            placeholder="tramo"
                            />
                          </Col>
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Seccion Conductor</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="seccion"
                            type="text"
                            placeholder="seccion"
                            />
                          </Col>
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Longitud (ft)</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="longitud"
                            type="number"
                            placeholder="ft"
                            />
                          </Col>
                        </Row>
                        <hr />
                        <Row >
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Resistencia Medida (μΩ)</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="resismedida"
                            type="number"
                            placeholder="μΩ"
                            />
                          </Col>
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Resistencia Máxima (μΩ)</span></Form.Label>
                            <Form.Control
                            onChange={cambioEnFormDetalleJmp}
                            name="resismax"
                            type="number"
                            placeholder="μΩ"
                            />
                          </Col>
                          <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Resultado Ω Eléctrica</span></Form.Label>
                            <Form.Check  value="AR1" type="checkbox" id="ck3" >
                            <Form.Check.Input name="resisresul" type="checkbox" isValid onChange={cambioEnFormDetalleJmp} />
                            <Form.Check.Label>Aprobado</Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Row>
                      </Container>
                    </Form>



/**************************  Elemetos distinto jumper */
                  :
                  <Form id="form-interno">
                  <Container>
                    <Row >
                        <Col xs={6} md={4}>
                            <Form.Label><span className="campo">Serie Epp</span></Form.Label>
                            <InputGroup id="input-serie" >
                              <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                                <Form.Control
                                onChange={cambioEnFormDetalle}
                                name="serie"
                                type="number"
                                placeholder="Serie epp"
                                />
                            </InputGroup>
                            <p><span className = "mensaje-error">{erroformClienteDetalle?.serie}</span></p>
                        </Col>
                        <Col xs={6} md={5}>
                            <Form.Label><span className="campo">Inspección Visual</span></Form.Label>
                            <span className="campo">
                            <Form.Check
                              type="checkbox"
                              name="visual"
                              label="Aprobada"
                              onChange={cambiaVisual}
                            /></span>
                        </Col>
                        <Col xs={4} md={3}>
                            <Form.Label><span className="campo"></span></Form.Label>
                            <InputGroup id="input-btn">
                            <button className="btn btn-outline-primary" 
                            onClick={agregaDetalle}>
                            <PlusSquare />
                          </button></InputGroup>
                        </Col>
                    </Row>
                    <Row>
                      <Col xs={4} md={3}>
                      <Form.Label><span className="campo">Número de parches</span></Form.Label>
                        <Form.Control
                        disabled={!apruebaVisual}
                        onChange={cambioEnFormDetalle}
                        name="parches"
                        type="number"
                        placeholder="parches"
                        />
                        <p><span className = "mensaje-error">{erroformClienteDetalle?.parches}</span></p>
                      </Col>
                      <Col xs={4} md={3}>
                      <Form.Label><span className="campo">Tensión de Ensayo</span></Form.Label>
                        <Form.Control
                        disabled={!apruebaVisual}
                        onChange={cambioEnFormDetalle}
                        name="tension"
                        type="number"
                        placeholder="kV"
                        />
                        <p><span className = "mensaje-error">{erroformClienteDetalle?.tension}</span></p>
                      </Col>
                      <Col xs={4} md={3}>
                        <Form.Group className="mb-3" controlId="form-6">
                        <Form.Label><span className="campo">Corriente fuga </span></Form.Label>
                        <Form.Control
                          disabled={!apruebaVisual}
                          onChange={cambioEnFormDetalle}
                          name="fuga1"
                          type="number"
                          placeholder="I(mA)"
                          />
                        </Form.Group>
                        <p><span className = "mensaje-error">{erroformClienteDetalle?.fuga1}</span></p>
                      </Col>
                      {/*console.log('codigo tipo: ', props.cod_epp)*/}
                      <Col xs={4} md={3}>
                      {props.cod_epp!=='GNT'?
                        <Form.Group className="mb-3" controlId="form-7">
                        <Form.Label><span className="campo">Resultado</span></Form.Label>
                        <Form.Select name="resultado" disabled={!apruebaVisual} aria-label="Default select example" onChange={cambioEnFormDetalle} >
                          <option value="RECHAZADO">RECHAZADO</option>
                          <option value="APROBADO">APROBADO</option>
                        </Form.Select>
                        </Form.Group>
                        :null
                      }
                      <Form.Label><span className="campo"></span></Form.Label>
                            <InputGroup id="input-btn-agrega" >
                            <button className="btn btn-outline-primary" 
                            onClick={agregaDetalle}>
                            <PlusSquare />
                          </button></InputGroup>
                      </Col>
                    </Row>
                  </Container>
                  </Form>}
                  {errorformClienteDetalleJmp?<p><span className = "mensaje-error">{errorformClienteDetalleJmp}</span></p>:null}
                  </Modal.Body>
                      {props.cod_epp=='JMP'?
                      <Modal.Footer>
                      <Button variant="secondary" onClick={() => {setShowDetalle(false); setFormClienteDetalleJmp({});}}>
                        Cancelar
                      </Button>
                      <Button variant="primary" onClick={agregaDetalleJmp}>
                        Agregar
                      </Button>
                    </Modal.Footer>
                      :
                      <Modal.Footer>
                        <Button variant="secondary" onClick={() => {setShowDetalle(false); setFormClienteDetalle({}); setErrorformClienteDetalle({})}}>
                          Cancelar
                        </Button>
                        <Button variant="primary" onClick={agregaDetalle}>
                          Agregar
                        </Button>
                      </Modal.Footer>}
                </Modal>
              </Col>
              <Col xs={9} md={6}>
                Detalle de Ensayo
              </Col>
            </Row>
            {/* aqui estaba MostrarListado */}
            <hr />
        </Container>
          </Form>
          {/*<MostrarListado cod_epp={props.cod_epp} />*/}
{/**###################################################################################### */}
{/**###################################################################################### */}
{/**###################################################################################### */}
          {props.cod_epp == 'LDB'?<>
          <Row>
                <Card >
                    <Card.Header><span className="titulo-card">SERIE EPP</span></Card.Header>
                </Card>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <InputGroup id="input-serie-epp">
                        <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                        <Form.Control
                        /*value={serieForEpp}*/
                        onChange={cambioEnEppLoad}
                        name="serie"
                        type="number"
                        placeholder="Serie epp"
                        />
                    </InputGroup>
                </Col>
                <Col xs={3} md={2}>
                    <Button variant="primary" onClick={validarEpp}>Validar</Button>
                </Col>
                <Col xs={9} md={6}>
                    <p><span className={validacionEpp.error?'msg-error':'titulo-card'}>{validacionEpp.msg}</span></p>
                </Col>
            </Row>
            <Row >
                <Card >
                    <Card.Header><span className="titulo-card">PATRONES</span></Card.Header>
                </Card>
            </Row>
            <Row >
            <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="patron">
                    <Form.Label><span className="campo">Patron 1</span>
                    </Form.Label>
                    <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron1"  onChange={cambioDetalleLoad}>
                        <SelectParam parametro="patron" />
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="patron2">
                    <Form.Label><span className="campo">Patron 2</span>
                    </Form.Label>
                    <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron2"  onChange={cambioDetalleLoad}>
                        <SelectParam parametro="patron" />
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="patron3">
                    <Form.Label><span className="campo">Patron 3</span>
                    </Form.Label>
                    <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron3"  onChange={cambioDetalleLoad}>
                        <SelectParam parametro="patron" />
                    </Form.Select>
                </Form.Group>
            </Col>
            </Row>
            <Row >
                <Card >
                    <Card.Header><span className="titulo-card">ENSAYOS</span></Card.Header>
                </Card>
            </Row>
            <Row>
                <Col>
                <h5><span className="titulo-columna">DIELECTRICO</span></h5>
                </Col>
                <Col>
                <h5><span className="titulo-columna">AISLACIÓN (C. ABIERTO)</span></h5>
                </Col>
                <Col>
                <h5><span className="titulo-columna">CONTACTO (C. CERRADO)</span></h5>
                </Col>
            </Row>
            <Row >
                {/* Dielectrico */}
                <Col>
                        <Form.Group className="mb-3" controlId="form-8">
                        <Form.Label><span className="campo">Tensión (kV)</span></Form.Label>
                        <Form.Control
                          name="tension1"
                          type="number"
                          placeholder="kV"
                          onChange={cambioDetalleLoad}
                          />
                        </Form.Group>
                </Col>
                <Col >
                <Form.Group className="mb-3" controlId="form-9">
                        <Form.Label><span className="campo">Corriente fuga </span></Form.Label>
                        <Form.Control
                          name="fuga1"
                          type="number"
                          placeholder="I(mA)"
                          onChange={cambioDetalleLoad}
                          />
                        </Form.Group>
                </Col>
                <Col >
                    <Form.Label><span className="campo">A - R</span></Form.Label>
                    <Form.Check  value="AR1" type="checkbox" id="ck2" >
                    <Form.Check.Input name="AR1" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>Aprobado</Form.Check.Label>
                    </Form.Check>
                </Col>

                {/* AISLACIÓN C. ABIERTO */}
                <Col >
                    <Form.Group className="mb-3" controlId="form-10">
                        <Form.Label><span className="campo">Tensión (kV)</span></Form.Label>
                        <Form.Control
                          name="tension2"
                          type="number"
                          placeholder="kV"
                          onChange={cambioDetalleLoad}
                          />
                        </Form.Group>
                </Col>
                <Col >
                <Form.Group className="mb-3" controlId="form-11">
                        <Form.Label><span className="campo">Medida (TΩ) </span></Form.Label>
                        <Form.Control
                          name="medida1"
                          type="number"
                          placeholder="TΩ"
                          onChange={cambioDetalleLoad}
                          />
                        </Form.Group>
                </Col>
                <Col >
                    <Form.Label><span className="campo">A - R</span></Form.Label>
                    <Form.Check  value="AR2" type="checkbox" id="ck2" >
                    <Form.Check.Input name="AR2" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>Aprobado</Form.Check.Label>
                    </Form.Check>
                </Col>

                {/* CONTACTO C. CERRADO */}
                <Col >
                <Form.Group className="mb-3" controlId="form-12">
                        <Form.Label><span className="campo">Tensión (μV) </span></Form.Label>
                        <Form.Control
                          name="tension3"
                          type="number"
                          placeholder="μV"
                          onChange={cambioDetalleLoad}
                          />
                        </Form.Group>
                </Col>
                <Col >
                <Form.Group className="mb-3" controlId="form-13">
                        <Form.Label><span className="campo">Medida (μΩ) </span></Form.Label>
                        <Form.Control
                          name="medida2"
                          type="number"
                          placeholder="μΩ"
                          onChange={cambioDetalleLoad}
                          />
                        </Form.Group>
                </Col>
                <Col >
                    <Form.Label><span className="campo">A - R</span></Form.Label>
                    <Form.Check  value="AR3" type="checkbox" id="ck2" >
                    <Form.Check.Input name="AR3" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>Aprobado</Form.Check.Label>
                    </Form.Check>
                </Col>
            </Row>
            <Row >
                <Card >
                    <Card.Header><span className="titulo-card">INSPECCIÓN VISUAL (marcar las casillas aprobadas)</span></Card.Header>
                </Card>
            </Row>
            <Row >
                <Col xs={6} md={4}>
                    <Form.Check  type="checkbox" id="carcaz" >
                    <Form.Check.Input name="carcaz" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CARCAZA</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="gancho" >
                    <Form.Check.Input name="gancho" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CONJUNTO GANCHO SUJECIÓN</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="cancla" >
                    <Form.Check.Input name="cancla" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CONJUNTO ANCLA</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="contop" >
                    <Form.Check.Input name="contop" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CONTADOR DE OPERACIONES</Form.Check.Label>
                    </Form.Check>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Check  type="checkbox" id="apertu" >
                    <Form.Check.Input name="apertu" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>APERTURA ANILLO DE TIRO</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="anillo" >
                    <Form.Check.Input name="anillo" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CIERRE ANILLO DE TIRO</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="extiro" >
                    <Form.Check.Input name="extiro" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>EXTENSION DE TIRO</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="citiro" >
                    <Form.Check.Input name="citiro" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CIERRE DE TIRO</Form.Check.Label>
                    </Form.Check>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Check type="checkbox" id="seguro" >
                    <Form.Check.Input name="seguro" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>SEGURO DE RESTABLECIMIENTO</Form.Check.Label>
                    </Form.Check>

                    <Form.Check type="checkbox" id="cubier" >
                    <Form.Check.Input name="cubier" type="checkbox" isValid onChange={cambiaCheck} />
                    <Form.Check.Label>CUBIERTA</Form.Check.Label>
                    </Form.Check>

                        <Form.Group as={Row} className="mb-3" controlId="contad">
                        <Form.Label column sm="3">
                            <h5><span className="titulo-columna">CONTADOR: </span></h5>
                             
                        </Form.Label>
                        <Col sm="1">

                        </Col>
                        <Col sm="8">
                            <Form.Control name="contad" type="number" placeholder="valor" onChange={cambioDetalleLoad} />
                        </Col>
                        </Form.Group>
                </Col>
            </Row>
            <Row >
                <Card >
                    <Card.Header><span className="titulo-card">CONCLUSIÓN (marcar ítems aprobados)</span></Card.Header>
                </Card>
            </Row>
            <Row>
            <Col xs={6} md={4}>
                <Form.Check  type="checkbox" id="ensayoresul" >
                <Form.Check.Input name="ensayoresul" type="checkbox" isValid onChange={cambiaCheckResult} />
                <Form.Check.Label>ENSAYOS</Form.Check.Label>
                </Form.Check>
            </Col>
            <Col xs={6} md={4}>
                <Form.Check  type="checkbox" id="insresultado" >
                <Form.Check.Input name="insresultado" type="checkbox" isValid onChange={cambiaCheckResult} />
                <Form.Check.Label>INSPECCIÓN VISUAL</Form.Check.Label>
                </Form.Check>
            </Col>
            <Col xs={6} md={4}>
                <Form.Check type="checkbox" id="resultado" >
                <Form.Check.Input name="resultado" type="checkbox" isValid onChange={cambiaCheckResult} />
                <Form.Check.Label>RESULTADO</Form.Check.Label>
                </Form.Check>
            </Col>
            </Row></>:<MostrarListado cod_epp={props.cod_epp} />}
{/**###################################################################################### */}
{/**###################################################################################### */}
{/**###################################################################################### */}


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          {props.cod_epp=='LDB'?
            <Button disabled={deshabilitaBotonGrabar} variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>:
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default EditEnsayo;