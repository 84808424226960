import React from "react";
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, InputGroup} from 'react-bootstrap';

function EditaCodEnsayo(props){

    const codEnsayoOld = props.codEnsayo;
    const [show, setShow] = useState(false);
    const [codEnsayoNew, setCodEnsayoNew] = useState();
    const [errorForm, setErrorForm] = useState({'valorensayo': ''});

    function cambioEnValor (event) {
      const {value, name} = event.target;
      setCodEnsayoNew(value);
    }
/***************************************************************************************** */ 
    function handleClose () {
        setShow(false);
      }
/***************************************************************************************** */ 
      function handleShow () {
        setShow(true);
      }
/***************************************************************************************** */      
    async function handCloseGraba () {
      let hayError = false;
      if (!codEnsayoNew) {
        setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: 'Debe indicar un valor válido'}))
      } else {
          if (isNaN(codEnsayoNew)) {
            setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: "El valor es incorrecto"}))
            hayError = true;
          } 
          if (!hayError){
            if (codEnsayoNew <=0 || codEnsayoNew > 99999){
              setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: "El valor es incorrecto"}))
              hayError = true;
            }
          }
      }
            if (!hayError){
                //setCodEnsayoNew(codEnsayoOld.substring(0,7).concat('-',codEnsayoNew.padStart(5, 0)));
                const codNuevo = codEnsayoOld.substring(0,7).concat('-',codEnsayoNew.padStart(5, 0));
                await axios({
                  method: "POST",
                  url:"/api/actualiza_codensayo",
                  data: {
                    "cod_old": codEnsayoOld,
                    "cod_new": codNuevo
                  }
                })
                .then((response) => {
                  //todo ok
                  //console.log(response.data);
                  props.setLabelCodEnsayo(codNuevo);
                  props.setFormCliente(prevNote => ({...prevNote, ['cod_ensayo']: codNuevo}))
                }).catch((error) => {
                  //error
                  hayError = true;
                  //console.log('error axios')
                  if (error.response.data.msg) {
                    setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: error.response.data.msg}))
                    //console.log(error.response.data.msg)
                    }
                });
            if (!hayError) {
              setShow(false); //cierra la ventana popup
                          }
          }

    }

    return (
    <>
    <Button id="edit-codensayo" variant="secondary" size="sm" onClick={handleShow} >Editar</Button>
    <Modal show={show} onHide={handleClose} size="xs">
    <Modal.Header closeButton>
          <Modal.Title><span className="subtitulo">Nuevo valor para el código {props.codEnsayo}</span></Modal.Title>
        </Modal.Header>
        <Modal.Body>
              <Form.Group className="mb-3" controlId="FormCambiaCodEnsayo">
              <Form.Label><span className="campo">Valor nuevo</span></Form.Label>
              <InputGroup >
              <InputGroup.Text >{props.codEnsayo.substring(0,7)}-</InputGroup.Text>
              <Form.Control
                name="valorensayo"
                type="number"
                placeholder="Valor"
                onChange={cambioEnValor}
                /></InputGroup>
                <p><span className = "mensaje-error">{errorForm?.valorensayo}</span></p>
              </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
        </Modal.Footer>
    </Modal>
    </>
    );
};

export default EditaCodEnsayo;