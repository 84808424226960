import React, { useState } from 'react';
import {Modal, Table as TablaBoot} from 'react-bootstrap';
import {Form, Container, Row, Col, InputGroup, Button} from 'react-bootstrap';
import { Trash, PlusSquare, PencilSquare } from 'react-bootstrap-icons';
import axios from 'axios';

function ListadoDetalleIngreso (props) {
  //console.log(props.datos)
  const [showFormCorriente, setShowFormCorriente] = useState(false);
  const [errorEnIfuga, setErrorEnIfuga] = useState(null);


  
  let detalleEnsayo=props.detalleEnsayo;
  //let setDetalleEnsayo=props.setDetalleEnsayo;
  
//************************************************************************************ */
//*********************************************************************************** */
  const [formClienteDetalleIngreso, setFormClienteDetalleIngreso] = useState({
    'serie': '',
    'parches': '',
    'tension': '',
    'fuga1': '',
    'resultado': 'RECHAZADO'
  });
//************************************************************************************ */
//*********************************************************************************** */
  const [errorformClienteDetalleIngreso, setErrorformClienteDetalleIngreso] = useState({
    'serie': '',
    'parches': '',
    'tension': '',
    'fuga1': '',
  });
//************************************************************************************ */
//*********************************************************************************** */
  const [listaDetalleCompletaIngreso, setListaDetalleCompletaIngreso] = useState(false);
  const [ivisualAprobado, setIvisualAprobado] = useState(false);

//************************************************************************************ */
//*********************************************************************************** */
  function cambiaIVisual (event) {
    const {checked, name} = event.target;
    setIvisualAprobado(checked);
  }

//************************************************************************************ */
//*********************************************************************************** */
  function cambioEnFormDetalleIngreso (event) {
    const {value, name} = event.target;
    setErrorEnIfuga(null);
    setErrorformClienteDetalleIngreso({
      'serie': '',
      'parches': '',
      'tension': '',
      'fuga1': '',
    });
    setFormClienteDetalleIngreso(prevNote => ({...prevNote, [name]: value}))
  }
 
//************************************************************************************ */
//*********************************************************************************** */
  async function agregaDetalle () {
    //chequear los datos
    //primero limpiar los errores los errores 
    //console.log('inicia agrega detalle en ListaDetalleIngreso')
    let formParaLista = formClienteDetalleIngreso;
    let hayError = false;
    if (ivisualAprobado) {
      //console.log('aprueba visual')
      if (!formClienteDetalleIngreso.serie) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalleIngreso.serie <= 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalleIngreso.serie.padStart(5, '0')
          if (a.length > 5) {
            console.log('error serie 2:', a);
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
              //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              /*TODO OK*/
              console.log('concatena 1');
              //formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      }
      if (!formClienteDetalleIngreso.parches) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["parches"]: "Ingrese cantidad de parches (0 si no tiene)"}))
        hayError = true;
      } else {
        if (formClienteDetalleIngreso.parches < 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["parches"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
      if (!formClienteDetalleIngreso.tension) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["tension"]: "Ingrese tensión de ensayo"}))
        hayError = true;
      } else {
        if (formClienteDetalleIngreso.tension < 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["tension"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }

      /*
      if (!formClienteDetalleIngreso.fuga1) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["fuga1"]: "Ingrese corriente de fuga"}))
        hayError = true;
      } else {
        if (formClienteDetalleIngreso.fuga1 < 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["fuga1"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
      */

    } else {
      //console.log('No aprueba visual')
      if (!formClienteDetalleIngreso.serie) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalleIngreso.serie <= 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalleIngreso.serie.padStart(5, '0')
          if (a.length > 5) {
            console.log('error serie 1');
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
            //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              console.log('concatena 2');
              formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      } 
      if (!hayError) {
        //console.log('no hubo error')
        formParaLista['parches'] = '--';
        formParaLista['tension'] = '--';
        formParaLista['fuga1'] = '--';
        formParaLista['resultado'] = 'RECHAZADO';
      }
    }
      //console.log('encontro error:', hayError, errorformClienteDetalleIngreso, formClienteDetalleIngreso);
      if (!hayError) {
        /** Generar el codigo de serie */
        let a = formClienteDetalleIngreso.serie.padStart(5, '0')
        formParaLista['serie'] = props.cod_epp.concat('-', a);

        let fuga_max = 5; //default
        await axios({
          method: "POST",
          url:"/api/chequea_epp",
          data: {
            "serie_epp": formParaLista['serie'],
            "cliente": props.formCliente.cliente,
            "negocio": props.formCliente.negocio,
            "sucursal": props.formCliente.sucursal,
          }
        })
        .then((response) => {
          //setValorTec({"valor": response.data.datos[0].tecnico})
          //console.log('llega respuesta de axios')

          if (isNaN(response.data.datos)) {
            //console.log('no es numero')
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: response.data.datos}))
            hayError = true;
            //console.log('error en guante: ')
          } else {
              //console.log('Es numero')
              fuga_max = response.data.datos
          }


        }).catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
            } else if (error.response.data.datos) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
            }
            hayError = true;
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });

        //console.log('valor de hayerror: ', hayError)
        if (!hayError) {
          if (ivisualAprobado) {

            /*
            if (props.cod_epp == 'GNT'){
              //calcular promedio
              let prom = ((parseInt(formParaLista['fuga3']))).toFixed(2);
              if (prom < fuga_max) {
                //ok
                formParaLista['resultado'] = 'APROBADO'
              } else {
                  formParaLista['resultado'] = 'RECHAZADO'
              }
            }else {
              //cuando no es guante no hay maximo de corriente de fuga
              formParaLista['resultado'] = formClienteDetalleIngreso['resultado']
            }
            */
            formParaLista['resultado'] = formClienteDetalleIngreso['resultado']
          }
          const datosNew = [formParaLista, ...detalleEnsayo]
          props.setDetalleEnsayo(datosNew);
          //setShowDetalle(false);

          setFormClienteDetalleIngreso({'serie': '',
          'parches': '',
          'tension': '',
          'fuga1': '',
          'resultado': 'RECHAZADO'})
          console.log('detalle: ', detalleEnsayo);
        } else {
          //console.log('Hubo error');
        }
      }
  }
//************************************************************************************ */
//*********************************************************************************** */
  function modificaCorriente (serie_elemento){
    //console.log('valor: ', serie_elemento);
    //onsole.log('valores: ', props.datos);
    const indice = props.datos.findIndex((elemento,idx) => {
      if (elemento.serie == serie_elemento){
        return true;
      }
    });
    //console.log('indice: ', props.datos[indice]);
    setFormClienteDetalleIngreso(props.datos[indice]);
  }

//************************************************************************************ */
//*********************************************************************************** */
  function GrabaCorriente (){
    //console.log('graba: ', formClienteDetalleIngreso);
    const formData = formClienteDetalleIngreso;
    //formData['fuga1'] = '5.4';
    //formData['resultado'] = 'APROBADO';
    if (formData.resultado=='RECHAZADO'){
      console.log('resulta1: ', formData.resultado);
      if (!formData.fuga1 || isNaN(formData.fuga1)){
        console.log('fuga1: ', formData.fuga1);
        //setErrorEnIfuga('El valor de la corriente no puede ser menor a 0')
        formData['fuga1'] = '';
        const indice = props.datos.findIndex((elemento,idx) => {
          if (elemento.serie == formData['serie']){
            return true;
          }
        });
        let arregloDatos = props.datos;
        arregloDatos[indice] = formData;
        props.setDetalleEnsayo(arregloDatos);
        setShowFormCorriente(false)
        console.log('uno');
      }else {
        if (formData.fuga1<0){ 
          console.log('fuga1: ', formData.fuga1);
          setErrorEnIfuga('El valor de la corriente no puede ser menor a 0, o deje el campo vacío si no es necesario')
        }else {
          console.log('fuga1: ', formData.fuga1);
          const indice = props.datos.findIndex((elemento,idx) => {
            if (elemento.serie == formData['serie']){
              return true;
            }
          });
          let arregloDatos = props.datos;
          arregloDatos[indice] = formData;
          props.setDetalleEnsayo(arregloDatos);
          setShowFormCorriente(false)
          console.log('dos');
        }
      }
    }else {
      /** ES APROBADO */
      console.log('resulta2: ', formData.resultado);
      if (!formData.fuga1) {
        console.log('fuga1: ', formData.fuga1);
        setErrorEnIfuga('Debe indicar un valor de corriente')
      }else {
        if (isNaN(formData.fuga1)) {
          console.log('fuga1: ', formData.fuga1);
          setErrorEnIfuga('El valor de la corriente no es válido')
        }else {
          if (formData.fuga1<0){
            console.log('fuga1: ', formData.fuga1);
            setErrorEnIfuga('El valor de la corriente no puede ser menor a 0')
          }else {
            console.log('fuga1: ', formData.fuga1);
            const indice = props.datos.findIndex((elemento,idx) => {
              if (elemento.serie == formData['serie']){
                return true;
              }
            });
            let arregloDatos = props.datos;
            arregloDatos[indice] = formData;
            props.setDetalleEnsayo(arregloDatos);
            setShowFormCorriente(false)
            console.log('tres');
          }
        }
      }
      
    }
  }
//************************************************************************************ */
//*********************************************************************************** */
  return (
    <>
    {/*LLenar formulario de ingreso datos*/}
    
                  <Container>
                  <Row >
                  <Col xs={15} md={10}>
                    <Form as={Row} >
                    
                        <Col >
                          {/* ************  SERIE EPP *****************/}
                          {/*******************************************/}
                            <Form.Label><span className="campo">Serie Epp</span></Form.Label>
                            <InputGroup >
                              <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                                <Form.Control
                                onChange={cambioEnFormDetalleIngreso}
                                name="serie"
                                type="number"
                                placeholder="Serie epp"
                                autoFocus/>
                            </InputGroup>
                            <p><span className = "mensaje-error">{errorformClienteDetalleIngreso?.serie}</span></p>
                        </Col>
                        <Col >
                          {/* ************  INSPECCION VISUAL *****************/}
                          {/*******************************************/}
                            <Form.Label><span className="campo">Inspección Visual</span></Form.Label>
                            <span className="campo">
                            <Form.Check
                              type="checkbox"
                              name="visual"
                              label="Aprobada"
                              onChange={cambiaIVisual}
                            /></span>
                        </Col>
                        <Col >
                        {/* ************  PARCHES *****************/}
                        {/*******************************************/}
                        <Form.Label><span className="campo">Número de parches</span></Form.Label>
                          <Form.Control
                          disabled={!ivisualAprobado}
                          onChange={cambioEnFormDetalleIngreso}
                          name="parches"
                          type="number"
                          placeholder="parches"
                          autoFocus/>
                          <p><span className = "mensaje-error">{errorformClienteDetalleIngreso?.parches}</span></p>
                        </Col>
                        <Col >
                        {/* ************  TENSION *****************/}
                        {/*******************************************/}
                        <Form.Label><span className="campo">Tensión de Ensayo</span></Form.Label>
                          <Form.Control
                          disabled={!ivisualAprobado}
                          onChange={cambioEnFormDetalleIngreso}
                          name="tension"
                          type="number"
                          placeholder="kV"
                          autoFocus/>
                          <p><span className = "mensaje-error">{errorformClienteDetalleIngreso?.tension}</span></p>
                        </Col>
                    </Form>
                    </Col>
                      <Col xs={3} md={2}>
                          {/* ************  BOTON AGREGA DETALLE*****************/}
                          {/*******************************************/}
                          <label >........................................</label>
                          <button disabled={listaDetalleCompletaIngreso} className="btn btn-outline-primary" 
                            onClick={agregaDetalle}>
                            <PlusSquare />
                          </button>
                      </Col>
                    </Row>
                          
                  </Container>
                  
                          
      {/* *************    TABLA    ************* */}            
      <TablaBoot striped bordered hover >
        <thead>
          <tr>
            <th>
              num_serie
            </th>
            <th>
              i_fuga_1
            </th>
            <th>
              num_parches
            </th>
            <th>
              tensión_ensayo
            </th>
            <th>
              aprobado
            </th>
            <th>
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
          {props.datos.map((detalle) => 
            <tr key={detalle.serie} >
              <td>{detalle.serie}</td>
              {detalle.fuga1=='--'?<td>{detalle.fuga1}</td>:<td>{detalle.fuga1}&nbsp;&nbsp;&nbsp;<button onClick={() => {setShowFormCorriente(true); console.log('cuatro'); modificaCorriente(detalle.serie); }} className="btn btn-outline-primary" size="sm"><PencilSquare /></button> </td>}
              <td>{detalle.parches}</td>
              <td>{detalle.tension}</td>
              <td>{detalle.resultado}</td>
              <td> 
                <button onClick={() => {props.borraElementoDetalle(detalle.serie)}} className="btn btn-outline-primary" size="sm"><Trash /></button> 
              </td>
            </tr>
            )}
        </tbody>
      </TablaBoot>
      <Modal show={showFormCorriente} >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h3>Ingresar Corriente de fuga</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{formClienteDetalleIngreso.serie}</h3>
            {/* ************  CORRIENTE FUGA *****************/}
            {/*******************************************/}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><span className="campo">Corriente fuga </span></Form.Label>
            <Form.Control
              value={formClienteDetalleIngreso.fuga1}
              onChange={cambioEnFormDetalleIngreso}
              name="fuga1"
              type="number"
              placeholder="I(mA)"
              autoFocus/>
            </Form.Group>
            <p><span className = "mensaje-error">{errorEnIfuga?errorEnIfuga:null}</span></p>
            {/* ************  APROBADO RECHAZADO*****************/}
            {/*******************************************/}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Resultado</span></Form.Label>
              <Form.Select defaultValue={formClienteDetalleIngreso.resultado} name="resultado"  aria-label="Default select example" onChange={cambioEnFormDetalleIngreso} >
                <option value="RECHAZADO">RECHAZADO</option>
                <option value="APROBADO">APROBADO</option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={()=>{GrabaCorriente();}}>
              Grabar
          </Button>
          <Button variant="secondary" onClick={()=>{setShowFormCorriente(false); console.log('cinco');}}>
              Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ListadoDetalleIngreso;