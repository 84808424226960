import React from "react"
import {useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Button, Modal, Form, Dropdown, ButtonGroup, Container, Row, Col, Badge, InputGroup} from 'react-bootstrap';
import axios from 'axios';
import '../estilos/AddNegocio.css';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import '../estilos/AddEnsayo.css';
import SelCliente from "./selectores/SelCliente";
import SelectParam from "./selectores/SelectParam";

function AddEnsayo (props) {
  //console.log('AddEnsayo props: ', props.nombre_tabla);
  const today = new Date();
  const rutaCompleta = props.nombre_tabla;
  //const [startDate, setStartDate] = useState(new Date());
  

  const [codigoEnsayo, setCodigoEnsayo] = useState('');
  const [maxFechaIn, setMaxFechaIn] = useState(formateaFecha(today));
  const [valorFechaIn, setValorFechaIn] = useState('');
  const [show, setShow] = useState(false);
  const [muestraValorEnsayo, setMuestraValorEnsayo] = useState(false);
  const [formCliente, setFormCliente] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'tipo_ensayo': props.nombre_tabla.split("/")[2],
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'orden_compra': '',
    'dir_ensayo': 'Leonidas Vial 1109, San Miguel, Santiago.',
    'estado': '1',
    'valorensayo': ''
  });
  const [errorForm, setErrorForm] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'orden_compra': '',
    'dir_ensayo': '',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
  });

  function formateaFecha (fecha) {
    
    let dia = fecha.getDate();
    let mes = fecha.getMonth() +1;
    let yearX = fecha.getFullYear();

    let diaStr = "";
    if (dia < 10) {
      diaStr = diaStr.toString().concat("0", dia.toString());
    }else {
      diaStr = dia.toString();
    }
    //console.log(diaStr)
    let mesStr = "";
    if (mes < 10) {
      mesStr = mesStr.toString().concat("0", mes.toString())
    } else {
      mesStr = mes.toString();
    }
    //console.log(mesStr)
    //let todo = diaStr.concat("-", mesStr, "-", yearX.toString())
    let todo = yearX.toString().concat("-", mesStr, "-", diaStr);
    //console.log(todo)
    return todo;
    
   //return "hola";
  }
  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  function handleClose () {
    setMuestraValorEnsayo(false);
    setShow(false);
  }
  function cambiaValorEnsayo (event) {
    //console.log(formCliente);
    const {checked, name} = event.target;
    if (checked) {
      setFormCliente(prevNote => ({...prevNote, ['cod_ensayo']: ''}))
    }else {
      setFormCliente(prevNote => ({...prevNote, ['cod_ensayo']: codigoEnsayo}))
    }
    setMuestraValorEnsayo(checked);
  }
  function handleShow () {
    //setStartDate(today);
    axios({
      method: "POST",
      url:"/api/getcodensayo",
      data: {"epp":props.cod_ensayo}
    })
    .then((response) => {
      //console.log('axios: ', response.data.datos);
      setCodigoEnsayo(response.data.datos);
      setFormCliente(prevNote => ({...prevNote, ['cod_ensayo']: response.data.datos}))
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });

    setValorFechaIn('');
    setFormCliente({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'tipo_ensayo': props.nombre_tabla.split("/")[2],
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'orden_compra': '',
    'dir_ensayo': 'Leonidas Vial 1109, San Miguel, Santiago.',
    'estado': '1',
    'valorensayo': ''
    });



    setErrorForm({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'orden_compra': '',
    'dir_ensayo': '',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'valorensayo': ''  
    });
    setShow(true);
  }

  function cambioEnForm (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
  }
  function cambioEnFecha (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
    setMaxFechaIn(value);
    setValorFechaIn('');
    //console.log(value, name);
  }
  function cambioEnFechaIn (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
    setValorFechaIn(value);
    //console.log(value, name);
  }

  async function handCloseGraba () {
    //setShow(false);
    //console.log('hand close graba')
    setErrorForm({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'orden_compra': '',
    'dir_ensayo': '',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'valorensayo': '',
  });

    //console.log(formCliente);
    let hayError = false;
    let nuevoEnsayo = '';
    let formParaApi = {};
    formParaApi['tipo_ensayo'] = formCliente.tipo_ensayo;
    formParaApi['estado'] = formCliente.estado;

    if (muestraValorEnsayo) {
      if (isNaN(formCliente.valorensayo)) {
        setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: "El valor es incorrecto"}))
        hayError = true;
      } 
      if (!hayError){
        if (formCliente.valorensayo <=0 || formCliente.valorensayo > 99999){
          setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: "El valor es incorrecto"}))
          hayError = true;
        }
      }
      if (!hayError){
        nuevoEnsayo = codigoEnsayo.substring(0,7).concat('-',formCliente.valorensayo.padStart(5, 0));
        //console.log('nuevo ensayo: ',nuevoEnsayo)
        await axios({
          method: "POST",
          url:"/api/buscaensayo",
          data: {
            "cod_ensayo": nuevoEnsayo,
          }
        })
        .then((response) => {
          //todo ok
          //console.log(response.data);
        }).catch((error) => {
          //error
          hayError = true;
          //console.log('error axios')
          if (error.response.data.msg) {
            setErrorForm(prevNote => ({...prevNote, ["valorensayo"]: error.response.data.msg}))
            //console.log(error.response.data.msg)
            }
        });
      }
    }
    
    //console.log('antes de chequeos', hayError);
    if (muestraValorEnsayo){
      if (!hayError){
        formParaApi['cod_ensayo'] = nuevoEnsayo;
      }
    }else{
      if (!formCliente.cod_ensayo) {
        setErrorForm(prevNote => ({...prevNote, ["cod_ensayo"]: "Código de ensayo no definido"}))
        hayError = true;
        //console.log('cod_ensayo');
      }else {
          formParaApi['cod_ensayo'] = formCliente.cod_ensayo;
      }
    }
    if (!formCliente.cliente) {
      setErrorForm(prevNote => ({...prevNote, ["cliente"]: "Seleccione Cliente"}))
      hayError = true;
      //console.log('cliente');
    }else {
      formParaApi['cliente'] = formCliente.cliente;
    }
    if (!formCliente.sucursal) {
      setErrorForm(prevNote => ({...prevNote, ["sucursal"]: "Seleccione Sucursal"}))
      hayError = true;
      //console.log('sucursal');
    }else {
      formParaApi['sucursal'] = formCliente.sucursal;
    }
    if (!formCliente.negocio) {
      setErrorForm(prevNote => ({...prevNote, ["negocio"]: "Seleccione Negocio"}))
      hayError = true;
      //console.log('negocio');
    }else {
      formParaApi['negocio'] = formCliente.negocio;
    }
    if (!formCliente.temperatura) {
      setErrorForm(prevNote => ({...prevNote, ["temperatura"]: "Ingrese Temperatura"}))
      hayError = true;
      //console.log('temperatura1');
    }else {
      if (isNaN(formCliente.temperatura)) {
        setErrorForm(prevNote => ({...prevNote, ["temperatura"]: "Temperatura debe ser un número"}))
        hayError = true;
        //console.log('temperatura2');
      }else {
        formParaApi['temperatura'] = formCliente.temperatura;
      }
    }
    if (!formCliente.humedad) {
      setErrorForm(prevNote => ({...prevNote, ["humedad"]: "Ingrese Humedad"}))
      hayError = true;
      //console.log('humedad1');
    }else {
      if (isNaN(formCliente.humedad)) {
        setErrorForm(prevNote => ({...prevNote, ["humedad"]: "Humedad debe ser un número"}))
        hayError = true;
        //console.log('humedad2');
      }else {
        formParaApi['humedad'] = formCliente.humedad;
      }
    }
    if (!formCliente.tecnico) {
      setErrorForm(prevNote => ({...prevNote, ["tecnico"]: "Seleccione Técnico"}))
      hayError = true;
      //console.log('tecnico');
    }else {
      formParaApi['tecnico'] = formCliente.tecnico;
    }
    if (!formCliente.patron) {
      setErrorForm(prevNote => ({...prevNote, ["patron"]: "Seleccione Patrón"}))
      hayError = true;
      //console.log('patron');
    }else {
      formParaApi['patron'] = formCliente.patron;
    }
    if (!formCliente.fecha_ingreso) {
      setErrorForm(prevNote => ({...prevNote, ["fecha_ingreso"]: "Seleccione fecha ingreso"}))
      hayError = true;
      //console.log('fecha_in');
    }else {
      formParaApi['fecha_ingreso'] = formCliente.fecha_ingreso;
    }
    if (!formCliente.fecha_ejecucion) {
      setErrorForm(prevNote => ({...prevNote, ["fecha_ejecucion"]: "Seleccione fecha ejecución"}))
      hayError = true;
      //console.log('fecha_ej');
    }else {
      formParaApi['fecha_ejecucion'] = formCliente.fecha_ejecucion;
    }
    if (!formCliente.orden_compra) {
      setErrorForm(prevNote => ({...prevNote, ["orden_compra"]: "Seleccione Orden de compra"}))
      hayError = true;
      //console.log('orden_compra');
    }else {
      formParaApi['orden_compra'] = formCliente.orden_compra;
    }
    if (!formCliente.dir_ensayo) {
      setErrorForm(prevNote => ({...prevNote, ["dir_ensayo"]: "Ingreso dirección del ensayo"}))
      hayError = true;
    }else {
      formParaApi['dir_ensayo'] = formCliente.dir_ensayo;
    }
    //console.log('despues de chequeos', hayError);
    if (!hayError) {
      //console.log('funcion guardaForm ' + JSON.stringify(formParaApi));
      await axios({
        method: "POST",
        url:"/api/grabaensayo",
        data: formParaApi
      })
      .then((response) => {
        //console.log('axios: ', response.data.msg);
      }).catch((error) => {
        if (error.response) {
          setErrorForm(prevNote => ({...prevNote, ["cliente"]: error.response.data.msg}))
          hayError = true;
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      if (!hayError) {
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
      }
      //window.location.reload(false);
    }
    
    
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        +Crear Ensayo
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><h3>Crear {props.titulo}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form >
          <Row>
            <Col>
              <h4>
              <Badge bg="secondary">{codigoEnsayo}</Badge>
              </h4>
            </Col>
            <Col>
              <Form.Check  value="AR1" type="checkbox" id="otrovalor" >
              <Form.Check.Input name="otro" type="checkbox" isValid onChange={cambiaValorEnsayo} />
              <Form.Check.Label>Usar otro valor para el ensayo</Form.Check.Label>
              </Form.Check>
            </Col>
            <Col>
              {muestraValorEnsayo?
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Valor nuevo</span></Form.Label>
              <InputGroup >
              <InputGroup.Text >{codigoEnsayo.substring(0,7)}-</InputGroup.Text>
              <Form.Control
                name="valorensayo"
                type="number"
                placeholder="Valor"
                onChange={cambioEnForm}
                /></InputGroup>
                <p><span className = "mensaje-error">{errorForm?.valorensayo}</span></p>
              </Form.Group>:null  
              }
              
            </Col>
            </Row>
            <hr/>
        <Container>
              <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Cliente</span></Form.Label>
                    <Form.Select autoFocus aria-label="Default select example" name="cliente" onChange={cambioEnForm}>
                        <SelectParam parametro="cliente" />
                    </Form.Select>
                    <p><span className = "mensaje-error">{errorForm?.cliente}</span></p>
                    </Form.Group>
                    
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Sucursal</span></Form.Label>
                    <Form.Select aria-label="Default select example" name="sucursal" onChange={cambioEnForm}>
                        <SelectParam parametro="sucursal" />
                    </Form.Select>
                    <p><span className = "mensaje-error">{errorForm?.sucursal}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Negocio</span></Form.Label>
                    <Form.Select aria-label="Default select example" name="negocio" onChange={cambioEnForm}>
                      <SelectParam parametro="negocio" />
                    </Form.Select>
                    <p><span className = "mensaje-error">{errorForm?.negocio}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Temperatura</span></Form.Label>
                    <Form.Control
                      name="temperatura"
                      type="text"
                      placeholder="T°"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.temperatura}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Humedad</span></Form.Label>
                    <Form.Control
                      name="humedad"
                      type="text"
                      placeholder="Hr%"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.humedad}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Técnico</span></Form.Label>
                    <Form.Select aria-label="Default select example" name="tecnico" onChange={cambioEnForm}>
                      <SelectParam parametro="tecnico" />
                    </Form.Select>
                      <p><span className = "mensaje-error">{errorForm?.tecnico}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="clase">
                    <Form.Label><span className="campo">Seleccione Patrón</span></Form.Label>
                    <Form.Select aria-label="Default select example" name="patron" onChange={cambioEnForm}>
                      <SelectParam parametro="patron" />
                    </Form.Select>
                    <p><span className = "mensaje-error">{errorForm?.patron}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Label><span className="campo">Fecha ejecución</span></Form.Label>
                    <Form.Group controlId="fec_ej">
                      <Form.Control type="date" name="fecha_ejecucion" placeholder="" onChange={cambioEnFecha} />
                      <p><span className = "mensaje-error">{errorForm?.fecha_ej}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Label><span className="campo">Fecha ingreso</span></Form.Label>
                  <Form.Group controlId="fec_in">
                      <Form.Control value={valorFechaIn} max={maxFechaIn} type="date" name="fecha_ingreso" placeholder="Date of Birth" onChange={cambioEnFechaIn} />
                      <p><span className = "mensaje-error">{errorForm?.fecha_in}</span></p>
                  </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Documento de Referencia</span></Form.Label>
                    <Form.Control
                      name="orden_compra"
                      type="text"
                      placeholder="Documento"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.orden_compra}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Dirección del ensayo</span></Form.Label>
                    <Form.Control
                      name="dir_ensayo"
                      type="text"
                      defaultValue="Leonidas Vial 1109, San Miguel, Santiago."
                      placeholder="Dirección"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.dir_ensayo}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <hr />
        </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddEnsayo;