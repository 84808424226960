import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';

function Ensayos (parametro) {
  console.log('props Ensayos: ', parametro);
  let elemento = "";
  let id_ensayo = "";
  let nombre_ensayo = "";
  let serie_ensayo = null;

  //const nombre_tabla = parametro;
  let nombre_tabla
  if (parametro.indexOf("/") >= 0) {
    var k = parametro.split("/")
    elemento = k[0];
    id_ensayo = k[2];
    nombre_ensayo = k[3];
    serie_ensayo = k[5];
    nombre_tabla = k[0].concat('/',k[1],'/',k[2],'/',k[3],'/',k[4])
  }

  if (serie_ensayo) {
    console.log('serie_ensayo: ',serie_ensayo);
  }
  const [datos, setDatos] = useState([]);


  const titulo="Ensayo: ".concat(nombre_ensayo);;
  const botonAgregar = elemento //"ensayoguante";

  useEffect(() => {
    //console.log('se ejecuta useeffect Ensayos');
    axios({
      method: "POST",
      url:"/api/listaensayos",
      data: {
        tipo_ensayo: id_ensayo,
        serie_ensayo: serie_ensayo
      }
    })
    .then((response) => {
      //console.log('axios: ', response.data.datos);
      setDatos(response.data.datos);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
  }, []);

  const columnas = [
    {
      Header: 'id',
      accessor: 'id'
    }, {
      Header: 'codigo',
      accessor: 'codigo'
    }, {
      Header: 'fecha_ingreso',
      accessor: 'fecha_ingreso'
    }, {
      Header: 'cliente',
      accessor: 'cliente'
    }, {
      Header: 'negocio',
      accessor: 'negocio'
    }, {
      Header: 'estado',
      accessor: 'estado'
    }
  ];

  

  return {
    columnas,
    datos,
    titulo,
    botonAgregar,
    nombre_tabla,
  };
};

export default Ensayos;