import React from "react";
import ClienteLista from "../../data/ClienteLista";
import Header from "../Header";
import Tablas from "../Tablas";

function Cliente () {
  return (
    <>
      <Tablas listado={ClienteLista} />
    </>
  );
};

export default Cliente;