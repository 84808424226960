import React from 'react';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom';
import Home from './paginas/Home';
import './App.css';
import Negocio from './componentes/acciones/Negocio';
import GestorEpp from './componentes/acciones/GestorEpp';
import AltasEpp from './componentes/acciones/AltasEpp';
import GestionaEnsayos from './componentes/acciones/GestionaEnsayos';
import Cliente from './componentes/acciones/Cliente';
import TestNav from './testcomponentes/TestNav';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="home" element={<Home />} />
        <Route path="testnav" element={<TestNav />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </Router>
  );
}

function NoPage() {
  return <span className="msg-error">
    <h2>Error 404, no existe la página solicitada</h2>
    <a href="/home">Inicio</a>
    </span>
}

export default App;
