import React from "react"
import {useState} from 'react';
import {Button, Modal, Form, Dropdown, ButtonGroup} from 'react-bootstrap';
import '../estilos/AddNegocio.css';

function AddGuante () {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        +Agregar Guante
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Agregar Guante</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="marca">
              <Form.Label><span className="campo">Seleccione Marca Guante</span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Marca</option>
                <option value="1">Salisbury</option>
                <option value="2">Chance</option>
                <option value="3">Novax</option>
                <option value="3">Regeltex</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="modelo">
              <Form.Label><span className="campo">Seleccione Modelo</span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Modelo</option>
                <option value="1">Modelo 1</option>
                <option value="2">Modelo 2</option>
                <option value="3">Modelo 3</option>
                <option value="3">Modelo 4</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="clase">
              <Form.Label><span className="campo">Seleccione Clase</span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Clase</option>
                <option value="1">00</option>
                <option value="2">0</option>
                <option value="3">1</option>
                <option value="3">2</option>
                <option value="3">3</option>
                <option value="3">4</option>
              </Form.Select>
            </Form.Group>
            
            <Form.Group className="mb-3" controlId="largo">
              <Form.Label><span className="campo">Seleccione Largo</span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option>Largo</option>
                <option value="1">280</option>
                <option value="2">360</option>
                <option value="3">410</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Max Corriente fuga</span></Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddGuante;