import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Container, Row, Col} from 'react-bootstrap';
import '../estilos/AddNegocio.css';

function AddCliente () {
  const [show, setShow] = useState(false);
  const [formCliente, setFormCliente] = useState({
    'nombre': '',
    'nombre_corto': '',
    'responsable': '',
    'fono': '',
    'direccion': ''
  });

  function cambioEnForm (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
  }

  function handCloseCancel () {
    setShow(false);
  }
  function handCloseGraba () {
    //setShow(false);
    //console.log('funcion guardaForm ' + JSON.stringify(formCliente))
    axios({
      method: "POST",
      url:"/api/grabacliente",
      data: formCliente
    })
    .then((response) => {
      //console.log('axios: ', response.data.msg);
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });
    //window.location.reload(false);
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        +Agregar Cliente
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><h3>Agregar Cliente</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form><Container>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Nombre</span></Form.Label>
                    <Form.Control
                        name="nombre"
                        type="text"
                        placeholder="Nombre Cliente"
                        autoFocus
                        value={formCliente.nombre}
                        onChange={cambioEnForm} 
                        />
                  </Form.Group>
              </Col>
              <Col  xs={6} md={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Nombre Corto</span></Form.Label>
                    <Form.Control
                        name="nombre_corto"
                        type="text"
                        placeholder="Máx 7 caracteres"
                        autoFocus 
                        value={formCliente.nombre_corto}
                        onChange={cambioEnForm}
                        />
                  </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Nombre Responsable</span></Form.Label>
                    <Form.Control
                        name="responsable"
                        type="text"
                        placeholder=""
                        autoFocus 
                        value={formCliente.responsable}
                        onChange={cambioEnForm}
                        />
                  </Form.Group>
              </Col>
              <Col  xs={6} md={4}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Teléfono de Contacto</span></Form.Label>
                    <Form.Control
                        name="fono"
                        type="text"
                        placeholder=""
                        autoFocus 
                        value={formCliente.fono}
                        onChange={cambioEnForm}
                        />
                  </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Dirección</span></Form.Label>
                    <Form.Control
                        name="direccion"
                        type="text"
                        placeholder=""
                        autoFocus 
                        value={formCliente.direccion}
                        onChange={cambioEnForm}
                        />
                  </Form.Group>
              </Col>
            </Row>
            </Container></Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handCloseCancel}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddCliente;