import React from "react";
import axios from 'axios';
import {Form} from 'react-bootstrap';
import { useEffect, useState } from "react";

function SelectParamTest (props) {
  //console.log('props de select param: ', props.parametro);
  //console.log(props.valorDefault);
  const encabezado = props.parametro.charAt(0).toUpperCase() + props.parametro.slice(1);

  const [datos, setDatos] = useState([]);
  let nombre = '';
  if (props.nombre) {
    nombre = props.nombre;
  } else {
    nombre = props.parametro;
  }

  useEffect(() => {
    //console.log('se ejecuta useeffect SelectParam');
    axios({
      method: "POST",
      url:"/api/selectparam",
      data: {
        "param": props.parametro,
      }
    })
    .then((response) => {
      //console.log('axios: ', response.data.datos);
      setDatos(response.data.datos);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
  }, []);

  return (
    <>
      <Form.Select key={props.parametro} value={props.valorDefault} aria-label="Default select example" name={nombre} onChange={props.cambioEnForm}>
          <option key="0" value="">Seleccione {encabezado}</option>
          {datos.map((dato, idx) => {return <option key={dato.id}  value={dato.id}>{dato.nombre}</option>})}
      </Form.Select>
    </>
  )
}

export default SelectParamTest;