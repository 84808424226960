import React from "react";
import ClienteNegocio from "../../data/ClienteNegocio";
import Header from "../Header";
import Tablas from "../Tablas";

function Negocio () {
  return (
    <>
      <Tablas listado={ClienteNegocio} />
    </>
  );
};

export default Negocio;