import React, { useState, useEffect } from "react";
import axios from 'axios';

function ElementoEpp (parametro) {
  console.log('Entra en ElementoEpp: ', parametro);
  let elemento = "";
  let id_epp = "";
  let nombre_epp = "";
  let cod_epp = "";

  // ejemplo parametro: PRT/8/Pertiga/tipo_pertiga
  // se agrega opcional el codigo epp al final para consultar por un elemento en particular
  // PRT/8/Pertiga/tipo_pertiga/PRT-00001
  const nombre_tabla = parametro;
  if (parametro.indexOf("/") >= 0) {
    var k = parametro.split("/")
    elemento = k[0];
    id_epp = k[1];
    nombre_epp = k[2];
    cod_epp = k[4];
  }
  //const elemento = "Guantes";
  //const id_epp = "1";

  const [datos, setDatos] = useState([]);
  //const [clase_epp, setClase_epp] = useState(0);
  //const [botonAgregar, setBotonAgregar] = useState('');

  const titulo="Epp: ".concat(nombre_epp);
  let clase_epp = "0";
  let botonAgregar = "";
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ];

  console.log('elementos Epp: ', elemento, id_epp);
  if (inventarioEpp.indexOf(elemento) >= 0) {
    //setClase_epp(1);
    //setBotonAgregar('eppguante');
    clase_epp = id_epp;
    //botonAgregar = "eppguante";
    botonAgregar = elemento;
  } else {
    clase_epp = "99";
  }
  useEffect(() => {
    console.log('se ejecuta useeffect ElementoEpp--> ', clase_epp);
    axios({
      method: "POST",
      url:"/api/listaepps",
      data: {
        "clase_epp": clase_epp,
        "cod_epp": cod_epp,
      }
    })
    .then((response) => {
      //console.log('axios de ElemtoEpp: ', response.data.datos);
      setDatos(response.data.datos);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
  }, []);
  /*
  const datos = [
      {
        n_serie: 'GUA-123',
        cliente: 'cliente 1',
        sucursal: 'Arica',
        negocio: 'Sae',
        estado: 'Cert Emitido'
      },
      {
        n_serie: 'GUA-124',
        cliente: 'cliente 1',
        sucursal: 'Iquique',
        negocio: 'Medición',
        estado: 'Cert Emitido'
      },
      {
        n_serie: 'GUA-125',
        cliente: 'cliente 5',
        sucursal: 'Rancagua',
        negocio: 'Sae',
        estado: 'Cert Emitido'
      },
      {
        n_serie: 'GUA-126',
        cliente: 'cliente 7',
        sucursal: 'Osorno',
        negocio: 'Medición',
        estado: 'Cert Emitido'
      },
      {
        n_serie: 'GUA-128',
        cliente: 'cliente 1',
        sucursal: 'Santiago',
        negocio: 'Sae',
        estado: 'Cert Emitido'
      },
      {
        n_serie: 'GUA-130',
        cliente: 'cliente 1',
        sucursal: 'Ovalle',
        negocio: 'Sae',
        estado: 'Cert Emitido'
      },
  ];
*/
  const columnas = [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: 'num_serie',
      accessor: 'num_serie'
    }, {
      Header: 'cliente',
      accessor: 'cliente'
    },{
      Header: 'negocio',
      accessor: 'negocio'
    }, {
      Header: 'sucursal',
      accessor: 'sucursal'
    }, {
      Header: 'estado',
      accessor: 'estado'
    }
  ];

  

  return {
    columnas,
    datos,
    titulo,
    botonAgregar,
    nombre_tabla,
  };
};

export default ElementoEpp;