import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {ArrowCounterclockwise} from 'react-bootstrap-icons';

function RevierteEmitido (props) {
    const rutaCompleta = props.nombre_tabla;


//**************************************************************************** */
async function revierte_emitir () {
    console.log('revierte', props.estado, props.codigo);
    //const aleatorio = Math.floor(Math.random()*1000).toString()
    ////setShow(false); //cierra la ventana popup
    //props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));

    
    await axios.request({
      url: "/api/reviertecert",
      method: "POST",
      data: {"cod_ensayo": props.codigo}
      }).then((response) => {
        //setValorTec({"valor": response.data.datos[0].tecnico})
        //setDetalleEnsayo(response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      //window.location.reload(false);
      const aleatorio = Math.floor(Math.random()*1000).toString()
      //setShow(false); //cierra la ventana popup
      props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
      
  }
  //**************************************************************************** */

    return (<>
        <button 
        className="btn btn-outline-primary" 
        size="sm" 
        data-toggle="tooltip" 
        data-placement="top"
        title="Revertir Emitido" 
        onClick={()=>revierte_emitir()} ><ArrowCounterclockwise />
        </button>
    </>)
};

export default RevierteEmitido;