import React, { useState, useEffect } from 'react';
import {Button, Modal, Form, Dropdown, ButtonGroup, Container, Row, Col, Badge, InputGroup, Card} from 'react-bootstrap';
import SelectParam from './selectores/SelectParam';


function ListadoDetalleIngresoCam (props) {
  //console.log('props.datosDetalleCAM', props.dataDetalle)
  const dataCam = props.dataDetalle;
  const patron1 = dataCam.detalle?dataCam.detalle.patron1.descripcion:'Patron 1';
  const patron2 = dataCam.detalle?dataCam.detalle.patron2.descripcion:'Patron 2';
  //console.log('dataCam', dataCam);

 
  return (
    <>
                <Row>
                    <Card >
                        <Card.Header><span className="titulo-card">SERIE EPP </span></Card.Header>
                    </Card>
                </Row>
                <Row>
                    <Col xs={6} md={4}>
                        <InputGroup id="input-serie-epp">
                            <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                            <Form.Control
                            disabled={dataCam.detalle?true:false}
                            value={dataCam.detalle?dataCam.detalle.serie_epp.slice(4,10):undefined}
                            onChange={props.cambioEnEppCam}
                            name="serie"
                            type="number"
                            placeholder="Serie epp"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={3} md={2}>
                        {dataCam.detalle?
                        <Button variant="primary" onClick={props.borraCam}>Liberar</Button>:
                        <Button variant="primary" onClick={props.validarEpp}>Validar</Button>}
                        
                    </Col>
                    <Col xs={9} md={6}>
                        <p><span className={props.validacionEpp.error?'msg-error':'titulo-card'}>{props.validacionEpp.msg}</span></p>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">PATRONES</span></Card.Header>
                    </Card>
                </Row>
                <Row >
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron1">
                        <Form.Label><span className="campo">{patron1}</span>
                        </Form.Label>
                        <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="Default select example" name="patron1"  onChange={props.cambioDetalleCam}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron2">
                        <Form.Label><span className="campo">{patron2}</span>
                        </Form.Label>
                        <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="Default select example" name="patron2"  onChange={props.cambioDetalleCam}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">PLUMA SUPERIOR</span></Card.Header>
                    </Card>
                </Row>
                <Row >
                    {/* Dielectrico */}
                    <Col xs={3} md={2}>
                            <Form.Group className="mb-3" controlId="plsup_cat">
                            <Form.Label><span className="campo">Categoría</span></Form.Label>
                            <Form.Control
                              name="plsup_cat"
                              type="text"
                              onChange={props.cambioDetalleCam}
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_cat:undefined}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_ais">
                            <Form.Label><span className="campo">Aislación Eléctrica Medida (GΩ)</span></Form.Label>
                            <Form.Control
                              name="plsup_ais"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_ais:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                </Row>
                <Row >
                    {/* Tabla */}
                    
                    
                    <Col xs={3} md={2}>
                            <Form.Group className="mb-3" controlId="plsup_tension1">
                            <Form.Label><span className="campo">Tensión Aplicada (kV)</span></Form.Label>
                            <Form.Control
                              name="plsup_tension1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_tension1:undefined}
                              placeholder='Tensión 1'
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_tiempo1">
                            <Form.Label><span className="campo">Tiempo (S)</span></Form.Label>
                            <Form.Control
                              name="plsup_tiempo1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_tiempo1:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_fuga1">
                            <Form.Label><span className="campo">I Fuga (µA)</span></Form.Label>
                            <Form.Control
                              name="plsup_fuga1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_fuga1:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_imax1">
                            <Form.Label><span className="campo">I Máx Fuga Permitida (µA)</span></Form.Label>
                            <Form.Control
                              name="plsup_imax1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_imax1:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Label><span className="campo">RESULTADO</span></Form.Label>
                        <Form.Check type="checkbox" id="plsup_ar1" >
                        <Form.Check.Input name="plsup_ar1" type="checkbox" 
                            defaultChecked={dataCam.detalle?dataCam.detalle.plsup_ar1:false} 
                            isValid onChange={props.cambioDetalleCam} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
                <Row >
                    {/* Tabla */}
                    <Col xs={3} md={2}>
                            <Form.Group className="mb-3" controlId="form-8">
                            <Form.Control
                              name="plsup_tension2"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_tension2:undefined}
                              placeholder='Tensión 2'
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_tiempo2">
                            <Form.Control
                              name="plsup_tiempo2"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_tiempo2:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_fuga2">
                            <Form.Control
                              name="plsup_fuga2"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_fuga2:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_imax2">
                            <Form.Control
                              name="plsup_imax2"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_imax2:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Check  value="plsup_ar2" type="checkbox" id="plsup_ar2" >
                        <Form.Check.Input name="plsup_ar2" type="checkbox" 
                            isValid defaultChecked={dataCam.detalle?dataCam.detalle.plsup_ar2:false} 
                            onChange={props.cambioDetalleCam} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
                <Row >
                    {/* Tabla */}
                    <Col xs={3} md={2}>
                            <Form.Group className="mb-3" controlId="plsup_tension3">
                            <Form.Control
                              name="plsup_tension3"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_tension3:undefined}
                              placeholder='Tensión 3'
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_tiempo3">
                            <Form.Control
                              name="plsup_tiempo3"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_tiempo3:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_fuga3">
                            <Form.Control
                              name="plsup_fuga3"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_fuga3:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plsup_imax3">
                            <Form.Control
                              name="plsup_imax3"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plsup_imax3:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Check  value="plsup_ar3" type="checkbox" id="plsup_ar3" >
                        <Form.Check.Input name="plsup_ar3" type="checkbox" 
                            isValid defaultChecked={dataCam.detalle?dataCam.detalle.plsup_ar3:false}
                            onChange={props.cambioDetalleCam} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">PLUMA INFERIOR</span></Card.Header>
                    </Card>
                </Row>
                <Row >
                    {/* Dielectrico */}
                    <Col xs={3} md={2}>
                            <Form.Group className="mb-3" controlId="plinf_cat">
                            <Form.Label><span className="campo">Categoría</span></Form.Label>
                            <Form.Control
                              name="plinf_cat"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plinf_cat:"(kV)"}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plinf_ais">
                            <Form.Label><span className="campo">Aislación Eléctrica Medida (GΩ)</span></Form.Label>
                            <Form.Control
                              name="plinf_ais"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plinf_ais:"(mA)"}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                </Row>
                <Row >
                    {/* Tabla */}
                    
                    
                    <Col xs={3} md={2}>
                            <Form.Group className="mb-3" controlId="plinf_tension1">
                            <Form.Label><span className="campo">Tensión Aplicada (kV)</span></Form.Label>
                            <Form.Control
                              name="plinf_tension1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plinf_tension1:undefined}
                              placeholder='Tensión 1'
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plinf_tiempo1">
                            <Form.Label><span className="campo">Tiempo (S)</span></Form.Label>
                            <Form.Control
                              name="plinf_tiempo1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plinf_tiempo1:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plinf_fuga1">
                            <Form.Label><span className="campo">I Fuga (µA)</span></Form.Label>
                            <Form.Control
                              name="plinf_fuga1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plinf_fuga1:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col xs={3} md={2}>
                    <Form.Group className="mb-3" controlId="plinf_imax1">
                            <Form.Label><span className="campo">I Máx Fuga Permitida (µA)</span></Form.Label>
                            <Form.Control
                              name="plinf_imax1"
                              type="text"
                              defaultValue={dataCam.detalle?dataCam.detalle.plinf_imax1:undefined}
                              onChange={props.cambioDetalleCam}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Label><span className="campo">RESULTADO</span></Form.Label>
                        <Form.Check  value="plinf_ar1" type="checkbox" id="plinf_ar1" >
                        <Form.Check.Input name="plinf_ar1" type="checkbox" 
                            isValid defaultChecked={dataCam.detalle?dataCam.detalle.plinf_ar1:false} 
                            onChange={props.cambioDetalleCam} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
  </>
   
  )
}

export default ListadoDetalleIngresoCam;