import React, { useState , useEffect} from "react";
import axios from 'axios';

function TiposGenerales (parametro) {
  
  
  let elemento = '';
  let tabla_en_base = '';
  
  if (parametro.indexOf("/") >= 0) {
    var k = parametro.split("/")
    elemento = k[1];
    tabla_en_base = k[2];
  };
  //console.log('Tipos generales: elemento: ', elemento );
  //console.log('Tipos generales: tabla_en_base: ', tabla_en_base );
  //console.log('TipoEpp - parametro: ', parametro);
  const nombre_tabla = parametro;
  const titulo="Ingreso de ".concat(elemento);
  const botonAgregar = elemento;
  /*
  console.log('Tipos generales: nombre_tabla: ', nombre_tabla );
  console.log('Tipos generales: titulo: ', titulo );
  console.log('Tipos generales: botonAgregar: ', botonAgregar );
*/
  //console.log('valor elemento en TipoEpp: ', elemento);

  const [datos, setDatos] = useState();
  const [columnas, setColumnas] = useState();
  //const [datosTabla, setDatosTabla] = useState({"datos":[], "columnas": []});
  //let datos = datosTabla.datos;
  //let columnas= datosTabla.columnas;

  //var ak = getHeaders(id_epp).then(function(resultado) {setArreglo(resultado)})
  //console.log('TipoEpp - datos_Tabla - ak: ', ak);

  /*
  useEffect(() => {
    const datosDeFuncion = getHeaders(1);
    setDatosTabla(datosDeFuncion);
  }, []
  )
|*/

  
  useEffect(() => {
    //console.log('se ejecuta useeffect TiposGenerales--> ', tabla_en_base);
    axios({
      method: "POST",
      url:"/api/gettablapornombre",
      data: {
        "nombre_tabla": tabla_en_base,
      }
    })
    .then((response) => {
      //console.log(response.data.datos);
      let tColumnas = response.data.headers.map((e) => {return {"Header":e, "accessor":e};});
      let tDatos = response.data.datos.map((f) => {
        let valor = '';
        f.forEach(function(e, idx) {
          //console.log('tColumna', response.data.headers[idx])
          if (valor === ''){
            valor=valor.concat('"',response.data.headers[idx],'":"',e,'"');
          }else {
            valor=valor.concat(',"',response.data.headers[idx],'":"',e,'"');
          }
        })
        valor="{".concat(valor, "}")
        return JSON.parse(valor);}
        );
        setDatos(tDatos);
        setColumnas(tColumnas);
        //setDatosTabla({"datos": tDatos, "columnas": tColumnas});
        //console.log('columnas -> ', tColumnas);
        //console.log('datos    -> ', tDatos);
      //response.data.datos.map((e) => {console.log('dato header: ', e)})
      //console.log('axios de TipoEpp: ', response.data.headers, response.data.datos);
      //setDatos(response.data.datos);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
  },[]);
  
  

  async function getHeaders(clase_epp) {
   // console.log('Entra en getHeaders: ', clase_epp);
    let columnas;
    let datos;
    await axios({
      method: "POST",
      url:"/api/gettablaepp",
      data: {
        "epp": clase_epp,
      }
    })
    .then((response) => {
      //console.log(response.data.datos);
      let tColumnas = response.data.headers.map((e) => {return {"Header":e, "accessor":e};});
      let tDatos = response.data.datos.map((f) => {
        let valor = '';
        f.forEach(function(e, idx) {
          //console.log('tColumna', response.data.headers[idx])
          if (valor === ''){
            valor=valor.concat('"',response.data.headers[idx],'":"',e,'"');
          }else {
            valor=valor.concat(',"',response.data.headers[idx],'":"',e,'"');
          }
        })
        valor="{".concat(valor, "}")
        return JSON.parse(valor);}
        );
        columnas = tColumnas;
        datos = tDatos;
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
        columnas = null;
        datos = null;
    })
    
    /*
    const columnas = [
      {
        Header: 'marca',
        accessor: 'marca'
      },{
        Header: 'largo',
        accessor: 'largo',
        //Cell: ({ cell: { value } }) => {return (<><Trash />{value}</>)}
      }, {
        Header: 'clase',
        accessor: 'clase'
      }, {
        Header: 'max_i_fuga',
        accessor: 'max_i_fuga'
      }
    ];
    const datos = [
      {
        marca: 'Salisbury',
        largo: '280',
        clase: '0',
        max_i_fuga: '5'
      },
      {
          marca: 'Novax',
          largo: '360',
          clase: '4',
          max_i_fuga: '5'
        },
        {
          marca: 'Chance',
          largo: '280',
          clase: '4',
          max_i_fuga: '5'
        },
        {
          marca: 'Regeltex',
          largo: '410',
          clase: '0',
          max_i_fuga: '6'
        },
        {
          marca: 'Salisbury',
          largo: '360',
          clase: '4',
          max_i_fuga: '5'
        },
        {
          marca: 'Novax',
          largo: '410',
          clase: '3',
          max_i_fuga: '5'
        },
        {
          marca: 'Otra',
          largo: '280',
          clase: '0',
          max_i_fuga: '5'
        },
    ];
    */
    return {
      columnas: columnas,
      datos: datos,
    }
  }

  function getDatos () {
    const datos = [
      {
        marca: 'Salisbury',
        largo: '280',
        clase: '0',
        max_i_fuga: '5'
      },
      {
          marca: 'Novax',
          largo: '360',
          clase: '4',
          max_i_fuga: '5'
        },
        {
          marca: 'Chance',
          largo: '280',
          clase: '4',
          max_i_fuga: '5'
        },
        {
          marca: 'Regeltex',
          largo: '410',
          clase: '0',
          max_i_fuga: '6'
        },
        {
          marca: 'Salisbury',
          largo: '360',
          clase: '4',
          max_i_fuga: '5'
        },
        {
          marca: 'Novax',
          largo: '410',
          clase: '3',
          max_i_fuga: '5'
        },
        {
          marca: 'Otra',
          largo: '280',
          clase: '0',
          max_i_fuga: '5'
        },
    ];
    return datos;
  }
  
  /*
  const columnas = [
    {
      Header: 'marca',
      accessor: 'marca'
    },{
      Header: 'largo',
      accessor: 'largo',
      //Cell: ({ cell: { value } }) => {return (<><Trash />{value}</>)}
    }, {
      Header: 'clase',
      accessor: 'clase'
    }, {
      Header: 'max_i_fuga',
      accessor: 'max_i_fuga'
    }
  ];
  */
  return {
    columnas,
    datos,
    titulo,
    botonAgregar,
    nombre_tabla
  };
};

export default TiposGenerales;