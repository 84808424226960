import React from "react";

function ClienteNegocio () {
  const datos = [
    {
      cliente: 'cliente01',
      negocio: 'SAE',
      sucursal: 'Iquique'
    },
    {
      cliente: 'cliente01',
      negocio: 'Medicion',
      sucursal: 'Iquique'
    },
    {
      cliente: 'cliente02',
      negocio: 'SAE',
      sucursal: 'Arica'
    },

  ];

  const columnas = [
    {
      Header: 'cliente',
      accessor: 'cliente'
    }, {
      Header: 'negocio',
      accessor: 'negocio'
    }, {
      Header: 'sucursal',
      accessor: 'sucursal'
    }
  ];

  const titulo="Listado de Clientes/Negocio/Sucursal";
  const botonAgregar = "negocio";

  return {
    columnas,
    datos,
    titulo,
    botonAgregar,
  };
};

export default ClienteNegocio;