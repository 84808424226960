import React from "react";

function Footer () {
  return (
    <>
      <h2></h2>
    </>
  );
};

export default Footer;