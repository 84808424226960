import React, {useState} from "react";
import axios from 'axios';
import Container from "react-bootstrap/esm/Container";
import { Row, Col, Card, ListGroup } from "react-bootstrap";
//import {Navbar, Nav, NavDropdown} from 'react-bootstrap/Navbar';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import Button from 'react-bootstrap/Button';
import logosm1 from "../imagenes/logosm1";


import Form from 'react-bootstrap/Form';
import '../estilos/Login.css';

const schema = yup.object().shape({
  //email: yup.string().email('Formato incorrecto').required('Ingrese email'),
  email: yup.string().required('Ingrese usuario'),
  password: yup.string(6).required('password de al menos 6 caracteres'),
});

function Login(props) {
  const { logo } = logosm1();

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const [errorAxios, setErrorAxios] = useState('');

  const onSubmitHandler = (data) => {
    //console.log({ data });
    axios({
      method: "POST",
      url:"/api/token",
      data: data,
    })
    .then((response) => {
      //console.log('respuesta de api token perfil: ', response.data.perfil);
      props.setToken(response.data.access_token, data.email, response.data.menu_inventario, response.data.menu_ensayo, response.data.perfil )
    }).catch((error) => {
      if (error.response) {
        setErrorAxios(error.response.data.msg);
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });

    window.name=""
    //props.cambiarLogin(true);
    reset();
  };

  return (
    <>
        {/*<h1>Ingreso</h1>*/}
        <Row>
            <Col md={{ span: 8, offset: 4 }}>
            <Card className="text-center" style={{ width: '30rem' }}>
                <Card.Header ></Card.Header>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item><img  src={logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras">Laboratorio Ensayo Equipos Dieléctricos</span></ListGroup.Item>
                </ListGroup>
                <Card.Body>
                    <Card.Title><span className="letras-negras">SISTEMA GESTIÓN LABORATORIO</span></Card.Title>
                    <Card.Text>
                    <span className="letras-negras">MÓDULO CLIENTES</span>
                    </Card.Text>

        
        {/*<Form className="form-signin" onSubmit={handleSubmit(onSubmitHandler)}>*/}
        <Form  onSubmit={handleSubmit(onSubmitHandler)}>
          <br />

          <input {...register("email")} placeholder="usuario" type="text" required />
          <span className="msg-error">
          <p>{errors.email?.message}</p>
          </span>
          <br />

          <input
            {...register("password")}
            placeholder="password"
            type="password"
            required
          />
          <span className="msg-error">
          <p>{errors.password?.message}</p>
          <p>{errorAxios?errorAxios:<></>}</p>
          </span>
          <br />

          <Button type="submit" color='primary'><span className="letras-blancas">Ingresar</span></Button>
        </Form>


        </Card.Body>
                  <Card.Footer className="text-muted"></Card.Footer>
              </Card>
              </Col>
          </Row>
    </>
  );
};

export default Login;

