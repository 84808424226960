import React from "react";
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Dropdown, ButtonGroup} from 'react-bootstrap';
import {PencilSquare} from 'react-bootstrap-icons';
import SelectParam from "./selectores/SelectParam";
import SelectParamTest from "./selectores/SelectParamTest";

function EditParamTest(props) {

  //console.log('props edit: ', props);
  let nombre_epp = "";
  let tabla_tipo_epp = "";
  if (props.nombre_tabla.indexOf("/") >= 0) {
    var k = props.nombre_tabla.split("/")
    nombre_epp = k[1];
    tabla_tipo_epp = k[2];
  };

  let valor = '';
  props.columnas.map((e) => {return e.accessor;}).forEach(function(e, idx) {
    //console.log('tColumna', response.data.headers[idx])
    if (!valor.substring(0,6) == 'estado') {
      if (valor === ''){
        valor=valor.concat('"',e,'":""');
      }else {
        valor=valor.concat(',"',e,'":""');
      }
    }
  })
  valor="{".concat(valor, "}")
  //console.log('valor:', valor);
  const [formEpp, setFormEpp] = useState(JSON.parse(valor));
  const [formEppError, setFormEppError] = useState(JSON.parse(valor));
  const [formConError, setFormConError] = useState();
  const [show, setShow] = useState(false);
  function handleClose () {
    setShow(false);
  }
  function handleShow () {
    //setFormEpp(JSON.parse(valor));
    //setFormEppError(JSON.parse(valor));
    axios({
        method: "POST",
        url:"/api/gettablapornombre_id",
        data: {
            tabla_tipo: tabla_tipo_epp,
            id_parametro: props.celdas.num,
        }
      })
      .then((response) => {
        //console.log(response.data.datos);
        setFormEpp(response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response.data.msg)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      })
      /*
    const dataForm = {
        tabla_tipo: tabla_tipo_epp,
        datos: JSON.stringify(formEpp),
        id_parametro: props.celdas.num,
    };
    console.log('formulario: ', dataForm);
    */
    setFormConError(null);
    setShow(true);
  }
  function grabaForm () {
    //console.log('funcion grabar');
    let hay = false;
    for (const [key, value] of Object.entries(formEpp)) {
      //console.log(`${key}: ${value}`);
        if (!value) {
          hay = true;
          setFormConError(key);
        }
    }
    if (!hay) {
      //no hay errores, grabar el dato
      const dataForm = {
        tabla_tipo: tabla_tipo_epp,
        datos: JSON.stringify(formEpp),
        id_parametro: props.celdas.num,
      }
      //console.log(dataForm);
      axios({
        method: "POST",
        url:"/api/grabatipoepp",
        data: dataForm
      })
      .then((response) => {
        //console.log('axios: ', response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      const aleatorio = Math.floor(Math.random()*1000).toString()
      setShow(false); //cierra la ventana popup
      props.setRuta('/paramrefresca'.concat('_',aleatorio,props.nombre_tabla));

    }
  }

  function cambioEnForm (event) {
    //console.log('funcion cambiaform');
    const {value, name} = event.target;
    setFormEpp(prevNote => ({...prevNote, [name]: value}))
    setFormConError(null);
  }
    return (
        <>
            <button className="btn btn-outline-primary" size="sm" onClick={handleShow} ><PencilSquare /></button>
            <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Modificar {nombre_epp}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
              {nombre_epp==='patron'?
              <>
                <Form.Group className="mb-3" controlId="patron.descripcion">
                  <Form.Label><span className="campo">Descripción</span></Form.Label>
                  <Form.Control
                    value={formEpp.descripcion}
                    name="descripcion"
                    type="text"
                    placeholder="Descripción"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patron.nombre_marca">
                  <Form.Label><span className="campo">Marca</span></Form.Label>
                  <Form.Control
                    value={formEpp.nombre_marca}
                    name="nombre_marca"
                    type="text"
                    placeholder="Marca"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patron.modelo">
                  <Form.Label><span className="campo">Modelo</span></Form.Label>
                  <Form.Control
                    value={formEpp.modelo}
                    name="modelo"
                    type="text"
                    placeholder="Modelo"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="patron.serie">
                  <Form.Label><span className="campo">Serie</span></Form.Label>
                  <Form.Control
                    value={formEpp.serie}
                    name="serie"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="id marca">
                  <Form.Label><span className="campo">Seleccione mes calibración {nombre_epp}</span>
                  </Form.Label>
                    <SelectParamTest parametro="mes_calibracion" valorDefault={formEpp.mes_calibracion} cambioEnForm={cambioEnForm} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="id marca">
                  <Form.Label><span className="campo">Seleccione período calibración {nombre_epp}</span>
                  </Form.Label>
                    <SelectParamTest parametro="periodo_calibracion" valorDefault={formEpp.periodo_calibracion} cambioEnForm={cambioEnForm} />
                </Form.Group>
              </>:
              null
              }
              {nombre_epp==='usuario'?
              <>
              <Form.Group className="mb-3" controlId="usuario.nombre_usuario">
              <Form.Label><span className="campo">Nombre Usuario</span></Form.Label>
              <Form.Control
                value={formEpp.nombre_usuario}
                name="nombre_usuario"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="usuario.password">
              <Form.Label><span className="campo">Password</span></Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder=""
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="usuario.nombre_persona">
              <Form.Label><span className="campo">Nombre Persona</span></Form.Label>
              <Form.Control
                value={formEpp.nombre_persona}
                name="nombre_persona"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="usuario.rut">
              <Form.Label><span className="campo">Rut</span></Form.Label>
              <Form.Control
                value={formEpp.rut}
                name="rut"
                type="text"
                placeholder="Rut"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="usuario.email">
              <Form.Label><span className="campo">Email</span></Form.Label>
              <Form.Control
                value={formEpp.email}
                name="email"
                type="email"
                placeholder="email"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="usuario.telefono">
              <Form.Label><span className="campo">Teléfono</span></Form.Label>
              <Form.Control
                value={formEpp.telefono}
                name="telefono"
                type="telefono"
                placeholder="fono"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='tecnico'?
              <>
              <Form.Group className="mb-3" controlId="tecnico.tecnico_ensayo">
              <Form.Label><span className="campo">Nombre Técnico</span></Form.Label>
              <Form.Control
                value={formEpp.tecnico_ensayo}
                name="tecnico_ensayo"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="tecnico.comentario">
              <Form.Label><span className="campo">Comentario</span></Form.Label>
              <Form.Control
                value={formEpp.comentario}
                name="comentario"
                type="text"
                placeholder="Comentario"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='marca'?
              <>
              <Form.Group className="mb-3" controlId="marca.nombre_marca">
              <Form.Label><span className="campo">Nombre Marca</span></Form.Label>
              <Form.Control
                value={formEpp.nombre_marca}
                name="nombre_marca"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='cuerpos_aterramiento'?
              <>
              <Form.Group className="mb-3" controlId="cuerpos_aterramiento.cuerpos">
              <Form.Label><span className="campo">Número Cuerpos</span></Form.Label>
              <Form.Control
                value={formEpp.cuerpos}
                name="cuerpos"
                type="text"
                placeholder="Cuerpos"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='cuerpos_pertiga'?
              <>
              <Form.Group className="mb-3" controlId="cuerpos_pertiga.cuerpos">
              <Form.Label><span className="campo">Número Cuerpos</span></Form.Label>
              <Form.Control
                value={formEpp.cuerpos}
                name="cuerpos"
                type="text"
                placeholder="Cuerpos"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='largo_guante'?
              <>
              <Form.Group className="mb-3" controlId="largo_guante.largo_guante">
              <Form.Label><span className="campo">Largo Guante</span></Form.Label>
              <Form.Control
                value={formEpp.largo_guante}
                name="largo_guante"
                type="text"
                placeholder="Largo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='modelo_cubrelinea'?
              <>
              <Form.Group className="mb-3" controlId="modelo_cubrelinea.modelo_cubrelinea">
              <Form.Label><span className="campo">Modelo Cubrelinea</span></Form.Label>
              <Form.Control
                value={formEpp.modelo_cubrelinea}
                name="modelo_cubrelinea"
                type="text"
                placeholder="Modelo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='largo_manta'?
              <>
              <Form.Group className="mb-3" controlId="largo_manta.largo_manta">
              <Form.Label><span className="campo">Largo Manta</span></Form.Label>
              <Form.Control
                value={formEpp.largo_manta}
                name="largo_manta"
                type="text"
                placeholder="Largo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='largo_cubreposte'?
              <>
              <Form.Group className="mb-3" controlId="largo_cubreposte.largo_cubreposte">
              <Form.Label><span className="campo">Largo Cubreposte</span></Form.Label>
              <Form.Control
                value={formEpp.largo_cubreposte}
                name="largo_cubreposte"
                type="text"
                placeholder="Largo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='negocio'?
              <>
              <Form.Group className="mb-3" controlId="negocio.nombre_negocio">
              <Form.Label><span className="campo">Nombre Negocio</span></Form.Label>
              <Form.Control
                value={formEpp.nombre_negocio}
                name="nombre_negocio"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='modelo_aterramiento'?
              <>
              <Form.Group className="mb-3" controlId="modelo_aterramiento.modelo">
              <Form.Label><span className="campo">Nombre Modelo</span></Form.Label>
              <Form.Control
                value={formEpp.modelo}
                name="modelo"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='modelo_pertiga'?
              <>
              <Form.Group className="mb-3" controlId="modelo_pertiga.modelo">
              <Form.Label><span className="campo">Nombre Modelo</span></Form.Label>
              <Form.Control
                value={formEpp.modelo}
                name="modelo"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='cliente'?
              <>
                <Form.Group className="mb-3" controlId="cliente.nombre">
                  <Form.Label><span className="campo">Nombre</span></Form.Label>
                  <Form.Control
                    value={formEpp.nombre}
                    name="nombre"
                    type="text"
                    placeholder="Nombre"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cliente.direccion">
                  <Form.Label><span className="campo">Direccion</span></Form.Label>
                  <Form.Control
                    value={formEpp.direccion}
                    name="direccion"
                    type="text"
                    placeholder="direccion"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cliente.representante">
                  <Form.Label><span className="campo">Representante</span></Form.Label>
                  <Form.Control
                    value={formEpp.representante}
                    name="representante"
                    type="text"
                    placeholder="Representante"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cliente.telefono">
                  <Form.Label><span className="campo">Telefono</span></Form.Label>
                  <Form.Control
                    value={formEpp.telefono}
                    maxLength={10}
                    name="telefono"
                    type="text"
                    placeholder="Telefono"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="cliente.nombre_corto">
                  <Form.Label><span className="campo">Nombre corto</span></Form.Label>
                  <Form.Control
                    value={formEpp.nombre_corto}
                    maxLength={7}
                    name="nombre_corto"
                    type="text"
                    placeholder="Nombre corto"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
              </>:
              null
              }
          </Form>
          {formConError?<p><span className = "mensaje-error">{"Complete el valor de ".concat(formConError)}</span></p>:null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={grabaForm}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    );
};
export default EditParamTest;