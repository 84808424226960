import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Dropdown, ButtonGroup} from 'react-bootstrap';
import '../estilos/AddNegocio.css';
import SelectParam from "./selectores/SelectParam";

function AddTipoParam (props) {
  //console.log('AddtipoParam: ', props.nombre_tabla)
  let elemento = "";
  let id_epp = "";
  let nombre_epp = "";
  let tabla_tipo_epp = "";
  if (props.nombre_tabla.indexOf("/") >= 0) {
    var k = props.nombre_tabla.split("/")
    //elemento = k[0];
    //id_epp = k[1];
    nombre_epp = k[1];
    tabla_tipo_epp = k[2];
  };

  let valor = '';
  props.columnas.map((e) => {return e.accessor;}).forEach(function(e, idx) {
    //console.log('tColumna', response.data.headers[idx])
    if (!valor.substring(0,6) == 'estado') {
      if (valor === ''){
        valor=valor.concat('"',e,'":""');
      }else {
        valor=valor.concat(',"',e,'":""');
      }
    }
  })
  valor="{".concat(valor, "}")

  const [formEpp, setFormEpp] = useState(JSON.parse(valor));
  const [formEppError, setFormEppError] = useState(JSON.parse(valor));
  const [formConError, setFormConError] = useState();

  const campos = props.columnas.map((e) => {return e.accessor;}).filter((e) => {return e.substring(0,3) !== "max";}).map((f) => {return f;});

  //console.log('props.columnas',  props.columnas);
  //console.log('Campos', campos);
  const [show, setShow] = useState(false);

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  function cambioEnForm (event) {
    const {value, name} = event.target;
    setFormEpp(prevNote => ({...prevNote, [name]: value}))
    setFormConError(null);
    //console.log('cambio en form')
  }

  function handleClose () {
    setShow(false);
  }
  function handleShow () {
    setFormEpp(JSON.parse(valor));
    setFormEppError(JSON.parse(valor));
    setFormConError(null);
    setShow(true);
  }

  async function grabaForm () {
    //console.log(formEpp);
    let hay = false;
    let cantidad = 0;
    for (const [key, value] of Object.entries(formEpp)) {
      cantidad = cantidad + 1;
      //console.log(`${key}: ${value}`);
        if (!value) {
          hay = true;
          setFormConError("Complete el valor de ".concat(key));
        }
    }
    if (cantidad == 0){
      setFormConError('Complete todos los datos');
      hay = true;
    }
    if (!hay) {
      //no hay errores, grabar el dato
      const dataForm = {
        tabla_tipo: tabla_tipo_epp,
        datos: JSON.stringify(formEpp),
      }
      //console.log(dataForm);
      await axios({
        method: "POST",
        url:"/api/grabatipoepp",
        data: dataForm
      })
      .then((response) => {
        //console.log('axios: ', response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log('axios error: ', error.response.data.msg);
          setFormConError(error.response.data.msg);
          hay = true;
          }
      });
      //console.log('hay: ', hay);
      if (!hay){
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        props.setRuta('/paramrefresca'.concat('_',aleatorio,props.nombre_tabla));
      }
    }
    //setShow(false);
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        +Agregar {nombre_epp}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Agregar {nombre_epp}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
              {nombre_epp==='patron'?
              <>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Descripción</span></Form.Label>
                  <Form.Control
                    name="descripcion"
                    type="text"
                    placeholder="Descripción"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Marca</span></Form.Label>
                  <Form.Control
                    name="nombre_marca"
                    type="text"
                    placeholder="Marca"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Modelo</span></Form.Label>
                  <Form.Control
                    name="modelo"
                    type="text"
                    placeholder="Modelo"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Serie</span></Form.Label>
                  <Form.Control
                    name="serie"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="id marca">
                  <Form.Label><span className="campo">Seleccione mes calibración {nombre_epp}</span>
                  </Form.Label>
                    <Form.Select aria-label="Default select example" name="mes_calibracion"  onChange={cambioEnForm}>
                      <SelectParam parametro="mes_calibracion" />
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="id marca">
                  <Form.Label><span className="campo">Seleccione período calibración {nombre_epp}</span>
                  </Form.Label>
                    <Form.Select aria-label="Default select example" name="periodo_calibracion"  onChange={cambioEnForm}>
                      <SelectParam parametro="periodo_calibracion" />
                    </Form.Select>
                </Form.Group>
              </>:
              null
              
              }
              {nombre_epp==='tecnico'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Técnico</span></Form.Label>
              <Form.Control
                name="tecnico_ensayo"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Comentario</span></Form.Label>
              <Form.Control
                name="comentario"
                type="text"
                placeholder="Comentario"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='marca'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Marca</span></Form.Label>
              <Form.Control
                name="nombre_marca"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='cuerpos_aterramiento'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Número Cuerpos</span></Form.Label>
              <Form.Control
                name="cuerpos"
                type="text"
                placeholder="Cuerpos"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='cuerpos_pertiga'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Número Cuerpos</span></Form.Label>
              <Form.Control
                name="cuerpos"
                type="text"
                placeholder="Cuerpos"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='largo_guante'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Largo Guante</span></Form.Label>
              <Form.Control
                name="largo_guante"
                type="text"
                placeholder="Largo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='modelo_cubrelinea'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Modelo Cubrelinea</span></Form.Label>
              <Form.Control
                name="modelo_cubrelinea"
                type="text"
                placeholder="Modelo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='largo_manta'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Largo Manta</span></Form.Label>
              <Form.Control
                name="largo_manta"
                type="text"
                placeholder="Largo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='largo_cubreposte'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Largo Cubreposte</span></Form.Label>
              <Form.Control
                name="largo_cubreposte"
                type="text"
                placeholder="Largo"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='negocio'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Negocio</span></Form.Label>
              <Form.Control
                name="nombre_negocio"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='modelo_aterramiento'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Modelo</span></Form.Label>
              <Form.Control
                name="modelo"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='modelo_pertiga'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Modelo</span></Form.Label>
              <Form.Control
                name="modelo"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              </>
              :null
              }
              {nombre_epp==='usuario'?
              <>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Usuario</span></Form.Label>
              <Form.Control
                name="nombre_usuario"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Password</span></Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder=""
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Nombre Persona</span></Form.Label>
              <Form.Control
                name="nombre_persona"
                type="text"
                placeholder="Nombre"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Rut</span></Form.Label>
              <Form.Control
                name="rut"
                type="text"
                placeholder="Rut"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Email</span></Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="email"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Teléfono</span></Form.Label>
              <Form.Control
                name="telefono"
                type="telefono"
                placeholder="fono"
                onChange={cambioEnForm}
                autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="id marca">
                  <Form.Label><span className="campo">Seleccione Cliente</span>
                  </Form.Label>
                    <Form.Select aria-label="Default select example" name="cliente"  onChange={cambioEnForm}>
                      <SelectParam parametro="cliente" />
                    </Form.Select>
                </Form.Group>
              </>
              :null
              }
              {nombre_epp==='cliente'?
              <>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Nombre</span></Form.Label>
                  <Form.Control
                    value={formEpp.nombre}
                    name="nombre"
                    type="text"
                    placeholder="Nombre"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Direccion</span></Form.Label>
                  <Form.Control
                    value={formEpp.direccion}
                    name="direccion"
                    type="text"
                    placeholder="direccion"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Representante</span></Form.Label>
                  <Form.Control
                    value={formEpp.representante}
                    name="representante"
                    type="text"
                    placeholder="Representante"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Telefono</span></Form.Label>
                  <Form.Control
                    value={formEpp.telefono}
                    maxLength={10}
                    name="telefono"
                    type="text"
                    placeholder="Telefono"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label><span className="campo">Nombre corto</span></Form.Label>
                  <Form.Control
                    value={formEpp.nombre_corto}
                    maxLength={7}
                    name="nombre_corto"
                    type="text"
                    placeholder="Nombre corto"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                </Form.Group>
              </>:
              null
              }
          </Form>
          {formConError?<p><span className = "mensaje-error">{formConError}</span></p>:null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={grabaForm}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddTipoParam;