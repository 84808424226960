import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Container, Row, Col} from 'react-bootstrap';
import {Trash} from 'react-bootstrap-icons';
import {HandThumbsDown} from 'react-bootstrap-icons';
import '../estilos/AddNegocio.css';

function DelEpp (props) {

  //console.log('props delcliente: ', props.celdas.id);
  let accion='';
  if (props.accion == 'borra'){
    accion = 'Eliminar';
  }else if (props.accion == 'baja'){
    accion = 'Dar de baja';
  }

  const [show, setShow] = useState(false);
  const [msgError, setMsgError] = useState();

  function handCloseCancel () {
    setShow(false);
  }
  function handCloseGraba () {
    //setShow(false);
    //console.log('funcion guardaForm ' + JSON.stringify(formCliente))
      let hayError = false;
        axios.request({
            url: "/api/eliminaepp",
            method: "POST",
            data: {
                "id_epp": props.celdas.id,
                "accion": props.accion,
                }
            }).then((response) => {
              //setValorTec({"valor": response.data.datos[0].tecnico})
              //setDetalleEnsayo(response.data.datos);
              //console.log('ok', response.data.msg)
            }).catch((error) => {
              if (error.response) {
                //console.log('no ok', error.response)
                hayError=true;
                setMsgError(error.response.msg)
                //console.log(error.response)
                //console.log(error.response.status)
                //console.log(error.response.headers)
                }
            });
            if (!hayError) {
                //console.log('refresca: ', '/paramrefresca'.concat('_',aleatorio,props.nombre_tabla))
                //console.log('no hubo error', props.nombre_tabla)
                const aleatorio = Math.floor(Math.random()*1000).toString()
                setShow(false); //cierra la ventana popup
                props.setRuta('/gueppRefresca'.concat('_',aleatorio,'/',props.nombre_tabla));
            
            } else {
                //console.log('hubo error')
            }
  }
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  function handleShow () {
    //console.log(props);
    setShow(true);
  }
  return (
    <>
      {props.accion=='borra'?
        <button disabled={props.celdas.estado!=='ingresado'?true:false} className="btn btn-outline-primary" size="sm" onClick={handleShow}><Trash /></button>:null
      }
      {props.accion=='baja'?
      <button disabled={props.celdas.estado=='de baja'?true:false} className="btn btn-outline-primary" size="sm" onClick={handleShow}><HandThumbsDown /></button>:null  
      }
      <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title><h3>Confirma {accion} {props.elemento}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form><Container>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">{props.celdas.num_serie} </span></Form.Label>
                  </Form.Group>
              </Col>
            </Row>
            </Container></Form>
        </Modal.Body>
        <Modal.Footer>
          
        {props.accion=='borra'?
          <>
            <Button variant="secondary" onClick={handCloseCancel}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handCloseGraba}>
              Eliminar
            </Button></>:null
          }
          {props.accion=='baja'?
          <>
            <Button variant="secondary" onClick={handCloseCancel}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handCloseGraba}>
              Dar de baja
            </Button></>:null
          }
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default DelEpp;