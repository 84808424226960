import React, { useState } from 'react';
import {Table as TablaBoot, Button, input} from 'react-bootstrap';
import { Trash } from 'react-bootstrap-icons';

function ListadoDetalleJmp (props) {
  //console.log(props.datos)

  if (props.datos.length >= 6) {
    //llego al máximo valor del listado
    props.setListaDetalleCompleta(true);
  } else {
    props.setListaDetalleCompleta(false);
  }

  return (
    <>
      <TablaBoot striped bordered hover >
        <thead>
          <tr>
            <th>
              num_serie
            </th>
            <th>
              i_fuga
            </th>
            <th>
              tensión_ensayo
            </th>
            <th>
              I. Visual
            </th>
            <th>
              Resultado
            </th>
            <th>
              Acción
            </th>
          </tr>
        </thead>
        <tbody>
          {props.datos.map((detalle) => 
            <tr>
              <td>{detalle.serie_epp}</td>
              <td>{detalle.fuga1}</td>
              <td>{detalle.tension}</td>
              <td>{detalle.visual}</td>
              <td>{detalle.dieresul}</td>
              <td> <button onClick={() => {props.borraElementoDetalle(detalle.serie)}} class="btn btn-outline-primary" size="sm"><Trash /></button> </td>
            </tr>
            )}
        </tbody>
      </TablaBoot>
      <hr />
      <TablaBoot striped bordered hover >
        <thead>
          <tr>
            <th>
              num_serie
            </th>
            <th>
              tramo
            </th>
            <th>
              Ø conductor
            </th>
            <th>
              longitud
            </th>
            <th>
              R. Medida
            </th>
            <th>
              R. max
            </th>
            <th>
              Resultado
            </th>
          </tr>
        </thead>
        <tbody>
          {props.datos.map((detalle) => 
            <tr key={detalle.serie_epp} >
              <td>{detalle.serie_epp}</td>
              <td>{detalle.tramo}</td>
              <td>{detalle.seccion}</td>
              <td>{detalle.longitud}</td>
              <td>{detalle.resismedida}</td>
              <td>{detalle.resismax}</td>
              <td>{detalle.resisresul}</td>
            </tr>
            )}
        </tbody>
      </TablaBoot>
    </>
  )
}

export default ListadoDetalleJmp;