import React from "react";
import { Container, Row , Col} from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import '../estilos/Bienvenida.css';

function Bienvenida () {
    return (
    <>
    <Container className="contenedor-card" >
        <Row ></Row>
        <Row><Col>
        <Card>
        <Card.Header><span className="letras-negras">
            Laboratorio Ensayo Equipos Dieléctricos
            </span>
            </Card.Header>
        <Card.Body>
            <Card.Title><span className="letras-negras">SISTEMA GESTIÓN LABORATORIO</span></Card.Title>
            <Card.Text><span className="letras-negras">
            Sistema de Gestión y Administración de Elementos y Ensayos.</span>
            </Card.Text>
        </Card.Body>
        </Card></Col>
        </Row>
    </Container>
    </>
    )
}

export default Bienvenida;