import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Container, Row, Col} from 'react-bootstrap';
import {Trash} from 'react-bootstrap-icons';
import '../estilos/AddNegocio.css';

function DelTipoEpp (props) {

    //console.log(props);
    
    const [msgError, setMsgError] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    let glosa_eliminar = 'Num Identificador: ' + props.celdas.num + ' Marca: ' + props.celdas.marca;
    let nombre_epp = '';
    let tabla_tipo_epp = '';
    if (props.nombre_tabla.indexOf("/") >= 0) {
        var k = props.nombre_tabla.split("/")
        nombre_epp = k[2];
        tabla_tipo_epp = k[3];
      };
      //console.log('nombre_epp', nombre_epp);
      //console.log('tabla_tipo_epp', tabla_tipo_epp);
      function handCloseCancel () {
        setShow(false);
      }
      function handCloseGraba () {
        //setShow(false);
        //console.log('funcion guardaForm ' + JSON.stringify(formCliente))
        let hayError = false;
        axios.request({
            url: "/api/eliminaparam",
            method: "POST",
            data: {
                "tipo_tabla": tabla_tipo_epp,
                "id_tabla": props.celdas.num,
                }
            }).then((response) => {
              //setValorTec({"valor": response.data.datos[0].tecnico})
              //setDetalleEnsayo(response.data.datos);
            }).catch((error) => {
              if (error.response) {
                hayError=true;
                setMsgError(error.response.msg)
                //console.log(error.response)
                //console.log(error.response.status)
                //console.log(error.response.headers)
                }
            });
            if (!hayError) {
                //console.log('no hubo error', props.nombre_tabla)
                const aleatorio = Math.floor(Math.random()*1000).toString()
                setShow(false); //cierra la ventana popup
                props.setRuta('/gesepprefresca'.concat('_',aleatorio,'/',props.nombre_tabla));
            
            } else {
                //console.log('hubo error')
            }
        //window.location.reload(false);
      }
    return (<>
    <button class="btn btn-outline-primary" size="sm" onClick={handleShow}><Trash /></button>  
        <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title><h3>Confirma Eliminar Tipo {nombre_epp} </h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form><Container>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">{glosa_eliminar} </span></Form.Label>
                  </Form.Group>
              </Col>
            </Row>
            </Container></Form>
            <p><span className = "mensaje-error">{msgError?msgError:null}</span></p>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handCloseCancel}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handCloseGraba}>
              Eliminar
            </Button>
        </Modal.Footer>
      </Modal>
    
    </>)
}

export default DelTipoEpp;