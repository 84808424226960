import React from "react"
import {useState} from 'react';
import {Button, Modal, Form, Dropdown, ButtonGroup, Container, Row, Col, Badge} from 'react-bootstrap';
import axios from 'axios';
import {PencilSquare} from 'react-bootstrap-icons';
import SelectParam from "./selectores/SelectParam";
import SelectParamTest from "./selectores/SelectParamTest";
import '../estilos/AddNegocio.css';

function EditEppGuante (props) {
  //console.log('Tipo_epp en EditEppGuante', props.tipo_epp)
  const rutaCompleta = props.nombre_tabla;
  const parametroSelect = props.nombre_tabla.substr(props.nombre_tabla.lastIndexOf("/")+1);
  let celdas_num_serie;
  if (props.celdas.num_serie.indexOf("=>")>1){
    //console.log("hay")
    celdas_num_serie = props.celdas.num_serie.substring(0, props.celdas.num_serie.indexOf("=>"))
  }else {
    //console.log("no hay")
    celdas_num_serie = props.celdas.num_serie;
  }
  const [show, setShow] = useState(false);
  const [codigoEpp, setCodigoEpp] = useState('');
  const [formGuante, setFormGuante] = useState({
    'cod_epp': '',
    'tipo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'estado': '0',
    'periodicidad': '6',
    'estado_uso': '2',
    'serie_fabrica': '000',
    'tipo_epp': props.tipo_epp,
    'epp_informe': '',
    'serie_elevador': '',
    'patente': '',
    'modelo': '',
    'year': '',
  });
  const [errorForm, setErrorForm] = useState({
    'cod_epp': '',
    'tipo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'periodicidad': '',
    'serie_fabrica': '',
    'estado_uso': '',
    'serie_elevador': '',
    'patente': '',
    'modelo': '',
    'year': '',
  });

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  function handleClose () {
    setFormGuante({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'estado': '0',
      'periodicidad': '6',
      'estado_uso': '2',
      'serie_fabrica': '000',
      'tipo_epp': props.tipo_epp,
      'epp_informe': '',
      'serie_elevador': '',
      'patente': '',
      'modelo': '',
      'year': '',
    });
    setErrorForm({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'periodicidad': '',
      'serie_fabrica': '',
      'estado_uso': '',
      'serie_elevador': '',
      'patente': '',
      'modelo': '',
      'year': '',
    });
    setShow(false);
  }





  async function handleShow () {
    
    await axios({
      method: "POST",
      url:"/api/formepps",
      data: {"id_epp": props.celdas.id}
    })
    .then((response) => {
      //console.log('axios: ', response.data.datos);
      //setCodigoEpp(response.data.datos);
      //setFormGuante(prevNote => ({...prevNote, ['cod_epp']: response.data.datos}))
      setFormGuante({
        'cod_epp': celdas_num_serie,
        'tipo': response.data.datos[0].tipo_epp,
        'cliente': response.data.datos[0].cliente,
        'sucursal': response.data.datos[0].sucursal,
        'negocio': response.data.datos[0].negocio,
        'estado': response.data.datos[0].estado_epp,
        'periodicidad': response.data.datos[0].periodicidad,
        'estado_uso': response.data.datos[0].estado_uso,
        'serie_fabrica': response.data.datos[0].serie_fabrica,
        'tipo_epp': props.tipo_epp,
        'epp_informe': response.data.datos[0].epp_informe,
        'serie_elevador': response.data.datos[0].serie_elevador,
        'patente': response.data.datos[0].patente,
        'modelo': response.data.datos[0].modelo,
        'year': response.data.datos[0].year,
        });
      setErrorForm({
        'cod_epp': '',
        'tipo': '',
        'cliente': '',
        'sucursal': '',
        'negocio': '',
        'periodicidad': '',
        'serie_fabrica': '',
        'estado_uso': '',
        'serie_elevador': '',
        'patente': '',
        'modelo': '',
        'year': '',
      })
      //console.log('respuesta: ',response.data.datos);
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });
    
    setShow(true)
  }



//********************************************************************* */  
  function cambioEnForm (event) {
    //event.preventDefault();
    const {value, name} = event.target;
    //console.log(name, value);
    
    setErrorForm({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'periodicidad': '',
      'serie_fabrica': '',
      'estado_uso': '',
    });
    //console.log('form: ', formGuante);
    setFormGuante(prevNote => ({...prevNote, [name]: value}))
    
    //console.log('cambio en form')
  }

//********************************************************************* */  
  async function handCloseGraba () {
    //console.log('formato 1: ',formGuante)
    setErrorForm({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'periodicidad': '',
      'serie_fabrica': '',
      'estado_uso': '',
    });
    let hayError = false;
    if (!formGuante.cod_epp) {
      //console.log('valor epp: ', formGuante.cod_epp);
      setErrorForm(prevNote => ({...prevNote, ["cod_epp"]: "Código de epp no definido"}))
      hayError = true;
    }
    if (!formGuante.tipo) {
      setErrorForm(prevNote => ({...prevNote, ["tipo"]: "Seleccione Tipo"}))
      hayError = true;
    }
    if (formGuante.cliente!==0) {
      if (!formGuante.cliente){
        //console.log('valor: ', formGuante.cliente);
        setErrorForm(prevNote => ({...prevNote, ["cliente"]: "Seleccione Ciente"}))
        hayError = true;
      }
    }
    if (!formGuante.sucursal) {
      setErrorForm(prevNote => ({...prevNote, ["sucursal"]: "Seleccione Sucursal"}))
      hayError = true;
    }
    if (!formGuante.negocio) {
      setErrorForm(prevNote => ({...prevNote, ["negocio"]: "Seleccione Negocio"}))
      hayError = true;
    }
    if (!formGuante.periodicidad) {
      setErrorForm(prevNote => ({...prevNote, ["periodicidad"]: "Seleccione Periodicidad"}))
      hayError = true;
    }
    if (!formGuante.estado_uso) {
      setErrorForm(prevNote => ({...prevNote, ["estado_uso"]: "Seleccione Estado Uso"}))
      hayError = true;
    }
    //console.log('funcion handCloseGraba', hayError)
    if (!hayError) {
      //axios
      //console.log('form guante: ', JSON.stringify(formGuante));
      await axios({
        method: "POST",
        url:"/api/grabaepp",
        data: formGuante
      })
      .then((response) => {
        //console.log('axios: ', response.data.msg);
      }).catch((error) => {
        if (error.response) {
          setErrorForm(prevNote => ({...prevNote, ["cliente"]: error.response.data.msg}))
          hayError = true;
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      //window.location.reload(false);
      if (!hayError) {
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        //console.log('Concatenacion : ', '/gueppRefresca'.concat('_',aleatorio,'/',rutaCompleta))
        props.setRuta('/gueppRefresca'.concat('_',aleatorio,'/',rutaCompleta));
      }
    }
  }
  return (
    <>
      <button class="btn btn-outline-primary" size="sm" onClick={handleShow}><PencilSquare /></button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><h3>{"Editar "}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form>
            <h4>
             <Badge bg="secondary">{celdas_num_serie}</Badge>
            </h4>
        <Container>
            <Row>
                <Col xs={12} md={8}>
                    <Form.Group className="mb-3" controlId="tipo">
                    <Form.Label><span className="campo">Seleccione Tipo</span></Form.Label>
                    <SelectParamTest 
                      key="tip"
                      nombre="tipo"
                      parametro={parametroSelect}
                      valorDefault={formGuante.tipo}
                      cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.tipo}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="Epp-informe">
                      <Form.Label><span className="campo">Opcional: Epp para informe pdf</span></Form.Label>
                      <Form.Control
                        name="epp_informe"
                        type="text"
                        defaultValue={formGuante.epp_informe}
                        placeholder=""
                        onChange={cambioEnForm}
                        />
                      </Form.Group>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="cli-1">
                    <Form.Label><span className="campo">Cliente</span></Form.Label>
                        <SelectParamTest key="cli" 
                        parametro="cliente" 
                        valorDefault={formGuante.cliente} 
                        cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.cliente}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="suc-1">
                    <Form.Label><span className="campo">Sucursal</span></Form.Label>
                      <SelectParamTest key="suc" 
                      parametro="sucursal" 
                      valorDefault={formGuante.sucursal} 
                      cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.sucursal}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="neg-1">
                    <Form.Label><span className="campo">Negocio</span></Form.Label>
                    <SelectParamTest 
                    key="neg" 
                    parametro="negocio" 
                    valorDefault={formGuante.negocio} 
                    cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.negocio}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="per-1">
                    <Form.Label><span className="campo">Periodicidad</span></Form.Label>
                      <SelectParamTest key="per" parametro="periodicidad" valorDefault={formGuante.periodicidad} cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.periodicidad}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="est-1">
                    <Form.Label><span className="campo">Estado Uso</span></Form.Label>
                      <SelectParamTest key="est" parametro="estado_uso" valorDefault={formGuante.estado_uso} cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.estado_uso}</span></p>
                    </Form.Group>
                </Col>
                {props.tipo_epp=='LDB'?
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="ser-1">
                <Form.Label><span className="campo">Serie de Fábrica</span></Form.Label>
                  <Form.Control
                    value={formGuante.serie_fabrica}
                    name="serie_fabrica"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.serie_fabrica}</span></p>
                </Form.Group>
                </Col>:null}
                {props.tipo_epp=='CAM'?
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label><span className="campo">Serie Elevador</span></Form.Label>
                  <Form.Control
                    value={formGuante.serie_elevador}
                    name="serie_elevador"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.serie_elevador}</span></p>
                </Form.Group>
                </Col>:null}
            </Row>
            {props.tipo_epp==='CAM'?
            <Row>
              <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Label><span className="campo">Modelo</span></Form.Label>
                  <Form.Control
                    value={formGuante.modelo}
                    name="modelo"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.modelo}</span></p>
                </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                <Form.Label><span className="campo">Año</span></Form.Label>
                  <Form.Control
                    value={formGuante.year}
                    name="year"
                    type="text"
                    placeholder="Año"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.year}</span></p>
                </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                <Form.Label><span className="campo">Patente</span></Form.Label>
                  <Form.Control
                    value={formGuante.patente}
                    name="patente"
                    type="text"
                    placeholder="Patente"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.patente}</span></p>
                </Form.Group>
                </Col>
            </Row>:null}
        </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default EditEppGuante;