import React from "react"
import {useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import '../estilos/AddNegocio.css';

function AddNegocio () {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        +Agregar Cliente
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Agregar Cliente</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Cliente</span></Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Sucursal</span></Form.Label>
              <Form.Select aria-label="Default select example">
                <option placeholder="Sucursal" ></option>
                <option value="1">Arica</option>
                <option value="2">Iquique</option>
                <option value="3">Coquimbo</option>
                <option value="3">Hualañé</option>
                <option value="3">Rancagua</option>
                <option value="3">Osorno</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Negocio</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddNegocio;