import React from "react";

function SelectUnidadResistencia (props) {

    const datos = [
        {id: 'μΩ', nombre: 'μΩ'},
        {id: 'mΩ', nombre: 'mΩ'},
        {id: 'Ω', nombre: 'Ω'},
        {id: 'kΩ', nombre: 'kΩ'}
        
    ]

    return (
        <>
            <option key="0" value="">Seleccione {props.parametro}</option>
            {datos.map((dato, idx) => {return <option key={dato.id} value={dato.id}>{dato.nombre}</option>})}
        </>
    )
}

export default SelectUnidadResistencia;