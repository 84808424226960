import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Dropdown, ButtonGroup, Container, Row, Col, Badge, InputGroup, Card} from 'react-bootstrap';
import {ListCheck, PlusSquare} from 'react-bootstrap-icons';
import ListadoDetalleJmp from "../ListadoDetalleJmp";
import ListadoDetalleIngreso from "../ListadoDetalleIngreso";
import ListadoDetalleIngresoTpe from "../ListaDetalleIngresoTpe";
import ListadoDetalleIngresoCam from "../ListaDetalleIngresoCam";
import SelectParam from "../selectores/SelectParam";
import SelectUnidadResistencia from "../selectores/SelectUnidadResistencia";
import ListadoDetalleTpe from "../ListadoDetalleTpe";


function EditarDetalleEnsayo (props) {

    //console.log('props.data.codigo', props.data.codigo)
    //console.log('props.data.detalle', props.data.detalle)

    const rutaCompleta = props.nombre_tabla;
    const dataDetalle = props.data;
    const [show, setShow] = useState(false);
    const [detalleEnsayo, setDetalleEnsayo] = useState([]);
    const [detalleEnsayoJmp, setDetalleEnsayoJmp] = useState([]);
    const [listaDetalleCompleta, setListaDetalleCompleta] = useState(false);
    const [listaDetalleCompletaJmp, setListaDetalleCompletaJmp] = useState(false);
    const [apruebaVisual, setApruebaVisual] = useState(false);
    const [showDetalle, setShowDetalle] = useState(false);
    const [deshabilitaBotonGrabar, setDeshabilitaBotonGrabar] = useState(props.data.detalle?false:true);
    const [validacionEpp, setValidacionEpp] = useState({"error":false, "msg":""});
    const [serieFormEpp, setSerieFormEpp] = useState('');
    const [serieLDB, setSerieLDB] = useState(null);
    const [serieCAM, setSerieCAM] = useState(props.data.detalle?props.data.detalle.serie_epp:null);
    const [validaATA, setValidaATA] = useState({"error":false, "msg":""});
    //**************************************************************************** */
  const [formCliente, setFormCliente] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'tipo_ensayo': props.nombre_tabla.split("/")[2],
    'orden_compra': '',
    'dir_ensayo': 'Leonidas Vial 1109, San Miguel, Santiago.',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'estado': '2'
  });
  const [formDetalleLoadB, setDetalleLoadB] = useState(
        {"serie_epp": '',
        "parches": '',
        "fuga1": '',
        "serie_fabrica": '',
        "patron1": '',
        "patron2": '',
        "patron3": '',
        "tension1": '',
        "tension1": '',
        "tension1": '',
        "medida1": '',
        "medida2": '',
        "AR1": 'R',
        "AR2": 'R',
        "AR3": 'R',
        "ensayoresul": 'RECHAZADO',
        "carcaz":'R',
        "gancho":'R',
        "cancla":'R',
        "contop":'R',
        "apertu":'R',
        "anillo":'R',
        "extiro":'R',
        "citiro":'R',
        "seguro":'R',
        "cubier":'R',
        "contad":'R',
        "insresultado": 'RECHAZADO',
        "resultado": 'RECHAZADO'}
    );
//******************************************************************************************* */
  const [formDetalleCam, setDetalleCam] = useState(props.data.detalle?
    {
    "serie_epp": props.data.detalle.serie_epp,
    "parches":  props.data.detalle.parches,
    "fuga1": props.data.detalle.fuga1,
    "serie_fabrica": props.data.detalle.serie_fabrica,
    "patron1": props.data.detalle.patron1.num,
    "patron2": props.data.detalle.patron2.num,
    "plsup_cat": props.data.detalle.plinf_cat,
    "plsup_ais": props.data.detalle.plinf_ais,
    "plsup_tension1": props.data.detalle.plinf_tension1,
    "plsup_tension2": props.data.detalle.plinf_tension2,
    "plsup_tension3": props.data.detalle.plinf_tension3,
    "plsup_tiempo1": props.data.detalle.plinf_tiempo1,
    "plsup_tiempo2": props.data.detalle.plinf_tiempo2,
    "plsup_tiempo3": props.data.detalle.plinf_tiempo3,
    "plsup_fuga1": props.data.detalle.plinf_fuga1,
    "plsup_fuga2": props.data.detalle.plinf_fuga2,
    "plsup_fuga3":  props.data.detalle.plinf_fuga3,
    "plsup_imax1": props.data.detalle.plinf_imax1,
    "plsup_imax2": props.data.detalle.plinf_imax2,
    "plsup_imax3": props.data.detalle.plinf_imax3,
    "plsup_ar1": props.data.detalle.plinf_ar1,
    "plsup_ar2": props.data.detalle.plinf_ar2,
    "plsup_ar3": props.data.detalle.plinf_ar3,
    "plinf_cat": props.data.detalle.plinf_cat,
    "plinf_ais": props.data.detalle.plinf_ais,
    "plinf_tension1": props.data.detalle.plinf_tension1,
    "plinf_tiempo1": props.data.detalle.plinf_tiempo1,
    "plinf_fuga1": props.data.detalle.plinf_fuga1,
    "plinf_imax1": props.data.detalle.plinf_imax1,
    "pluma_superior": props.data.detalle.pluma_superior,
    "pluma_inferior": props.data.detalle.pluma_inferior,
    "resultado": props.data.detalle.resultado
    }:{"serie_epp": '',
    "parches": '',
    "fuga1": '',
    "serie_fabrica": '',
    "patron1": '',
    "patron2": '',
    "plsup_cat": '',
    "plsup_ais": '',
    "plsup_tension1": '',
    "plsup_tension2": '',
    "plsup_tension3": '',
    "plsup_tiempo1": '',
    "plsup_tiempo2": '',
    "plsup_tiempo3": '',
    "plsup_fuga1": '',
    "plsup_fuga2": '',
    "plsup_fuga3": '',
    "plsup_imax1": '',
    "plsup_imax2": '',
    "plsup_imax3": '',
    "plsup_ar1": '',
    "plsup_ar2": '',
    "plsup_ar3": '',
    "plinf_cat": '',
    "plinf_ais": '',
    "plinf_tension1": '',
    "plinf_tiempo1": '',
    "plinf_fuga1": '',
    "plinf_imax1": '',
    "pluma_superior": 'RECHAZADO',
    "pluma_inferior": 'RECHAZADO',
    "resultado": 'RECHAZADO'}
  )
    //**************************************************************************** */
      const [formClienteDetalle, setFormClienteDetalle] = useState({
        'serie': '',
        'parches': '',
        'tension': '',
        'fuga1': '',
        'resultado': 'RECHAZADO'
      });
    //**************************************************************************** */
      const [erroformClienteDetalle, setErrorformClienteDetalle] = useState({
        'serie': '',
        'parches': '',
        'tension': '',
        'fuga1': '',
      });
    //**************************************************************************** */
      const [formClienteDetalleJmp, setFormClienteDetalleJmp] = useState({
        'serie':'',
        'fuga1':'',
        'tension':'',
        'visual': false,
        'dieresul': '',
        'tramo': '',
        'seccion':'',
        'longitud':'',
        'resismedida':'',
        'resismax':'',
        'resisresul':false
      });
      //**************************************************************************** */
      const [formClienteDetalleAta, setFormClienteDetalleAta] = useState({
        'serie_epp':'',
        'fuga1':'',
        'seccion':'',
        'resistencia_AB':'',
        'resistencia_BC':'',
        'resistencia_CD':'',
        'resistencia_DE':'',
        't_cuerpoA':'',
        't_cuerpoB':'',
        't_cuerpoC':'',
        't_cuerpoD':'',
        't_cuerpoE':'',
        'i_cuerpoA':'',
        'i_cuerpoB':'',
        'i_cuerpoC':'',
        'i_cuerpoD':'',
        'i_cuerpoE':'',
        'visual': false,
      });
    //**************************************************************************** */
      const [errorformClienteDetalleJmp, setErrorformClienteDetalleJmp] = useState();

    //**************************************************************************** */
//**************************************************************************** */
function cambiaVisual (event) {
    const {checked, name} = event.target;
    setApruebaVisual(checked);
  }

//**************************************************************************** */
function borraElementoDetalleJmp (elemento) {
    let newData = detalleEnsayoJmp.filter(e => e.serie !== elemento);
    setDetalleEnsayoJmp(newData);
  }

function cambioEnFormDetalle (event) {
    const {value, name} = event.target;
    setFormClienteDetalle(prevNote => ({...prevNote, [name]: value}))
    }

function cambioEnFormDetalleJmp (event) {
  const {value, name} = event.target;
  setFormClienteDetalleJmp(prevNote => ({...prevNote, [name]: value}))
}

//**************************************************************************** */
function MostrarListado (props){
    //if (props.cod_epp == 'LDB'){
    //  return <FormDetalleLoad setDetalleLoadB={setDetalleLoadB} cod_epp={props.cod_epp} setDeshabilitaBotonGrabar={setDeshabilitaBotonGrabar} deshabilitaBotonGrabar={deshabilitaBotonGrabar} />;
    //}else 
    if (props.cod_epp == 'JMP'){
      return <ListadoDetalleJmp datos={detalleEnsayoJmp} borraElementoDetalle={borraElementoDetalleJmp} setListaDetalleCompleta={setListaDetalleCompletaJmp} />
    }else if(props.cod_epp === 'ATA' || props.cod_epp === 'TPE' || props.cod_epp === 'CAM'){
      return null;
    }else {
      return <ListadoDetalleIngreso datos={detalleEnsayo} 
                            borraElementoDetalle={borraElementoDetalle} 
                            setListaDetalleCompleta={setListaDetalleCompleta} 
                            cod_epp={props.cod_epp}
                            detalleEnsayo={detalleEnsayo} 
                            setDetalleEnsayo={setDetalleEnsayo}
                            formCliente={formCliente}
                            />;
      /* return <ListadoDetalle datos={detalleEnsayo} borraElementoDetalle={borraElementoDetalle} setListaDetalleCompleta={setListaDetalleCompleta} />; */
    }
  }
//  ########################### Funciones para LDB ######################////
function cambiaCheckResult (event) {
    const {checked, name} = event.target;
    if (checked){
        setDetalleLoadB(prevNote => ({...prevNote, [name]: 'APROBADO'}));
    }else{
        setDetalleLoadB(prevNote => ({...prevNote, [name]: 'RECHAZADO'}));
    }
  }
//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
function cambiaCheck (event) {
    const {checked, name} = event.target;
    if (checked){
        setDetalleLoadB(prevNote => ({...prevNote, [name]: 'A'}));
        if (name == 'AR1'){
          if (formDetalleLoadB.AR2 == 'A' && formDetalleLoadB.AR3 == 'A'){
            setDetalleLoadB(prevNote => ({...prevNote, ['ensayoresul']: 'APROBADO'}));
          }
        }else if (name == 'AR2'){
          if (formDetalleLoadB.AR1 == 'A' && formDetalleLoadB.AR3 == 'A'){
            setDetalleLoadB(prevNote => ({...prevNote, ['ensayoresul']: 'APROBADO'}));
          }
        }else if (name == 'AR3'){
          if (formDetalleLoadB.AR2 == 'A' && formDetalleLoadB.AR1 == 'A'){
            setDetalleLoadB(prevNote => ({...prevNote, ['ensayoresul']: 'APROBADO'}));
          }
        }
        let datosVisuales = ['carcaz', 'gancho', 'cancla', 'contop', 'apertu', 'anillo', 'extiro', 'citiro', 'seguro', 'cubier'];
        //let f = {'uno':'A', 'dos':'A', 'tres':'A', 'cuatro':'A', 'cinco':'R'}
        //let nombre = 'dos';
        let otro = datosVisuales.filter(function(n){return n !== name && formDetalleLoadB[n] !== 'A'});
        console.log(otro.length);
        if (otro.length == 0){
          //no hay nimguno rechazado
          console.log('visual = aprobado', name, checked);
          setDetalleLoadB(prevNote => ({...prevNote, ['insresultado']: 'APROBADO'}));
        }
    }else{
        setDetalleLoadB(prevNote => ({...prevNote, [name]: 'R'}));
        if (name == 'AR1' || name == 'AR2' || name == 'AR3'){
          setDetalleLoadB(prevNote => ({...prevNote, ['ensayoresul']: 'RECHAZADO'}));
        }else{
          setDetalleLoadB(prevNote => ({...prevNote, ['insresultado']: 'RECHAZADO'}));
        }
    }
    
  } 

//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
async function validarEpp (){
    //if (props.formDetalleLoadB.serie.length > 5) {
        console.log('serie  ', serieFormEpp)
    if (serieFormEpp.length > 5) {
        setValidacionEpp({
            error: true,
            msg: 'El valor debe tener máximo 5 cifras'
        })
    }else {
        //let serie_epp = props.formDetalleLoadB.serie.padStart(5, '0')
        let serie_epp = serieFormEpp.padStart(5, '0');
        //setSerieFormEpp(serie_epp)
        
        serie_epp = props.cod_epp.concat('-', serie_epp);
        let datos_consulta = {
            "serie_epp": serie_epp,
            "cliente": formCliente.cliente,
            "negocio": formCliente.negocio,
            "sucursal": formCliente.sucursal,
        }

        await axios({
            method: "POST",
            url:"/api/chequea_epp",
            data: datos_consulta
          })
          .then((response) => {
  
            let mensajeBueno = ''
            if (props.cod_epp === 'LDB'){
              mensajeBueno = "Serie Epp: ".concat(serie_epp,' Serie de Fábrica: ',response.data.datos);
            }else {
              mensajeBueno = "Serie Epp: ".concat(serie_epp);
            }
            setValidacionEpp({
                error: false,
                msg: mensajeBueno
            })
            setDetalleLoadB(prevNote => ({...prevNote, ['serie_fabrica']: response.data.datos, ['serie_epp']: serie_epp}));
            setFormClienteDetalleAta(prevNote => ({...prevNote, ['serie_epp']: serie_epp}));
            setDetalleCam(prevNote => ({...prevNote, ['serie_epp']: serie_epp}));
  
            //setMensajeOK('mensaje ok');
            //setValorTec({"valor": response.data.datos[0].tecnico})
            
            
            setDeshabilitaBotonGrabar(false);
            //setDetalleLoadB(prevNote => ({...prevNote, ['serie_fabrica']: response.data.datos, ['serie_epp']: serie_epp}));
            
            //setDetalleLoadB(prevNote => ({...prevNote, ['serie_epp']: serie_epp}));
            
            //setMensajeOK('mensaje ok');
            
            
            
            
  
            //setSerieFormEpp('');
          }).catch((error) => {
            if (error.response) {
              if (error.response.data.msg) {
                //setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
                
                setDeshabilitaBotonGrabar(true);
                setValidacionEpp({
                    error: true,
                    msg: error.response.data.msg
                })
              } else if (error.response.data.datos) {
                //setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
               
                setDeshabilitaBotonGrabar(true);
                setValidacionEpp({
                    error: true,
                    msg: error.response.data.datos
                })
              }
              //console.log(error.response)
              //console.log(error.response.status)
              //console.log(error.response.headers)
              }
          });
        
    }
    
  }
//************************************************************************** */
function cambiaCheckCam (event) {
  const {checked, name} = event.target;
    if (checked){
      setDetalleCam(prevNote => ({...prevNote, [name]: 'A'}));
    }else{
      setDetalleCam(prevNote => ({...prevNote, [name]: 'R'}));
    }

}

//**************************************************************************** */
async function agregaDetalle () {
    //chequear los datos
    //primero limpiar los errores los errores 
    
    setErrorformClienteDetalle({
      'serie': '',
      'parches': '',
      'tension': '',
      'fuga1': '',
      'resultado': 'RECHAZADO'
    });
    let formParaLista = formClienteDetalle;
    let hayError = false;
    if (apruebaVisual) {
      
      if (!formClienteDetalle.serie) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalle.serie <= 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalle.serie.padStart(5, '0')
          if (a.length > 5) {
            setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
              
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      }
      if (!formClienteDetalle.parches) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["parches"]: "Ingrese cantidad de parches (0 si no tiene)"}))
        hayError = true;
      } else {
        if (formClienteDetalle.parches < 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["parches"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
      if (!formClienteDetalle.tension) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["tension"]: "Ingrese tensión de ensayo"}))
        hayError = true;
      } else {
        if (formClienteDetalle.tension < 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["tension"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
   // aquí va el chequeo corrientes
    } else {
      
      if (!formClienteDetalle.serie) {
        setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalle.serie <= 0) {
          setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalle.serie.padStart(5, '0')
          if (a.length > 5) {
            setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
            
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      } 
      if (!hayError) {
       
        formParaLista['parches'] = '--';
        formParaLista['tension'] = '--';
        formParaLista['fuga1'] = '--';
        formParaLista['resultado'] = 'RECHAZADO';
      }
    }

      if (!hayError) {
        let fuga_max = 5; //default
        await axios({
          method: "POST",
          url:"/api/chequea_epp",
          data: {
            "serie_epp": formParaLista['serie'],
            "cliente": formCliente.cliente,
            "negocio": formCliente.negocio,
            "sucursal": formCliente.sucursal,
          }
        })
        .then((response) => {
          //setValorTec({"valor": response.data.datos[0].tecnico})
         
          if (isNaN(response.data.datos)) {
          
            setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: response.data.datos}))
            hayError = true;
            //console.log('error en guante: ')
          } else {
              //console.log('Es numero')
              fuga_max = response.data.datos
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
            } else if (error.response.data.datos) {
              setErrorformClienteDetalle(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
            }
            hayError = true;
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });

        //console.log('valor de hayerror: ', hayError)
        if (!hayError) {
          if (apruebaVisual) {

            formParaLista['resultado'] = formClienteDetalle['resultado']

          }
          const datosNew = [formParaLista, ...detalleEnsayo]
          setDetalleEnsayo(datosNew);
          //setShowDetalle(false);
          setFormClienteDetalle({})
        } 
      }
  }
//**************************************************************************** */
function borraElementoDetalle (elemento) {
    let newData = detalleEnsayo.filter(e => e.serie !== elemento);
    //console.log(newData);
    setDetalleEnsayo(newData);
  }

//**************************************************************************** */
async function agregaDetalleJmp (){
    //console.log('primero', formClienteDetalleJmp);
    let formParaLista = formClienteDetalleJmp;
    let hay = false;
    for (const [key, value] of Object.entries(formClienteDetalleJmp)) {
      //console.log(`${key}: ${value}`);
        if (key!=='dieresul' && key!=='resisresul' && key!=='visual'){
          if (!value) {
            hay = true;
            let nombreVariable = key;
            if (key=='fuga1') {
              nombreVariable='Corriente de Fuga';
            }else if (key=='tension') {
              nombreVariable='Tension de ensayo';
            }else if (key=='seccion') {
              nombreVariable='Seccion conductor';
            }else if (key=='resismedida') {
              nombreVariable='Resistencia Medida';
            }else if (key=='resismax') {
              nombreVariable='Resistencia Máxima';
            }
            setErrorformClienteDetalleJmp(''.concat('Complete el valor de: ',nombreVariable));
          }else{
            if (key == 'serie'){
              if (value<=0 || value>99999){
                hay = true;
                setErrorformClienteDetalleJmp('El valor de la serie Epp debe ser de máximo 5 cifras y mayor a 0');
              }else {
                //chequear epp en la base
                formParaLista['serie_epp'] = props.cod_epp.concat('-', value.padStart(5, 0));
                    await axios({
                      method: "POST",
                      url:"/api/chequea_epp",
                      data: {
                        "serie_epp": formParaLista['serie_epp'],
                        "cliente": formCliente.cliente,
                        "negocio": formCliente.negocio,
                        "sucursal": formCliente.sucursal,
                      }
                    })
                    .then((response) => {
                      //setValorTec({"valor": response.data.datos[0].tecnico})
                      // todo ok
                    }).catch((error) => {
                      if (error.response) {
                        if (error.response.data.msg) {
                          setErrorformClienteDetalleJmp(error.response.data.msg)
                        } else if (error.response.data.datos) {
                          setErrorformClienteDetalleJmp(error.response.data.datos)
                        }
                        hay = true;
                        //console.log(error.response)
                        //console.log(error.response.status)
                        //console.log(error.response.headers)
                        }
                    });
              }
            }
          }
        }else if (key == 'visual'){
          if (value=='on'){
            formParaLista['visual'] = 'APROBADO';
          }else{
            formParaLista['visual'] = 'RECHAZADO';
          }
        }else if (key == 'dieresul'){
          if (value=='on'){
            formParaLista['dieresul'] = 'APROBADO';
          }else{
            formParaLista['dieresul'] = 'RECHAZADO';
          }
        }else if (key == 'resisresul'){
          if (value=='on'){
            formParaLista['resisresul'] = 'APROBADO';
          }else{
            formParaLista['resisresul'] = 'RECHAZADO';
          }
        }
    }
    if (!hay){
      const datosNew = [formParaLista, ...detalleEnsayoJmp]
      //console.log('segundo', datosNew);
      setDetalleEnsayoJmp(datosNew);
      setShowDetalle(false);
    }
  }
//**************************************************************************** */
//  ########################### Funciones para LDB ######################////
function cambioEnEppLoad (event) {
    const {value, name} = event.target;
    setSerieFormEpp(value);
}
//**************************************************************************** */
//**************************************************************************** */
//  ########################### Funciones para LDB ######################////

function cambioDetalleLoad (event) {
    const {value, name} = event.target;
    if (name=='tension1'){
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (kV)').replace('.',','));
        //setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (kV)').replace('.',',')}));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }else if (name=='tension2') {
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        //setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (kV)').replace('.',',')}));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }else if (name=='tension3') {
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        //setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (V)').replace('.',',')}));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }else if (name=='medida1') {
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        //setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (Ω)').replace('.',',')}));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }else if (name=='medida2') {
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        //setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (Ω)').replace('.',',')}));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }else if (name=='fuga1') {
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        //setDetalleLoadB(prevNote => ({...prevNote, [name]: value.toLocaleString(undefined, { minimumFractionDigits: 2 }).concat(' (mA)').replace('.',',')}));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }else if (name=='contad') {
        //console.log('nombre: ', name, 'valor: ', value.toLocaleString(undefined, { minimumFractionDigits: 2 }));
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value.padStart(4, '0')}));
    }else {
        setDetalleLoadB(prevNote => ({...prevNote, [name]: value}));
    }
    //console.log('nombre: ', name, 'valor: ', value);
    
  
  }
//**************************************************************************** */
//  ########################### Funciones para Camion ######################////
function cambioEnEppCam (event) {
  const {value, name} = event.target;
  setSerieFormEpp(value);
}

function cambioDetalleCam (event) {
  const {value, name} = event.target;
  setDetalleCam(prevNote => ({...prevNote, [name]: value}));
}
//***************************************************************************** */
function cambioDetalleAta (event) {
    const {value, name} = event.target;
    setFormClienteDetalleAta(prevNote => ({...prevNote, [name]: value}));
    setValidaATA({"error":false, "msg":""});
}
//**************************************************************************** */
function handleClose () {
    const aleatorio = Math.floor(Math.random()*1000).toString()
    setShow(false); //cierra la ventana popup
    props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
}

async function borraLdb () {
    const datoLdb = {'cod_epp': serieLDB}
    await axios({
      method: "POST",
      url:"/api/eliminadetalleldb",
      data: datoLdb
    })
    .then((response) => {
      //console.log('axios grabadetensayo: ', response.data.msg);
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });
    const aleatorio = Math.floor(Math.random()*1000).toString()
    setShow(false); //cierra la ventana popup
    props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
}

//////////////////////////////////////////////////////////////////////
async function borraCam () {
  const datoCam = {'cod_epp': serieCAM}
  await axios({
    method: "POST",
    url:"/api/eliminadetallecam",
    data: datoCam
  })
  .then((response) => {
    //console.log('axios grabadetensayo: ', response.data.msg);
  }).catch((error) => {
    if (error.response) {
      //console.log(error.response)
      //console.log(error.response.status)
      //console.log(error.response.headers)
      }
  });
  const aleatorio = Math.floor(Math.random()*1000).toString()
  setShow(false); //cierra la ventana popup
  props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
}

async function handCloseGraba () {
    //setShow(false);
    if (props.cod_epp === 'LDB') {
        //console.log('Graba ldb', formDetalleLoadB);
        let copiaFormDetalle = formDetalleLoadB;
        if (copiaFormDetalle.ensayoresul == 'APROBADO' && copiaFormDetalle.insresultado == 'APROBADO'){
          copiaFormDetalle['resultado'] = 'APROBADO';
        }else {
          copiaFormDetalle['resultado'] = 'RECHAZADO';
        }
        //const salidaDet = {"detalle": [formDetalleLoadB]}
        const salidaDet = {"detalle": [copiaFormDetalle]}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };
        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });
    }else if (props.cod_epp === 'JMP') {
        //console.log('Graba JMP', detalleEnsayoJmp);
        const salidaDet = {"detalle": detalleEnsayoJmp}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };
        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });
    }else if (props.cod_epp === 'ATA') {
        console.log('Graba ATA', formClienteDetalleAta);
        console.log('Validacion ATA', validacionEpp);
        if (!(formClienteDetalleAta['patron1'] && formClienteDetalleAta['patron2'])) {
            console.log('No hay patron, debe seleccionar los patrones');
            setValidaATA({"error":true, "msg":"Debe seleccionar los patrones"});
            return
        }
        if (!(formClienteDetalleAta['resistencia_AB'] || formClienteDetalleAta['resistencia_BC'] || formClienteDetalleAta['resistencia_CD'] || formClienteDetalleAta['resistencia_DE'])) {
            console.log('Debe ingresar al menos un valor de resistencia');
            setValidaATA({"error":true, "msg":"Debe ingresar al menos un valor de resistencia"});
            return
        }
        if (!(formClienteDetalleAta['i_cuerpoA'] || formClienteDetalleAta['i_cuerpoB'] || formClienteDetalleAta['i_cuerpoC'] || formClienteDetalleAta['i_cuerpoD'] )) {
            console.log('Debe ingresar al menos un valor de corriente');
            setValidaATA({"error":true, "msg":"Debe ingresar al menos un valor de corriente"});
            return

        }
        if (!(formClienteDetalleAta['t_cuerpoA'] || formClienteDetalleAta['t_cuerpoB'] || formClienteDetalleAta['t_cuerpoC'] || formClienteDetalleAta['t_cuerpoD'] )) {
            console.log('Debe ingresar al menos un valor de tensión');
            setValidaATA({"error":true, "msg":"Debe ingresar al menos un valor de tensión"});
            return
        }
        if (!formClienteDetalleAta['seccion']){
            setValidaATA({"error":true, "msg":"Debe ingresar la sección"});
            return
        }

        let datosClienteATA = formClienteDetalleAta;
        if (datosClienteATA['resistencia_AB']) {
          if (!datosClienteATA['UnidadResistenciaAB']){
            setValidaATA({"error":true, "msg":"Debe ingresar la unidad de resistencia AB"});
            return
          }
            datosClienteATA['resistencia_AB'] = datosClienteATA['resistencia_AB'].concat(' ', datosClienteATA['UnidadResistenciaAB']);
        }
        if (datosClienteATA['resistencia_BC']) {
          if (!datosClienteATA['UnidadResistenciaBC']){
            setValidaATA({"error":true, "msg":"Debe ingresar la unidad de resistencia BC"});
            return
          }
          datosClienteATA['resistencia_BC'] = datosClienteATA['resistencia_BC'].concat(' ', datosClienteATA['UnidadResistenciaBC']);
        }
        if (datosClienteATA['resistencia_CD']) {
          if (!datosClienteATA['UnidadResistenciaCD']){
            setValidaATA({"error":true, "msg":"Debe ingresar la unidad de resistencia CD"});
            return
          }
          datosClienteATA['resistencia_CD'] = datosClienteATA['resistencia_CD'].concat(' ', datosClienteATA['UnidadResistenciaCD']);
        }
        if (datosClienteATA['resistencia_DE']) {
          if (!datosClienteATA['UnidadResistenciaDE']){
            setValidaATA({"error":true, "msg":"Debe ingresar la unidad de resistencia DE"});
            return
          }
          datosClienteATA['resistencia_DE'] = datosClienteATA['resistencia_DE'].concat(' ', datosClienteATA['UnidadResistenciaDE']);
        }


        const detalleATA = [];
        detalleATA.push(datosClienteATA);
        const salidaDet = {"detalle": detalleATA}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };
        console.log('Graba ATA: ', datoGrabar);
        
        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            console.log(error.response)
            }
        });
    }else if (props.cod_epp === 'TPE') {
        console.log('Graba TPE', detalleEnsayo);
        const detalleDb = detalleEnsayo.map((e) => {
          return {"serie_epp": e.serie,
                  "fuga1": e.fuga1,  
                  "parches": e.parches,  
                  "tension": e.tension, 
                  "resultado": e.resultado,
                  'resistencia_AB': e.resistencia_AB,
                  'resistencia_AC': e.resistencia_AC,
                  'resistencia_AD': e.resistencia_AD,
                  'resistencia_BC': e.resistencia_BC,
                  'resistencia_BD': e.resistencia_BD,
                  'resistencia_CD': e.resistencia_CD,
                  'UnidadResistenciaAB': e.UnidadResistenciaAB,
                  'UnidadResistenciaAC': e.UnidadResistenciaAC,
                  'UnidadResistenciaAD': e.UnidadResistenciaAD,
                  'UnidadResistenciaBC': e.UnidadResistenciaBC,
                  'UnidadResistenciaBD': e.UnidadResistenciaBD,
                  'UnidadResistenciaCD': e.UnidadResistenciaCD,
                  'seccion': e.seccion,
                }});
        const salidaDet = {"detalle": detalleDb}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };

        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });
    }else if (props.cod_epp === 'CAM') {
        console.log('Graba CAM', formDetalleCam);
        let copiaFormDetalle = formDetalleCam;
        if (copiaFormDetalle['plsup_tension1']){
          if (copiaFormDetalle['plsup_ar1'] === 'on'){
            copiaFormDetalle['pluma_superior'] = 'APROBADO';
            if (copiaFormDetalle['plsup_tension2'] && copiaFormDetalle['plsup_ar2'] !== 'on'){
              copiaFormDetalle['pluma_superior'] = 'RECHAZADO';
            }
            if (copiaFormDetalle['plsup_tension3'] && copiaFormDetalle['plsup_ar3'] !== 'on'){
              copiaFormDetalle['pluma_superior'] = 'RECHAZADO';
            }
            if (copiaFormDetalle['plinf_tension1'] && copiaFormDetalle['plinf_ar1'] === 'on'){
              copiaFormDetalle['pluma_inferior'] = 'APROBADO';
            }
          }
        }
        if (copiaFormDetalle['pluma_superior'] === 'APROBADO' && copiaFormDetalle['pluma_inferior'] === 'APROBADO'){
          copiaFormDetalle['resultado'] = 'APROBADO';
        }
        const salidaDet = {"detalle": [copiaFormDetalle]}
        const datoGrabar = {
          id_batea: props.celdas.id,
          detalle: JSON.stringify(salidaDet),
        };
        await axios({
          method: "POST",
          url:"/api/grabadetensayo",
          data: datoGrabar
        })
        .then((response) => {
          //console.log('axios grabadetensayo: ', response.data.msg);
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });


    }else {
          const detalleDb = detalleEnsayo.map((e) => {
            return {"serie_epp": e.serie,
                    "fuga1": e.fuga1,  
                    "parches": e.parches,  
                    "tension": e.tension, 
                    "resultado": e.resultado}});
          const salidaDet = {"detalle": detalleDb}
          const datoGrabar = {
            id_batea: props.celdas.id,
            detalle: JSON.stringify(salidaDet),
          };

          await axios({
            method: "POST",
            url:"/api/grabadetensayo",
            data: datoGrabar
          })
          .then((response) => {
            //console.log('axios grabadetensayo: ', response.data.msg);
          }).catch((error) => {
            if (error.response) {
              //console.log(error.response)
              //console.log(error.response.status)
              //console.log(error.response.headers)
              }
          });}
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
        //window.location.reload(false);
 
  }

//**************************************************************************** */
function cambioEnFormDetalleJmp (event) {
    const {value, name} = event.target;
    setFormClienteDetalleJmp(prevNote => ({...prevNote, [name]: value}))
}
//**************************************************************************** */
async function handleShow () {
  await axios({
      method: "POST",
      url:"/api/formensayos",
      data: {
        "id_batea": props.celdas.id,
      }
    })
    .then((response) => {

      setFormCliente({
        'cod_ensayo': props.celdas.codigo,
        'cliente': response.data.datos[0].cliente,
        'sucursal': response.data.datos[0].sucursal,
        'negocio': response.data.datos[0].negocio,
        'temperatura': response.data.datos[0].temperatura,
        'humedad': response.data.datos[0].humedad,
        'tecnico': response.data.datos[0].tecnico,
        'patron': response.data.datos[0].patron,
        'tipo_ensayo': props.nombre_tabla.split("/")[2],
        'fecha_ejecucion': response.data.datos[0].fecha_ejecucion,
        'fecha_ingreso': response.data.datos[0].fecha_ingreso,
        'orden_compra': response.data.datos[0].orden_compra,
        'dir_ensayo': response.data.datos[0].dir_ensayo,
        'estado': '2',
      })
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });

  await axios({
    method: "POST",
    url:"/api/listadetalle",
    data: {
      "id_batea": props.celdas.id,
      "cod_epp": props.cod_epp,
    }
  })
  .then((response) => {
    //setValorTec({"valor": response.data.datos[0].tecnico})
    if (props.cod_epp=='JMP'){
      setDetalleEnsayoJmp(response.data.datos);
    }else{
      console.log('datos_tpe: ', response.data.datos);
      setDetalleEnsayo(response.data.datos);
      //console.log('datos: ', response.data.datos[0].serie);
      if (props.cod_epp=='LDB'){
        setSerieLDB(response.data.datos[0].serie);
      }
    }
    
  }).catch((error) => {
    if (error.response) {
      //console.log(error.response)
      //console.log(error.response.status)
      //console.log(error.response.headers)
      }
  });
  
  setShow(true);
  //setEditaForm(false);
}


    return (
    <>
    <button 
    className="btn btn-outline-primary" 
    size="sm" 
    data-toggle="tooltip" 
    data-placement="top"
    title="Editar Detalles"
    onClick={handleShow}><ListCheck /></button>
      <Modal show={show} fullscreen={true} onHide={handleClose} size="lg" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Editar Detalles para Ensayo {props.celdas.codigo}</h3></Modal.Title>
        </Modal.Header>
            <Modal.Body>
                <Form>
                    <Container>
                      <Row>
                        {/* Formulario de ingreso de detalle */}
                        {/* *********************************************************** */}
                        <Col xs={15} md={10}>

                        </Col>
                        <Col xs={3} md={2}>
                          {props.cod_epp == 'LDB' || props.cod_epp == 'JMP' || props.cod_epp == 'ATA' || props.cod_epp == 'TPE' || props.cod_epp == 'CAM'?null:
                            <Button disabled={listaDetalleCompleta} variant="primary" onClick={() => {setApruebaVisual(false); setShowDetalle(true)}}>
                            + Agregar Detalle
                            </Button>
                          }
                          {props.cod_epp == 'JMP'?
                            <Button disabled={listaDetalleCompleta} variant="primary" onClick={() => {setApruebaVisual(false); setShowDetalle(true)}}>
                            + Agregar Detalle
                            </Button>:null
                          }
                          {/** ABRE MODAL 1 */}
                          <Modal
                            show={showDetalle}
                            onHide={() => {setShowDetalle(false); setFormClienteDetalle({})}}
                            dialogClassName="modal-90w"
                            aria-labelledby="example-custom-modal-styling-title"
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="example-custom-modal-styling-title">
                                <h3>Ingresar Detalle</h3>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              {/* Elemento es jumper */}
          {/* ********************************************************************************** */}
                            {props.cod_epp=='JMP'/* Elemento jumper*/?
                              <Form id="form-interno-2">
                                <Container>
                                    <Row>
                                    <Card >
                                      <Card.Header><span className="titulo-card">Ensayo dieléctrico</span></Card.Header>
                                    </Card>
                                    </Row>
                                    <hr />
                                  <Row >
                                  <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Serie Epp</span></Form.Label>
                                      <InputGroup id="serie-epp" >
                                        <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                                          <Form.Control
                                          onChange={cambioEnFormDetalleJmp}
                                          name="serie"
                                          type="number"
                                          placeholder="Serie epp"
                                          />
                                      </InputGroup>
                                  </Col>
                                  <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Tensión Ensayo</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="tension"
                                      type="number"
                                      placeholder="kV"
                                      />
                                  </Col>
                                  <Col xs={6} md={4}>
                                    <Form.Label><span className="campo">Corriente de fuga</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="fuga1"
                                      type="number"
                                      placeholder="mA" />
                                  </Col>
                                  
                                  </Row>
                                  <hr />
                                  <Row>
                                  <Col xs={6} md={4}>
                                    <Form.Label><span className="campo">Inspección Visual</span></Form.Label>
                                      <Form.Check  value="AR1" type="checkbox" id="ck2" >
                                      <Form.Check.Input name="visual" type="checkbox" isValid onChange={cambioEnFormDetalleJmp}  />
                                      <Form.Check.Label>Aprobado</Form.Check.Label>
                                      </Form.Check>
                                  </Col>
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Resultado E. dielectrico</span></Form.Label>
                                      <Form.Check  value="AR1" type="checkbox" id="ck1" >
                                      <Form.Check.Input name="dieresul" type="checkbox" isValid onChange={cambioEnFormDetalleJmp} />
                                      <Form.Check.Label>Aprobado</Form.Check.Label>
                                      </Form.Check>
                                    </Col>
                                  </Row>
                                  <hr />
                                    <Row>
                                      <Card >
                                        <Card.Header><span className="titulo-card">Ensayo resistencia eléctrica</span></Card.Header>
                                      </Card>
                                      </Row>
                                      <hr />
                                    <Row >
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Tramo</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="tramo"
                                      type="text"
                                      placeholder="tramo"
                                      />
                                    </Col>
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Seccion Conductor</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="seccion"
                                      type="text"
                                      placeholder="seccion"
                                      />
                                    </Col>
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Longitud (ft)</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="longitud"
                                      type="number"
                                      placeholder="ft"
                                      />
                                    </Col>
                                  </Row>
                                  <hr />
                                  <Row >
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Resistencia Medida (μΩ)</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="resismedida"
                                      type="number"
                                      placeholder="μΩ"
                                      />
                                    </Col>
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Resistencia Máxima (μΩ)</span></Form.Label>
                                      <Form.Control
                                      onChange={cambioEnFormDetalleJmp}
                                      name="resismax"
                                      type="number"
                                      placeholder="μΩ"
                                      />
                                    </Col>
                                    <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Resultado Ω Eléctrica</span></Form.Label>
                                      <Form.Check  value="AR1" type="checkbox" id="ck3" >
                                      <Form.Check.Input name="resisresul" type="checkbox" isValid onChange={cambioEnFormDetalleJmp} />
                                      <Form.Check.Label>Aprobado</Form.Check.Label>
                                      </Form.Check>
                                    </Col>
                                  </Row>
                                </Container>
                              </Form>



          /**************************  Elemetos distinto jumper */
                            :
                            <Form id="form-interno">
                            <Container>
                              <Row >
                                  <Col xs={6} md={4}>
                                      <Form.Label><span className="campo">Serie Epp</span></Form.Label>
                                      <InputGroup id="input-serie" >
                                        <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                                          <Form.Control
                                          onChange={cambioEnFormDetalle}
                                          name="serie"
                                          type="number"
                                          placeholder="Serie epp"
                                          />
                                      </InputGroup>
                                      <p><span className = "mensaje-error">{erroformClienteDetalle?.serie}</span></p>
                                  </Col>
                                  <Col xs={6} md={5}>
                                      <Form.Label><span className="campo">Inspección Visual</span></Form.Label>
                                      <span className="campo">
                                      <Form.Check
                                        type="checkbox"
                                        name="visual"
                                        label="Aprobada"
                                        onChange={cambiaVisual}
                                      /></span>
                                  </Col>
                                  <Col xs={4} md={3}>
                                      <Form.Label><span className="campo"></span></Form.Label>
                                      <InputGroup id="input-btn">
                                      <button className="btn btn-outline-primary" 
                                      onClick={agregaDetalle}>
                                      <PlusSquare />
                                    </button></InputGroup>
                                  </Col>
                              </Row>
                              <Row>
                                <Col xs={4} md={3}>
                                <Form.Label><span className="campo">Número de parches</span></Form.Label>
                                  <Form.Control
                                  disabled={!apruebaVisual}
                                  onChange={cambioEnFormDetalle}
                                  name="parches"
                                  type="number"
                                  placeholder="parches"
                                  />
                                  <p><span className = "mensaje-error">{erroformClienteDetalle?.parches}</span></p>
                                </Col>
                                <Col xs={4} md={3}>
                                <Form.Label><span className="campo">Tensión de Ensayo</span></Form.Label>
                                  <Form.Control
                                  disabled={!apruebaVisual}
                                  onChange={cambioEnFormDetalle}
                                  name="tension"
                                  type="number"
                                  placeholder="kV"
                                  />
                                  <p><span className = "mensaje-error">{erroformClienteDetalle?.tension}</span></p>
                                </Col>
                                <Col xs={4} md={3}>
                                  <Form.Group className="mb-3" controlId="form-6">
                                  <Form.Label><span className="campo">Corriente fuga </span></Form.Label>
                                  <Form.Control
                                    disabled={!apruebaVisual}
                                    onChange={cambioEnFormDetalle}
                                    name="fuga1"
                                    type="number"
                                    placeholder="I(mA)"
                                    />
                                  </Form.Group>
                                  <p><span className = "mensaje-error">{erroformClienteDetalle?.fuga1}</span></p>
                                </Col>
                                {/*console.log('codigo tipo: ', props.cod_epp)*/}
                                <Col xs={4} md={3}>
                                {props.cod_epp!=='GNT'?
                                  <Form.Group className="mb-3" controlId="form-7">
                                  <Form.Label><span className="campo">Resultado</span></Form.Label>
                                  <Form.Select name="resultado" disabled={!apruebaVisual} aria-label="Default select example" onChange={cambioEnFormDetalle} >
                                    <option value="RECHAZADO">RECHAZADO</option>
                                    <option value="APROBADO">APROBADO</option>
                                  </Form.Select>
                                  </Form.Group>
                                  :null
                                }
                                <Form.Label><span className="campo"></span></Form.Label>
                                      <InputGroup id="input-btn-agrega" >
                                      <button className="btn btn-outline-primary" 
                                      onClick={agregaDetalle}>
                                      <PlusSquare />
                                    </button></InputGroup>
                                </Col>
                              </Row>
                            </Container>
                            </Form>}
                            {errorformClienteDetalleJmp?<p><span className = "mensaje-error">{errorformClienteDetalleJmp}</span></p>:null}
                            </Modal.Body>
                                {props.cod_epp=='JMP'?
                                <Modal.Footer>
                                <Button variant="secondary" onClick={() => {setShowDetalle(false); setFormClienteDetalleJmp({});}}>
                                  Cancelar
                                </Button>
                                <Button variant="primary" onClick={agregaDetalleJmp}>
                                  Agregar
                                </Button>
                                </Modal.Footer>
                                :
                                <Modal.Footer>
                                  <Button variant="secondary" onClick={() => {setShowDetalle(false); setFormClienteDetalle({}); setErrorformClienteDetalle({})}}>
                                    Cancelar
                                  </Button>
                                  <Button variant="primary" onClick={agregaDetalle}>
                                    Agregar
                                  </Button>
                                </Modal.Footer>}
                          </Modal>
                          {/** CIERRA MODAL 1 */}
                        </Col>
                        <Col xs={9} md={6}>
                          Detalle de Ensayo
                        </Col>
                      </Row>
                    </Container>
                </Form>
{/**###################################################################################### */}
{/**###################################################################################### */}
{/**###################################################################################### */}
          {props.cod_epp == 'LDB'?
              <>
                <Row>
                  <span className="titulo-card">{serieLDB?<>Epp asociado  {serieLDB} <Button variant="primary" onClick={borraLdb}>Liberar</Button></>:'No hay Epp asociado'} </span>
                </Row>
                <Row>
                    <Card >
                        <Card.Header><span className="titulo-card">SERIE EPP </span></Card.Header>
                    </Card>
                </Row>
                <Row>
                    <Col xs={6} md={4}>
                        <InputGroup id="input-serie-epp">
                            <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                            <Form.Control
                            /*value={serieForEpp}*/
                            onChange={cambioEnEppLoad}
                            name="serie"
                            type="number"
                            placeholder="Serie epp"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={3} md={2}>
                        <Button variant="primary" onClick={validarEpp}>Validar</Button>
                    </Col>
                    <Col xs={9} md={6}>
                        <p><span className={validacionEpp.error?'msg-error':'titulo-card'}>{validacionEpp.msg}</span></p>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">PATRONES</span></Card.Header>
                    </Card>
                </Row>
                <Row >
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron">
                        <Form.Label><span className="campo">Patron 1</span>
                        </Form.Label>
                        <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron1"  onChange={cambioDetalleLoad}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron2">
                        <Form.Label><span className="campo">Patron 2</span>
                        </Form.Label>
                        <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron2"  onChange={cambioDetalleLoad}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron3">
                        <Form.Label><span className="campo">Patron 3</span>
                        </Form.Label>
                        <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron3"  onChange={cambioDetalleLoad}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">ENSAYOS</span></Card.Header>
                    </Card>
                </Row>
                <Row>
                    <Col>
                    <h5><span className="titulo-columna">DIELECTRICO</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">AISLACIÓN (C. ABIERTO)</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">CONTACTO (C. CERRADO)</span></h5>
                    </Col>
                </Row>
                <Row >
                    {/* Dielectrico */}
                    <Col>
                            <Form.Group className="mb-3" controlId="form-8">
                            <Form.Label><span className="campo">Tensión (kV)</span></Form.Label>
                            <Form.Control
                              name="tension1"
                              type="text"
                              defaultValue="(kV)"
                              onChange={cambioDetalleLoad}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                    <Form.Group className="mb-3" controlId="form-9">
                            <Form.Label><span className="campo">Corriente fuga </span></Form.Label>
                            <Form.Control
                              name="fuga1"
                              type="text"
                              defaultValue="(mA)"
                              onChange={cambioDetalleLoad}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Label><span className="campo">A - R</span></Form.Label>
                        <Form.Check  value="AR1" type="checkbox" id="ck2" >
                        <Form.Check.Input name="AR1" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>

                    {/* AISLACIÓN C. ABIERTO */}
                    <Col >
                        <Form.Group className="mb-3" controlId="form-10">
                            <Form.Label><span className="campo">Tensión (kV)</span></Form.Label>
                            <Form.Control
                              name="tension2"
                              type="text"
                              defaultValue="(kV)"
                              onChange={cambioDetalleLoad}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                    <Form.Group className="mb-3" controlId="form-11">
                            <Form.Label><span className="campo">Medida (Ω) </span></Form.Label>
                            <Form.Control
                              name="medida1"
                              type="text"
                              defaultValue="(TΩ)"
                              onChange={cambioDetalleLoad}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Label><span className="campo">A - R</span></Form.Label>
                        <Form.Check  value="AR2" type="checkbox" id="ck2" >
                        <Form.Check.Input name="AR2" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>

                    {/* CONTACTO C. CERRADO */}
                    <Col >
                    <Form.Group className="mb-3" controlId="form-12">
                            <Form.Label><span className="campo">Tensión (V) </span></Form.Label>
                            <Form.Control
                              name="tension3"
                              type="text"
                              defaultValue="(μV)"
                              onChange={cambioDetalleLoad}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                    <Form.Group className="mb-3" controlId="form-13">
                            <Form.Label><span className="campo">Medida (Ω) </span></Form.Label>
                            <Form.Control
                              name="medida2"
                              type="text"
                              defaultValue="(μΩ)"
                              onChange={cambioDetalleLoad}
                              />
                            </Form.Group>
                    </Col>
                    <Col >
                        <Form.Label><span className="campo">A - R</span></Form.Label>
                        <Form.Check  value="AR3" type="checkbox" id="ck2" >
                        <Form.Check.Input name="AR3" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>Aprobado</Form.Check.Label>
                        </Form.Check>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">INSPECCIÓN VISUAL (marcar las casillas aprobadas)</span></Card.Header>
                    </Card>
                </Row>
                <Row >
                    <Col xs={6} md={4}>
                        <Form.Check  type="checkbox" id="carcaz" >
                        <Form.Check.Input name="carcaz" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CARCAZA</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="gancho" >
                        <Form.Check.Input name="gancho" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CONJUNTO GANCHO SUJECIÓN</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="cancla" >
                        <Form.Check.Input name="cancla" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CONJUNTO ANCLA</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="contop" >
                        <Form.Check.Input name="contop" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CONTADOR DE OPERACIONES</Form.Check.Label>
                        </Form.Check>
                    </Col>
                    <Col xs={6} md={4}>
                        <Form.Check  type="checkbox" id="apertu" >
                        <Form.Check.Input name="apertu" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>APERTURA ANILLO DE TIRO</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="anillo" >
                        <Form.Check.Input name="anillo" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CIERRE ANILLO DE TIRO</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="extiro" >
                        <Form.Check.Input name="extiro" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>EXTENSION DE TIRO</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="citiro" >
                        <Form.Check.Input name="citiro" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CIERRE DE TIRO</Form.Check.Label>
                        </Form.Check>
                    </Col>
                    <Col xs={6} md={4}>
                        <Form.Check type="checkbox" id="seguro" >
                        <Form.Check.Input name="seguro" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>SEGURO DE RESTABLECIMIENTO</Form.Check.Label>
                        </Form.Check>

                        <Form.Check type="checkbox" id="cubier" >
                        <Form.Check.Input name="cubier" type="checkbox" isValid onChange={cambiaCheck} />
                        <Form.Check.Label>CUBIERTA</Form.Check.Label>
                        </Form.Check>

                            <Form.Group as={Row} className="mb-3" controlId="contad">
                            <Form.Label column sm="3">
                                <h5><span className="titulo-columna">CONTADOR: </span></h5>
                                
                            </Form.Label>
                            <Col sm="1">

                            </Col>
                            <Col sm="8">
                                <Form.Control name="contad" type="number" placeholder="valor" onChange={cambioDetalleLoad} />
                            </Col>
                            </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">CONCLUSIÓN (marcar ítems aprobados)</span></Card.Header>
                    </Card>
                </Row>
                <Row>
                <Col xs={6} md={4}>
                    <Form.Check  type="checkbox" id="ensayoresul" >
                    <Form.Check.Input disabled={true} name="ensayoresul" type="checkbox" isValid onChange={cambiaCheckResult} />
                    <Form.Check.Label>ENSAYOS</Form.Check.Label>
                    </Form.Check>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Check type="checkbox" id="insresultado" >
                    <Form.Check.Input disabled={true} name="insresultado" type="checkbox" isValid onChange={cambiaCheckResult} />
                    <Form.Check.Label>INSPECCIÓN VISUAL</Form.Check.Label>
                    </Form.Check>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Check type="checkbox" id="resultado" >
                    <Form.Check.Input disabled={true} name="resultado" type="checkbox" isValid onChange={cambiaCheckResult} />
                    <Form.Check.Label>RESULTADO</Form.Check.Label>
                    </Form.Check>
                </Col>
                </Row>
              </>
            :<MostrarListado cod_epp={props.cod_epp} />}
          {props.cod_epp==='ATA'?
            <>
                <Row>
                    <Card >
                        <Card.Header><span className="titulo-card">SERIE EPP </span></Card.Header>
                    </Card>
                </Row>
                <Row>
                    <Col xs={6} md={4}>
                        <InputGroup id="input-serie-epp-ata">
                            <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                            <Form.Control
                            /*value={serieForEpp}*/
                            onChange={cambioEnEppLoad}
                            name="serie"
                            type="number"
                            placeholder="Serie at araña"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={3} md={2}>
                        <Button variant="primary" onClick={validarEpp}>Validar</Button>
                    </Col>
                    <Col xs={9} md={6}>
                        <p><span className={validacionEpp.error?'msg-error':'titulo-card'}>{validacionEpp.msg}</span></p>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">PATRONES - SECCIÓN</span></Card.Header>
                    </Card>
                </Row>
                <Row >
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron">
                        <Form.Label><span className="campo">Patron 1</span>
                        </Form.Label>
                        <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron1"  onChange={cambioDetalleAta}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="patron2">
                        <Form.Label><span className="campo">Patron 2</span>
                        </Form.Label>
                        <Form.Select disabled={deshabilitaBotonGrabar} aria-label="Default select example" name="patron2"  onChange={cambioDetalleAta}>
                            <SelectParam parametro="patron" />
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className="mb-3" controlId="SeccionATA">
                            <Form.Label><span className="campo">SECCIÓN</span></Form.Label>
                            <Form.Control
                              name="seccion"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                    </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">RESISTENCIA CONTACTO</span></Card.Header>
                    </Card>
                </Row>
                <Row>
                    <Col>
                    <h5><span className="titulo-columna">A - B</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">B - C</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">C - D</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">D - E</span></h5>
                    </Col>
                </Row>
                <Row >
                    <Col >
                    <InputGroup className="mb-3">
                            <Form.Control
                              name="resistencia_AB"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                                <Form.Select aria-label="Default select example" name="UnidadResistenciaAB"  onChange={cambioDetalleAta}>
                                    <SelectUnidadResistencia parametro="Unidad" />
                                </Form.Select>
                    </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="resistencia_BC"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                                <Form.Select aria-label="Default select example" name="UnidadResistenciaBC"  onChange={cambioDetalleAta}>
                                    <SelectUnidadResistencia parametro="Unidad" />
                                </Form.Select>
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="resistencia_CD"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                                <Form.Select aria-label="Default select example" name="UnidadResistenciaCD"  onChange={cambioDetalleAta}>
                                    <SelectUnidadResistencia parametro="Unidad" />
                                </Form.Select>
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="resistencia_DE"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                                <Form.Select aria-label="Default select example" name="UnidadResistenciaDE"  onChange={cambioDetalleAta}>
                                    <SelectUnidadResistencia parametro="Unidad" />
                                </Form.Select>
                          </InputGroup>
                    </Col>
                </Row>
                <Row >
                    <Card >
                        <Card.Header><span className="titulo-card">ENSAYO DIELÉCTRICO</span></Card.Header>
                    </Card>
                </Row>
                <Row>
                    <Col>
                    <h5><span className="titulo-columna"></span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">Cuerpo A</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">Cuerpo B</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">Cuerpo C</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">Cuerpo D</span></h5>
                    </Col>
                    <Col>
                    <h5><span className="titulo-columna">Cuerpo E</span></h5>
                    </Col>
                </Row>
                <Row >
                    <Col>
                      <InputGroup >
                          <InputGroup.Text>Tensión de Ensayo (kV)</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col >
                    <InputGroup className="mb-3">
                            <Form.Control
                              name="t_cuerpoA"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                    </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="t_cuerpoB"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="t_cuerpoC"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="t_cuerpoD"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="t_cuerpoE"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                </Row>
                <Row >
                    <Col>
                      <InputGroup >
                          <InputGroup.Text>Corriente de Fuga (mA)</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col >
                    <InputGroup className="mb-3">
                            <Form.Control
                              name="i_cuerpoA"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                    </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="i_cuerpoB"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="i_cuerpoC"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="i_cuerpoD"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                    <Col >
                          <InputGroup className="mb-3">
                            <Form.Control
                              name="i_cuerpoE"
                              type="text"
                              defaultValue=""
                              onChange={cambioDetalleAta}
                              />
                          </InputGroup>
                    </Col>
                </Row>
                <Row >
                  {validaATA.error?<p><span className="mensaje-error">{validaATA.msg}</span></p>:null}
                </Row>
            </>
          :null}
          {props.cod_epp==='TPE'?
            <ListadoDetalleIngresoTpe datos={detalleEnsayo} 
            borraElementoDetalle={borraElementoDetalle} 
            setListaDetalleCompleta={setListaDetalleCompleta} 
            listaDetalleCompleta={listaDetalleCompleta}
            cod_epp={props.cod_epp}
            detalleEnsayo={detalleEnsayo} 
            setDetalleEnsayo={setDetalleEnsayo}
            formCliente={formCliente}
            setDeshabilitaBotonGrabar={setDeshabilitaBotonGrabar}
            deshabilitaBotonGrabar={deshabilitaBotonGrabar}
            />:null}
          {props.cod_epp==='CAM'?
          <ListadoDetalleIngresoCam datos={detalleEnsayo} 

          cod_epp={props.cod_epp}
          serieCAM={serieCAM} 
          setSerieCAM={setSerieCAM}
          borraCam = {borraCam}
          cambioEnEppCam={cambioEnEppCam}
          validarEpp={validarEpp}
          validacionEpp={validacionEpp}
          setValidacionEpp={setValidacionEpp}
          setDeshabilitaBotonGrabar={setDeshabilitaBotonGrabar}
          deshabilitaBotonGrabar={deshabilitaBotonGrabar}
          cambiaCheckCam={cambiaCheckCam}
          cambioDetalleCam={cambioDetalleCam}
          dataDetalle={dataDetalle}
          setDetalleCam={setDetalleCam}

          />:null}


{/**###################################################################################### */}
{/**###################################################################################### */}
{/**###################################################################################### */}

            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          {props.cod_epp=='LDB'||props.cod_epp=='ATA'||props.cod_epp=='TPE'||props.cod_epp=='CAM'?
            <Button disabled={deshabilitaBotonGrabar} variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>:
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
          }
        </Modal.Footer>
        </Modal>
    </>
    )
};

export default EditarDetalleEnsayo;