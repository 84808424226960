import React, { useState } from "react";
import { Row, Col, Table as TablaBoot, Card, ListGroup, Button, Form, Navbar,Container, Image, InputGroup, Pagination } from "react-bootstrap";
import axios from "axios";
import fileDownload from 'js-file-download'
import styled from 'styled-components'
import { useTable, usePagination, useRowSelect } from 'react-table'
import logosm1 from '../../imagenes/logosm1';
import f_logo from '../../imagenes/logo.bmp';
import SelectParamTest from "../selectores/SelectParamTest";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)

async function DescargaInformes (datos, token) {
  const valores = datos.map(d => d.original);
  //console.log('informes: ', valores)
  for (var i = 0; i <= valores.length; i++) {
    await axios.request({
      url: "/api/getpdf",
      method: "POST",
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        'ensayopdf': valores[i].informe_ensayo,
        'verpng': false,
      },
    }).then((res) => {
      fileDownload(res.data, valores[i].informe_ensayo + ".pdf");
    }).catch(function (error) {
        //console.log(error);
    });
  } 
}

function Table({ columns, data, token }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  // Render the UI for your table
  return (
    <>
      
      <TablaBoot striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </TablaBoot>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div className="pagination">

        <Row>
          <Col xs={5} md={4} >
                <Pagination size='md'  >
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                  <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                  <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                </Pagination>
          </Col>
          <Col xs={5} md={4}>
              <span className="letras-negras">
                Página{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </span>
          </Col>
          
        <Col xs={5} md={4}>
        <Form.Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </Form.Select>
        </Col>
        <Col xs={3} md={4}>
          <Button onClick={() => DescargaInformes(selectedFlatRows, token)} >Descargar Informes</Button>
        </Col>
        </Row>
      </div>
    </>
  )
}

function Informe02 (props) {

  const c = new Date().toLocaleString("es-CL", {timeZone: "America/Santiago"});
  const fechoy = c.substring(6,10) + '-' + c.substring(3,5) + '-' + c.substring(0,2);

  const [formConsulta, setFormConsulta] = useState({
    'elemento': '',
    'fecha_ini': fechoy,
    'fecha_fin': fechoy,
    'cliente': '99999',
    'empresa': '',
    'doc_ref': '',
});
const [errorForm, setErrorForm] = useState();
const [datosTabla, setDatosTabla] = useState();
const [mensajeBuscar, setMensajeBuscar] = useState();


const columnas = [
  {
    Header: 'Empresa',
    accessor: 'empresa'
  }, {
    Header: 'Elemento',
    accessor: 'elemento'
  }, {
    Header: 'Fecha Ensayo',
    accessor: 'fecha_ensayo'
  }, {
    Header: 'Informe Ensayo',
    accessor: 'informe_ensayo'
  }
];

function cambioEnFecha (event) {
  const {value, name} = event.target;
  if (name === 'fecha_ini') {
    setFormConsulta(prevNote => ({...prevNote, [name]: value, ['fecha_fin']: fechoy}))
  }else {
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
  }
  setErrorForm(null);
}

function cambioEnForm (event) {
  const {value, name} = event.target;
  //console.log('cambio form encabezado', name, value);
  setErrorForm(null);
  setFormConsulta(prevNote => ({...prevNote, [name]: value}))
}

async function get_ensayados() {
    let formData = {};
    formData['elemento'] = formConsulta['elemento'];
    formData['doc_ref'] = formConsulta['doc_ref'];
    formData['fecha_ini'] = formConsulta['fecha_ini'];
    formData['fecha_fin'] = formConsulta['fecha_fin'];
    //console.log('empresa', formConsulta['empresa']);
    if (!formConsulta['empresa']){
      //se deja igual
      formData['cliente'] = formConsulta['cliente'];
    }else {
      //se cambia el cliente por empresa
      formData['cliente'] = formConsulta['empresa'];
    }
    
  //console.log('formConsulta: ', formConsulta);
  await axios.request({
    url: "/api/informesemitidos",
    method: "POST",
    headers: {
      Authorization: 'Bearer ' + props.token
    },
    data: formData
}).then((res) => {
  //console.log('respuesta axios: ', res.data)
  if (res.data.datos) {
    if (res.data.datos.length !== 0) {
      //console.log('respuesta largo: ', res.data.datos.length)
      setMensajeBuscar(null);
      setDatosTabla(res.data.datos);
    }else {
      setMensajeBuscar('No hubo resultado para la búsqueda')
    }
  }
}).catch((error) => {
  if (error.response) {
    setErrorForm(error.response.data.msg)
    //console.log(error.response.data.msg)
    }
  });
}

function limpia() {
  //setPdfBase64(null);
  //setNombres({'ensayo':'', 'elemento':''});
  let temp = "";
    let mySelect = document.getElementById('TipoElemento');
    for(var i, j = 0; i = mySelect.options[j]; j++) {
        if(i.value == temp) {
            mySelect.selectedIndex = j;
            break;
        }
    }
    temp = "";
    mySelect = document.getElementById('TipoEmpresa');
    for(var i, j = 0; i = mySelect.options[j]; j++) {
        if(i.value == temp) {
            mySelect.selectedIndex = j;
            break;
        }
    }

  setErrorForm(null);
  setDatosTabla(null);
  setMensajeBuscar(null);
  setFormConsulta({
    'elemento': '',
    'fecha_ini': fechoy,
    'fecha_fin': fechoy,
    'cliente': '99999',
    'empresa': '',
    'doc_ref': '',

  });
}

    return (
        <>
          {props.mostrarCabecera?
            <>
            <Navbar className="bg-white">
              <Container>
                <Navbar.Brand href="#home"><img  src={f_logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras-grande">Laboratorio Ensayo Equipos Dieléctricos</span></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                  <Button onClick={() => { props.setMuestraMenu(true); props.setOpcion1(0)}} >volver</Button>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
            </>:null}
        <hr />
        <Row >
          <Col md={{ span: 3, offset: 1 }} >
          <span className="letras-negras">Módulo de Descarga Masiva de Informes</span>
          </Col>
        </Row>
        <hr />
        <Row>
              {/******************** COLUMNA INICIAL ***********************/}
              <Col xs={2} md={1}></Col>
              <Col xs={12} md={6}>
                      <p><span className = "mensaje-error">{errorForm}</span></p>
              </Col>
                    </Row>
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>

          
          <Col xs={8} md={4}>
                <Form>
                    {props.perfil=='superusuario'?<>
                      <Form.Group as={Row} className="mb-3" controlId="TipoEmpresa">
                            <Form.Label column sm="4">
                            <span className="campo">
                              Empresa
                            </span>
                            </Form.Label>
                            <Col sm="8">
                              <SelectParamTest parametro="empresa" valorDefault={formConsulta.empresa} cambioEnForm={cambioEnForm} />
                            </Col>
                          </Form.Group>
                    </>:null}
                  {/******************** COLUMNA TIPO ELEMENTO ***********************/}
                  <Form.Group as={Row} className="mb-3" controlId="TipoElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Tipo Elemento
                      </span>
                      </Form.Label>
                      <Col sm="8">
                        <SelectParamTest parametro="elemento" valorDefault={formConsulta.tipo} 
                        cambioEnForm={cambioEnForm} />
                      </Col>
                    </Form.Group>
                    {/******************** COLUMNA DOCUMENTO REFERENCIA ***********************/}
                  <Form.Group as={Row} className="mb-3" controlId="TipoElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Documento Referencia
                      </span>
                      </Form.Label>
                      <Col sm="8">
                            <InputGroup >
                                <Form.Control
                                min={1}
                                value={formConsulta.doc_ref}
                                name="doc_ref"
                                type="text"
                                placeholder="referencia"
                                onChange={cambioEnForm}
                                />
                            </InputGroup>
                      </Col>
                    </Form.Group>

                    {/******************** COLUMNA FECHA INICIO ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="FecIni">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Fecha Inicio
                      </span>
                      </Form.Label>
                      <Col sm="8">
                      <Form.Control value={formConsulta.fecha_ini} max={fechoy} type="date" name="fecha_ini" placeholder="" onChange={cambioEnFecha} />
                      </Col>
                      </Form.Group>

                    {/******************** COLUMNA FECHA FIN ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="FecFin">
                      <Form.Label column sm="4">
                        <span className="campo">Fecha Fin</span>
                      </Form.Label>
                      <Col sm="8">
                      <Form.Control value={formConsulta.fecha_fin} min={formConsulta.fecha_ini} max={fechoy} type="date" name="fecha_fin" placeholder="" onChange={cambioEnFecha} />
                      </Col>
                    </Form.Group>

                  </Form>
                  </Col>
        </Row>
        <Row>
                          {/******************** COLUMNA INICIAL ***********************/}
                          <Col xs={2} md={1}></Col>
                          {/******************** BOTON BUSCAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => get_ensayados()} >Buscar</Button>
                          </Col>

                          {/******************** BOTON LIMPIAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => limpia() } >Limpiar</Button>
                          </Col>
                         
        </Row>
        <hr />
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>
          <Col xs={8} md={4}>
          {mensajeBuscar?
          <p><span className = "comentarios">{mensajeBuscar}</span></p>:null}
          </Col>
        </Row>
        {datosTabla?
          <div className='contenedor-tabla'>
            <Table columns={columnas} data={datosTabla} token={props.token} /></div>
        :null}
        </>
    )
};

export default Informe02;