import React, { useState } from 'react';
import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import axios from 'axios';
import {Table as TablaBoot, Button, input, Row, Col} from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Nav from 'react-bootstrap/Nav';
import {ArrowDownUp} from 'react-bootstrap-icons';
import {SortAlphaDown} from 'react-bootstrap-icons';
import {SortAlphaUp} from 'react-bootstrap-icons';
import {Search} from 'react-bootstrap-icons';
import {PencilSquare} from 'react-bootstrap-icons';
import {Trash} from 'react-bootstrap-icons';
import {FilePdf} from 'react-bootstrap-icons';
import {Eye} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import {InputGroup} from 'react-bootstrap'
import fileDownload from 'js-file-download'
import EditEppGuante from './EditEppGuante';
import EditTest from '../testcomponentes/EditTest';
import {Buffer} from 'buffer';
import { Document, Page } from 'react-pdf';
import SinglePagePDFViewer from '../componentes/pdf/single-page.js';
import { sampleBase64pdf } from '../componentes/pdf/sampleBase64pdf.js';
import useToken from './UseToken';
import EditarEnsayo from './edit/EditarEnsayo';
import EditarDetalleEnsayo from './edit/EditarDetalleEnsayo';
import AddNegocio from './AddNegocio';
import AddCliente from './AddCliente';
import TestModal from '../testcomponentes/TestModal';
import InfoModal from '../testcomponentes/InfoModal';
import AddGuante from './AddGuante';
import AddEppGuante from './AddEppGuante';
import AddEnsayo from './AddEnsayo';
import DelCliente from './DelCliente';
import DelEpp from './DelEpp';
import EditEnsayo from './EditEnsayo';
import '../estilos/Tablas.css';
import RevierteEmitido from './RevierteEmitido';

const Styles = styled.div `
  table {
    width: 90%;
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 5px;
      padding: 1rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
`

function arregloAJson(s) {
  let g = s.map((cell) => {return [cell.column.id, cell.value];});
  //console.log('g: ', g);
  const z = {};
  function mifunc(valor) {
    z[valor[0]]=valor[1];
  }
  g.forEach(mifunc);
  //console.log('z: ', z);
  return z;
};
function bajarArchivo (archivo, setPdfBase64, token) {
  //console.log(archivo);
  
  axios.request({
    url: "/api/getpdf",
    //url: "/api/testpdf",  //para test
    method: "POST",
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + token
    },
    data: {'ensayopdf': archivo, 'verpng': false,}
}).then((res) => {
  //console.log('archivo-pdf: ', res.data);
  fileDownload(res.data, archivo + ".pdf");

  //setPdfBase64(res.data)
  //fileDownload(res.data, archivo + ".png");
  //const urlBlob = URL.createObjectURL(res.data);
  //console.log(urlBlob);

  //setPdfBase64(urlBlob);
}).catch(function (error) {
    //console.log(error);
}); 
}


function SetBoton (props) {
  //console.log('nombre tabla en SetBoton: ', props.nombre_tabla);
  const nombreTabla = props.nombre;
  const nmbresEnsayos = [
  "LAT-GNT",
  "LAT-LDB",
  "LAT-CBL",
  "LAT-MNG",
  "LAT-MNT",
  "LAT-BNQ",
  "LAT-ATR",
  "LAT-PRT",
  "LAT-CBP",
  "LAT-JMP",
  "LAT-ATA",
  "LAT-TPE",
  "LAT-CAM"
];
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ]
  if (nombreTabla == "guante" || nombreTabla == "Guante") {
    return <AddGuante />
  }else if (nombreTabla == "negocio") {
    return <AddNegocio />
  }else if (inventarioEpp.indexOf(nombreTabla) >= 0) {
    //console.log('Nombre en inventarioEpp: ', nombreTabla);
    return <AddEppGuante setRuta={props.setRuta} tipo_epp={nombreTabla} titulo={props.titulo} nombre_tabla={props.nombre_tabla} />
  }else if (nmbresEnsayos.indexOf(nombreTabla) >= 0) {
    return <AddEnsayo cod_ensayo={nombreTabla.slice(4)} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} titulo={props.titulo}  />
  }else if (nombreTabla == "cliList") {
    return <AddCliente />
  }else {
    return <></> 
  }
};

async function bajarXlsListado (nombre_tabla, linea_tabla, token) {
  const nombreTabla = nombre_tabla;
  const nmbresEnsayos = [
  "LAT-GNT",
  "LAT-LDB",
  "LAT-CBL",
  "LAT-MNG",
  "LAT-MNT",
  "LAT-BNQ",
  "LAT-ATR",
  "LAT-PRT",
  "LAT-CBP",
  "LAT-JMP",
  "LAT-ATA",
  "LAT-TPE",
  "LAT-CAM"
];
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ];
  let nombre_codigo;
  if (inventarioEpp.indexOf(nombreTabla) >= 0) {
    nombre_codigo = linea_tabla.split("/")[1];
  }else if (nmbresEnsayos.indexOf(nombreTabla) >= 0) {
    nombre_codigo = linea_tabla.split("/")[2];
  };
      await axios.request({
        url: "/api/listado_xls",
        method: "POST",
        responseType: 'blob',
        headers: {
          Authorization: 'Bearer ' + token
        },
        data: {'codigo': nombre_codigo, 'tipo': nombreTabla}
    }).then((res) => {
      //console.log('respuesta axios: ', res.data)
      fileDownload(res.data, 'listado_' + nombreTabla  + ".xlsx");
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response.data.msg)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
      });
}

function SetBotonBorra (props) {

  const nombreTabla = props.nombre;
  const celdas = props.celdas;
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ];
  if (nombreTabla == "cliList") {
    return <DelCliente celdas={celdas}/>
  } else if (nombreTabla == "eppguante" || inventarioEpp.indexOf(nombreTabla) >= 0) {
    return <DelEpp celdas={celdas} elemento="Guante" accion="borra" setRuta={props.setRuta} tipo_epp={nombreTabla} titulo={props.titulo} nombre_tabla={props.nombre_tabla} />
  }else {
    return <></>
  }
};
function SetBotonDeBaja (props) {

  //console.log('SetBotonBaja', props)
  const nombreTabla = props.nombre;
  const celdas = props.celdas;
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ];
  if (nombreTabla == "eppguante" || inventarioEpp.indexOf(nombreTabla) >= 0) {
    return <DelEpp celdas={celdas} elemento="Guante" accion="baja" setRuta={props.setRuta} tipo_epp={props.nombreTabla} titulo={props.titulo} nombre_tabla={props.nombre_tabla} />
  }else {
    return <></>
  }
};

function SetBtnEdit (props) {
  //console.log('SetBtnEdit', props)
  const nombreTabla = props.nombre;
  const celdas = props.celdas;
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ];
  if (inventarioEpp.indexOf(nombreTabla) >= 0) {
    //console.log('inventario epp', nombreTabla);
    return <EditEppGuante celdas={celdas} tipo_epp={nombreTabla} setRuta={props.setRuta} titulo={props.titulo} nombre_tabla={props.nombre_tabla} />
  }else {
    return <></>
  }
}
function SetBotonPdf (props) {
  if (props.botonAgregar) {
    if (props.botonAgregar.substring(0,3)=='LAT') {
      return <button onClick={() => {bajarArchivo(props.celdas.codigo, props.setPdfBase64, props.token)}} className="btn btn-outline-primary" size="sm"><FilePdf /></button>;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
function SetBotonRevierte(props){
  //let estado = props.celdas.estado;
  //let codigo = props.celdas.codigo;
  if (props.celdas.estado.indexOf("Emitido") > 0 ) {
    return <RevierteEmitido nombre_tabla={props.nombre_tabla} estado={props.celdas.estado} codigo={props.celdas.codigo} setRuta={props.setRuta} />;
  }else{
    return null;
  }
  
}

function SetBotonEdit (props) {

  console.log('props en SetBotonEdit', props.celdas)
  const nombreTabla = props.nombre;
  const celdas = props.celdas;
  const nmbresEnsayos = [
    "LAT-GNT",
    "LAT-LDB",
    "LAT-CBL",
    "LAT-MNG",
    "LAT-MNT",
    "LAT-BNQ",
    "LAT-ATR",
    "LAT-PRT",
    "LAT-CBP",
    "LAT-JMP",
    "LAT-ATA",
    "LAT-TPE",
    "LAT-CAM"
  ];
  //console.log('nombre en SetBotonEdit: ', props.nombre);
  if (nmbresEnsayos.indexOf(nombreTabla) >= 0) {
    //console.log('cledas: ', celdas);
    if (celdas.estado == 'Cert. Emitido') {
      return <></>
    } else {
      //return <EditEnsayo celdas={celdas} titulo={props.titulo}  cod_epp={nombreTabla.slice(4)} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} />
      if (props.detalle) {
        return <EditarEnsayo celdas={celdas} titulo={props.titulo}  cod_epp={nombreTabla.slice(4)} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} />
      }else {
        return <EditarDetalleEnsayo data={props.data} celdas={celdas} cod_epp={nombreTabla.slice(4)} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} />
      }
    }
  }else {
    return <></>
  }
};

function VerInforme (props) {
  return <button className="btn btn-outline-primary" size="sm"><Eye /></button>
}

function Table({columns, data, titulo, botonAgregar, cambiaLinea, lineaTabla, setRuta, nombre_tabla, rutaBuscar}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy, usePagination
  )

  const [botonEdicion, setBotonEdicion] = useState(true);
  const [textoBuscar, setTextoBuscar] = useState();
  const [textoBuscarEpp, setTextoBuscarEpp] = useState();
  const [pdfBase64, setPdfBase64] = useState();
  const {token} = useToken();
  const inventarioEpp = [
    "GNT",
    "MNG",
    "MNT",
    "CBL",
    "LDB",
    "BNQ",
    "ATR",
    "PRT",
    "CBP",
    "JMP",
    "ATA",
    "TPE",
    "CAM"
  ]; 

  function cambioBuscar (event) {
    const {value, name} = event.target;
    //console.log(value);
    setTextoBuscar(value);
  };
  function cambioBuscarEpp (event) {
    const {value, name} = event.target;
    //console.log(value);
    setTextoBuscarEpp(value);
  };
  
  // Render Data Table UI
  return (
    <>
    <div className='header-table-contenedor'>
      <div className='header-contenedor'>
        <nav className="navbar bg-light">
          <div className="container-fluid">
            <a className="navbar-brand">{titulo}</a>
            <nav aria-label="Page navigation example">
              <div className="pagination">
                <Pagination size='lg'  >
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                  <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                  <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                </Pagination>
              </div>
            </nav>
              <Nav.Item>
                <Form.Select size='sm' 
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 15, 20].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </Nav.Item>
          </div>
        </nav>
        <nav className="navbar bg-light">
          <div className="container-fluid">

            {/****************  BUSCADOR DE EPP ***************/}
          {(nombre_tabla && inventarioEpp.indexOf(nombre_tabla.substring(0,3))) >= 0?
            <Row>
                <Col>
                <Form  >
                      <Form.Group controlId="buscaEppbuscaEppbuscaEpp">
                      <InputGroup >
                      <InputGroup.Text >{nombre_tabla.substring(0,3)}-</InputGroup.Text>
                        <Form.Control
                          name="buscar"
                          type="number"
                          placeholder="Serie"
                          value={textoBuscarEpp}
                          onChange={cambioBuscarEpp}
                          /></InputGroup>
                      </Form.Group>
                      </Form>
                </Col>
                <Col>
                    <button id="btnBuscaEpp" 
                      disabled={!textoBuscarEpp} 
                      className="btn btn-outline-primary" 
                      onClick={()=>{setRuta('/guepp/' + nombre_tabla + '/' + nombre_tabla.substring(0,3) + '-' + textoBuscarEpp.padStart(5, 0))}}
                      size="sm">Epp<Search /></button>
                </Col>
            </Row>:null}
            {/****************  BUSCADOR DE ENSAYOS ***************/}
            {nombre_tabla && nombre_tabla.substring(0,3) == 'LAT'?
            <Row>
                <Col>
                <Form  >
                      <Form.Group className="mb-3" controlId="buscaEppEnsayo">
                        <Form.Control
                          name="buscar"
                          type="number"
                          placeholder="Sólo números"
                          value={textoBuscar}
                          onChange={cambioBuscar}
                          />
                      </Form.Group>
                  </Form>
                </Col>
                <Col>
                      <button 
                      id="btnBuscaEnsayo" 
                      disabled={!textoBuscar} 
                      onClick={() => {setRuta('/EnsayoGuantes/'.concat(nombre_tabla,'/', nombre_tabla.substring(0,7),'-', textoBuscar.padStart(5, 0)))}} 
                      className="btn btn-outline-primary" 
                      size="sm" >Buscar ensayo<Search />
                      </button>
                </Col>
                <Col>
                      <button 
                      disabled={!textoBuscar} 
                      onClick={() => {setRuta('/EnsayoGuantes/'.concat(nombre_tabla,'/', nombre_tabla.substring(4,7),'-', textoBuscar.padStart(5, 0)))}} 
                      className="btn btn-outline-primary" 
                      size="sm" >Buscar Epp<Search />
                      </button>
                </Col>
            </Row>:null}     
            {!botonAgregar?<></>:
              <>        
                <button className="btn btn-outline-primary" onClick={()=>bajarXlsListado(botonAgregar, nombre_tabla, token)} >Exportar lista a Excel</button>     
                <SetBoton nombre={botonAgregar} setRuta= {setRuta} titulo={titulo} nombre_tabla={nombre_tabla} />
              </>
              }
          </div>
        </nav>
      </div>
    </div>
    <div className='app-contenedor'>
    <div className='contenedor-tabla'>
       <TablaBoot striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <SortAlphaDown />
                      : <SortAlphaUp />
                    : <ArrowDownUp />}
                </span>
              </th>
            ))}
            <th></th>
          </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            //cambiaLinea(lineaTabla + 1);
            prepareRow(row)
            //console.log('ROW: ', i);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  //console.log(cell)
                  //console.log(cell.column.Header);
                  //console.log(cell.value);

                  //cambiaLinea(cell.column.Header, cell.value);
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td>
                  {/*console.log(lineaTabla)*/}
                  {/*cambiaLinea(lineaTabla + 1)*/}
                  {/*console.log('g: ', row.cells.map((cell) => {return cell.column.id;}))*/}
                  {!botonEdicion?<></>:
                  <>            
                    <SetBotonEdit setRuta= {setRuta} nombre={botonAgregar} celdas={arregloAJson(row.cells)} onBotonEditar={setBotonEdicion} nombre_tabla={nombre_tabla} data={data[i]} detalle={false} />
                    <SetBotonEdit setRuta= {setRuta} nombre={botonAgregar} celdas={arregloAJson(row.cells)} onBotonEditar={setBotonEdicion} nombre_tabla={nombre_tabla} data={data[i]} detalle={true} />
                  </>
                  }
                  <SetBotonBorra nombre={botonAgregar} nombre_tabla={nombre_tabla} setRuta= {setRuta}  celdas={arregloAJson(row.cells)}/>
                  <SetBotonDeBaja nombre={botonAgregar} nombre_tabla={nombre_tabla} setRuta= {setRuta}  celdas={arregloAJson(row.cells)}/>
                  <SetBtnEdit nombre={botonAgregar} nombre_tabla={nombre_tabla} setRuta= {setRuta}  celdas={arregloAJson(row.cells)}  />
                  <SetBotonPdf botonAgregar={botonAgregar} celdas={arregloAJson(row.cells)} setPdfBase64={setPdfBase64} token={token} />
                  <SetBotonRevierte botonAgregar={botonAgregar} celdas={arregloAJson(row.cells)} setRuta= {setRuta} token={token} nombre_tabla={nombre_tabla}  />
                </td>
              </tr>
            )
          })}
        </tbody>
      </TablaBoot>
      </div>
      </div>
      {pdfBase64?<><span className='comentarios'>IMAGEN</span>
      <img src={pdfBase64} /></>
      :null}
      {/*<h4>Base 64 Single Page</h4>
      <SinglePagePDFViewer pdf={pdfBase64} /> */}
      
    </>
  )
}


function Tablas(props) {
  //console.log('listado: ', props.listado());

  const [lineaTabla, setLineaTabla] = useState(0);
  const {datos, columnas, titulo, botonAgregar, nombre_tabla} = props.listado(props.parametro);
  //console.log('Tablas - datos: ', datos);
  //console.log('Tablas - columnas: ', columnas);
  //console.log('Tablas - titulo: ', titulo);
  console.log('Tablas - botonAgregar: ', botonAgregar);
  //console.log('Tablas - nombre_tabla: ', nombre_tabla);
  return (
    <>
    {!columnas?<></>:<Table data={datos} columns={columnas} titulo={titulo} botonAgregar={botonAgregar} cambiaLinea={setLineaTabla} lineaTabla={lineaTabla} setRuta={props.setRuta} nombre_tabla={nombre_tabla} rutaBuscar={props.parametro} />}
    </>

  )
}
export default Tablas;