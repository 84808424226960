import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Tablas from "./Tablas";
import Vencimiento10 from '../data/Vencimiento10';
import Vencimiento90 from '../data/Vencimiento90';
import GestionaEnsayos from '../componentes/acciones/GestionaEnsayos';
import Negocio from '../componentes/acciones/Negocio';
import GestorEpp from '../componentes/acciones/GestorEpp';
import AltasEpp from '../componentes/acciones/AltasEpp';
import Cliente from '../componentes/acciones/Cliente';
import MenuInventario from "../data/MenuInventario";
import TestModal from "../testcomponentes/TestModal";
import TiposGenerales from "../data/TiposGenerales";
import GestorParametros from "./acciones/GestorParametros";
import IngresoClientes from "./clientes/IngresoClientes";
import Informe01 from "./clientes/Informe01";
import Informe02 from "./clientes/Informe02";
import Informe03 from "./clientes/Informe03";
import Informe04 from "./clientes/Informe04";
import Bienvenida from "./Bienvenida";
import AddInstrumento from "./AddInstrumento";

function Inicio (props) {

  //console.log('entra inicio: props.perfil', props.perfil);
    const [ruta, setRuta] = useState('');
    //const {dataMenu} = MenuInventario();
    //console.log('ejecuta inicia');

    useEffect(() => {
        //console.log('useEffect de Inicio: ', ruta);
        SetPantalla();
      }, [ruta]);

    function SetPantalla () {
        //const nombreTabla = props.nombre;
        //console.log('SetPantalla: ', ruta);

        if (ruta == "") {
          //return <><Tablas listado={Vencimiento10} /><Tablas listado={Vencimiento90} /></>
          return <><Bienvenida/></>
        }else if (ruta.substring(0,14) == "/EnsayoGuantes" || ruta.substring(0,22) == "/refrescaEnsayoGuantes") {
          return <><GestionaEnsayos parametro={ruta.substr(ruta.indexOf("/",1) +1)} clase_epp={"Guantes"} setRuta={setRuta} /></>
        }else if (ruta.substring(0,6) == "/guepp" || ruta.substring(0,14) == "/gueppRefresca") {
          return <><AltasEpp parametro={ruta.substr(ruta.indexOf("/",1) +1)} setRuta={setRuta} /></>
        }else if (ruta == "/negocio") {
          return <><Negocio /></>
        }else if (ruta == "/cliente") {
          return <><Cliente /></>
        }else if (ruta.substring(0,6) == "/param" || ruta.substring(0,14) == "/paramrefresca") {
          return <><GestorParametros parametro={ruta.substr(ruta.indexOf("/",1))} setRuta={setRuta} /></>
        }else if (ruta.substring(0,7) == "/gesepp" || ruta.substring(0,15) == "/gesepprefresca") {
          return <><GestorEpp tipo_epp={"Guantes"} parametro={ruta.substr(ruta.indexOf("/",1) +1)} setRuta={setRuta} /></>
        }else if (ruta == "/informe01") {
          return <><Informe01 email={props.email} token={props.token} perfil={props.perfil} mostrarCabecera = {false} /></>
        }else if (ruta == "/informe02") {
          return <><Informe02 email={props.email} token={props.token} perfil={props.perfil} mostrarCabecera = {false} /></>
        }else if (ruta == "/informe03") {
          return <><Informe03 email={props.email} token={props.token} perfil={props.perfil} mostrarCabecera = {false} /></>
        }else if (ruta == "/informe04") {
          return <><Informe04 email={props.email} token={props.token} perfil={props.perfil} mostrarCabecera = {false} /></>
        }else if (ruta == "/instrumentos") {
          return <><AddInstrumento email={props.email} token={props.token} perfil={props.perfil} mostrarCabecera = {false} /></>
        }else {
          return <></>
        }
    };

    return (
<>      
        {props.perfil!=='cliente'?<>
        <Header removeToken={props.removeToken} email={props.email} setRuta={setRuta} menuInv={props.menuInv} menuEns={props.menuEns} perfil={props.perfil} />
            <SetPantalla />
        <Footer /></>:<IngresoClientes removeToken={props.removeToken} email={props.email} token={props.token} perfil={props.perfil} />
        }
</>
    );
};

export default Inicio;