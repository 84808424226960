import React, { useState } from 'react';
import styled from 'styled-components'
import { useTable, usePagination, useSortBy } from 'react-table'
import axios from 'axios';
import {Table as TablaBoot, Button, input, Row, Col} from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import Nav from 'react-bootstrap/Nav';
import {ArrowDownUp} from 'react-bootstrap-icons';
import {SortAlphaDown} from 'react-bootstrap-icons';
import {SortAlphaUp} from 'react-bootstrap-icons';
import {Search} from 'react-bootstrap-icons';
import {PencilSquare} from 'react-bootstrap-icons';
import {Trash} from 'react-bootstrap-icons';
import {FilePdf} from 'react-bootstrap-icons';
import Form from 'react-bootstrap/Form';
import '../estilos/Tablas.css';
import AddNegocio from './AddNegocio';
import AddCliente from './AddCliente';
import TestModal from '../testcomponentes/TestModal';
import InfoModal from '../testcomponentes/InfoModal';
import AddGuante from './AddGuante';
import AddEppGuante from './AddEppGuante';
import AddEnsayo from './AddEnsayo';
import DelCliente from './DelCliente';
import DelEpp from './DelEpp';
import EditEnsayo from './EditEnsayo';
import fileDownload from 'js-file-download'
import AddTipoEpp from './AddTipoEpp';
import AddTipoParam from './AddTipoParam';
import DelParam from './DelParam';
import EditTipoParam from './EditTipoParam';
import EditParamTest from './EditParamTest';
import DelTipoEpp from './DelTipoEpp';

const Styles = styled.div `
  table {
    width: 90%;
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 5px;
      padding: 1rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
    }
  }
`
function arregloAJson(s) {
  let g = s.map((cell) => {return [cell.column.id, cell.value];});
  //console.log('g: ', g);
  const z = {};
  function mifunc(valor) {
    z[valor[0]]=valor[1];
  }
  g.forEach(mifunc);
  //console.log('z: ', z);
  return z;
};
function bajarArchivo (archivo) {
  //console.log(archivo);

  axios.request({
    url: "/api/getpdf",
    method: "POST",
    responseType: 'blob',
    data: {"ensayopdf": archivo}
}).then((res) => {fileDownload(res.data, archivo + ".pdf")
}).catch(function (error) {
    //console.log(error);
}); 
}


function SetBoton (props) {

  const nombresElemtos = [
    "Guante", "Manguilla", "Manta", "Cubrelinea", 
    "Banqueta", "Loadbuster", "Aterramiento", "Pertiga", "Jumper", "Cubreposte", "Ecm", "Camion_hidro", 
    "At_arana", "Tierra_pre_ensamblada", "Camion_hidroelevador"];
  const parametrosConf = [
    "cliente", 
    "tecnico", 
    "patron", 
    "marca", 
    "cuerpos_aterramiento", 
    "cuerpos_pertiga",
    "largo_guante",
    "modelo_cubrelinea",
    "largo_manta",
    "largo_cubreposte",
    "negocio",
    "usuario",
    "modelo_aterramiento",
    "modelo_pertiga"
  ]
  //console.log('nombre tabla en SetBoton: ', props.nombre_tabla);
  const nombreTabla = props.nombre;
  //console.log('SetBoton props.nombre: ', props.nombre);
  //console.log('SetBoton props.titulo: ', props.titulo);
  if (nombresElemtos.indexOf(nombreTabla) >= 0) {
    return <AddTipoEpp nombre_tabla={props.nombre_tabla} columnas={props.columnas} setRuta={props.setRuta}  />
  }else if (nombreTabla == "negocio1") {
    return <AddNegocio />
  }else if (nombreTabla == "GNT" || nombreTabla == "MNG" || nombreTabla == "MNT" || nombreTabla == "CBL" || nombreTabla == "ATR") {
    return <AddEppGuante setRuta={props.setRuta} tipo_epp={nombreTabla} titulo={props.titulo} nombre_tabla={props.nombre_tabla} />
  }else if (nombreTabla == "ensayoguante" || nombreTabla == "LAT-GNT" || nombreTabla == "LAT-MNG") {
    return <AddEnsayo cod_ensayo={nombreTabla.slice(4)} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} titulo={props.titulo}  />
  }else if (nombreTabla == "cliList") {
    return <AddCliente />
  }else if (parametrosConf.indexOf(nombreTabla) >= 0) {
    return <AddTipoParam nombre_tabla={props.nombre_tabla} columnas={props.columnas} setRuta={props.setRuta} />
  }
  else {
    return <></> 
  }
};


function SetBotonBorra (props) {

  //console.log('SetBotonBorra: ', props);
  const nombreTabla = props.nombre;
  const celdas = props.celdas;
  const nombresElementos = [
    "Guante", "Manguilla", "Manta", "Cubrelinea", 
    "Banqueta", "Loadbuster", "Aterramiento", "Pertiga", "Jumper", "Cubreposte", "Ecm", "Camion_hidro", 
    "At_arana", "Tierra_pre_ensamblada"];

  const parametrosConf = [
    "cliente", 
    "tecnico", 
    "patron", 
    "marca", 
    "cuerpos_aterramiento", 
    "cuerpos_pertiga", 
    "largo_guante",
    "modelo_cubrelinea",
    "largo_manta",
    "largo_cubreposte",
    "negocio",
    "usuario",
    "modelo_aterramiento",
    "modelo_pertiga"
  ];
  if (nombreTabla == "cliList") {
    return <DelCliente celdas={celdas}/>
  } else if (nombreTabla == "eppguante") {
    return <DelEpp celdas={celdas} elemento="Guante" />
  } else if (parametrosConf.indexOf(nombreTabla) >= 0) {
    return <DelParam celdas={celdas} nombreTabla={nombreTabla} nombre_tabla={props.nombre_tabla} setRuta={props.setRuta} />
  }else if (nombresElementos.indexOf(nombreTabla) >= 0) {
    return <DelTipoEpp nombre_tabla={props.nombre_tabla} celdas={celdas} setRuta={props.setRuta} />
  }else {
    return <></>
  }
};
function SetBotonEdit (props) {

  const nombreTabla = props.nombre;
  const celdas = props.celdas;
  //console.log('nombre tabla en SetBotonEdit: ', props.nombre_tabla);
  if (nombreTabla == "ensayoguante" || nombreTabla == "LAT-GNT" || nombreTabla == "LAT-MNG") {
    //console.log('cledas: ', celdas);
    if (celdas.estado == 'Cert. Emitido') {
      return <></>
    } else {
      return <EditEnsayo celdas={celdas} titulo={props.titulo}  cod_epp={nombreTabla.slice(4)} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} />
    }
  }else {
    return <></>
  }
};
function SetBotonEditGeneral (props) {
  const parametrosConf = [
    "cliente", 
    "tecnico", 
    "patron", 
    "marca", 
    "cuerpos_aterramiento", 
    "cuerpos_pertiga", 
    "largo_guante",
    "modelo_cubrelinea",
    "largo_manta",
    "largo_cubreposte",
    "negocio",
    "usuario",
    "modelo_aterramiento",
    "modelo_pertiga"
  ];
  const nombreTabla = props.nombre;
  if (parametrosConf.indexOf(nombreTabla) >= 0) {
    //return <Button ><PencilSquare /></Button>;
    return <EditParamTest nombre_tabla={props.nombre_tabla} celdas={props.celdas} columnas={props.columnas} setRuta={props.setRuta} />
  }
}

function Table({columns, data, titulo, botonAgregar, setRuta, nombre_tabla}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy, usePagination
  )

  const [botonEdicion, setBotonEdicion] = useState(true);
  const [textoBuscar, setTextoBuscar] = useState();
  const [listaCheck, setListaCheck] = useState([])

  function ControlCheck (props){

    //console.log('props.celdas', props.celdas, props.nombre_tabla.substring(1,6));
    const datosNew = [{[props.celdas.codigo]: false}, ...listaCheck]
    setListaCheck(datosNew)
    if (props.nombre_tabla.substring(1,6)=='lista'){
      return <Form.Check   type="checkbox"  >
              <Form.Check.Input name={props.celdas.codigo} type="checkbox" isValid  />
              <Form.Check.Label>.</Form.Check.Label>
              </Form.Check>
    }else{
      return null;
    }
  }

  function cambioBuscar (event) {
    const {value, name} = event.target;
    //console.log(value);
    setTextoBuscar(value);
  };
  // Render Data Table UI
  return (
    <>
    <div className='header-table-contenedor'>
      <div className='header-contenedor'>
        <nav className="navbar bg-light">
          <div className="container-fluid">
            <a className="navbar-brand">{titulo}</a>
            <nav aria-label="Page navigation example">
              <div className="pagination">
                <Pagination size='lg'  >
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                  <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                  <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                </Pagination>
              </div>
            </nav>
              <Nav.Item>
                <Form.Select size='sm' 
                  value={pageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[10, 15, 20].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Mostrar {pageSize}
                    </option>
                  ))}
                </Form.Select>
              </Nav.Item>
          </div>
        </nav>
        <nav className="navbar bg-light">
          <div className="container-fluid">
          <Row >
                    <Col xs={12} md={8}>
                        
                    </Col>
                    <Col xs={6} md={4}>
                      {nombre_tabla && nombre_tabla.substring(0,3) == 'LAT'?
                      <button onClick={() => console.log('/EnsayoGuantes/'.concat(nombre_tabla,'/', nombre_tabla.substring(0,7),'-', textoBuscar.padStart(5, 0)))} className="btn btn-outline-primary" size="sm" ><Search /></button>:null}

                    </Col>
            </Row>   
            {!botonAgregar?<></>:
            <>            
              <SetBoton nombre={botonAgregar} setRuta= {setRuta} titulo={titulo} nombre_tabla={nombre_tabla} columnas={columns} />
            </>
            }
          </div>
        </nav>
      </div>
    </div>
    <div className='app-contenedor'>
    <div className='contenedor-tabla'>
       <TablaBoot striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {column.isSorted
                    ? column.isSortedDesc
                      ? <SortAlphaDown />
                      : <SortAlphaUp />
                    : <ArrowDownUp />}
                </span>
              </th>
            ))}
            <th>
              {nombre_tabla.substring(1,6)=='lista'?
              <Form.Check   type="checkbox"  >
                <Form.Check.Input name="todos" type="checkbox" isValid  />
                <Form.Check.Label>.</Form.Check.Label>
              </Form.Check>:null}
            </th>
          </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            //cambiaLinea(lineaTabla + 1);
            prepareRow(row)
            //console.log('ROW: ', i);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  //console.log(cell)
                  //console.log(cell.column.Header);
                  //console.log(cell.value);

                  //cambiaLinea(cell.column.Header, cell.value);
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                <td>
                  {/*console.log(lineaTabla)*/}
                  {/*cambiaLinea(lineaTabla + 1)*/}
                  {/*console.log('g: ', row.cells.map((cell) => {return cell.column.id;}))*/}
                  {!botonEdicion?<></>:
                  <>            
                    <SetBotonEdit nombre={botonAgregar} celdas={arregloAJson(row.cells)} onBotonEditar={setBotonEdicion} nombre_tabla={nombre_tabla} />
                  </>
                  }
                  <SetBotonBorra nombre={botonAgregar} celdas={arregloAJson(row.cells)} nombre_tabla={nombre_tabla} setRuta= {setRuta} />
                  <SetBotonEditGeneral nombre={botonAgregar} celdas={arregloAJson(row.cells)} nombre_tabla={nombre_tabla} setRuta= {setRuta} columnas={columns}/>
                  {/*<ControlCheck celdas={arregloAJson(row.cells)} nombre_tabla={nombre_tabla}  />*/}
                </td>
              </tr>
            )
          })}
        </tbody>
      </TablaBoot>
      </div>
      </div>  
    </>
  )
}


function TablasForm(props) {
  //console.log('listado: ', props.listado());

  //const {datos, columnas, titulo, botonAgregar, nombre_tabla} = props.listado(props.parametro);

  /*
  console.log('TablasForm - datos: ', props.datos);
  console.log('TablasForm - columnas: ', props.columnas);
  console.log('TablasForm - titulo: ', props.titulo);
  console.log('TablasForm - botonAgregar: ', props.botonAgregar);
  console.log('TablasForm - nombre_tabla: ', props.nombre_tabla);
  */
  return (

      <Table data={props.datos} columns={props.columnas} titulo={props.titulo} botonAgregar={props.botonAgregar} setRuta={props.setRuta} nombre_tabla={props.nombre_tabla} />

  )
}
export default TablasForm;