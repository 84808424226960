import React from "react"
import {useState} from 'react';
import {Button, Modal, Form, Dropdown, ButtonGroup, Container, Row, Col, Badge, InputGroup} from 'react-bootstrap';
import axios from 'axios';
import SelectParam from "./selectores/SelectParam";
import '../estilos/AddNegocio.css';

function AddEppGuante (props) {
  //console.log('Tipo epp eb AddEppGuante', props.tipo_epp)
  const rutaCompleta = props.nombre_tabla;
  const [show, setShow] = useState(false);
  const [muestraNuevoepp, setMuestraNuevoepp] = useState(false);
  const [codigoEpp, setCodigoEpp] = useState('');
  const [formGuante, setFormGuante] = useState({
    'cod_epp': '',
    'tipo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'estado': '0',
    'periodicidad': '6',
    'estado_uso': '2',
    'serie_fabrica': '000',
    'tipo_epp': props.tipo_epp,
    'serie_elevador': '',
    'patente': '',
    'modelo': '',
    'year': '',
  });
  const [errorForm, setErrorForm] = useState({
    'cod_epp': '',
    'tipo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'periodicidad': '',
    'estado_uso': '',
    'serie_fabrica': '',
    'serie_elevador': '',
    'patente': '',
    'modelo': '',
    'year': '',
  });

  function handleClose() {
    setMuestraNuevoepp(false);
     setShow(false);
    }
  //const handleShow = () => setShow(true);

  function cambiaValorepp (event) {
    const {checked, name} = event.target;
    if (checked) {
      setCodigoEpp('');
      setFormGuante(prevNote => ({...prevNote, ['cod_epp']: ''}))
      setMuestraNuevoepp(checked);
    }else {
      setMuestraNuevoepp(checked);
    }
  }

  async function handleShow () {
    setFormGuante({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'estado': '0',
      'periodicidad': '6',
      'estado_uso': '2',
      'serie_fabrica': '000',
      'tipo_epp': props.tipo_epp,
      'serie_elevador': '',
      'patente': '',
      'modelo': '',
      'year': '',
    });
    setErrorForm({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'periodicidad': '',
      'serie_fabrica': '',
      'estado_uso': '',
      'serie_elevador': '',
      'patente': '',
      'modelo': '',
      'year': '',
      
    });
    await axios({
      method: "POST",
      url:"/api/getcodepp",
      data: {"epp": props.tipo_epp}
    })
    .then((response) => {
      //console.log('axios: ', response.data.datos);
      setCodigoEpp(response.data.datos);
      setFormGuante(prevNote => ({...prevNote, ['cod_epp']: response.data.datos}))
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    });
    
    setShow(true)
  }
  function cambioEnForm (event) {
    const {value, name} = event.target;
    if (name == 'cod_epp'){
      setFormGuante(prevNote => ({...prevNote, [name]: props.tipo_epp.concat('-', value.padStart(5, 0))}))
    }else {
      setFormGuante(prevNote => ({...prevNote, [name]: value}))
    }
    //console.log('cambio en form')
  }
  async function handCloseGraba () {
    //console.log('formato: ', formGuante);
    setErrorForm({
      'cod_epp': '',
      'tipo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'periodicidad': '',
      'serie_fabrica': '',
      'estado_uso': '',
      'serie_elevador': '',
      'patente': '',
      'modelo': '',
      'year': '',
    });
    let hayError = false;
    if (!formGuante.cod_epp) {
      //console.log('valor epp: ', formGuante.cod_epp);
      setErrorForm(prevNote => ({...prevNote, ["cod_epp"]: "Código de epp no definido"}))
      hayError = true;
    }
    if (!formGuante.tipo) {
      setErrorForm(prevNote => ({...prevNote, ["tipo"]: "Seleccione Tipo"}))
      hayError = true;
    }
    if (!formGuante.cliente) {
      setErrorForm(prevNote => ({...prevNote, ["cliente"]: "Seleccione Ciente"}))
      hayError = true;
    }
    if (!formGuante.sucursal) {
      setErrorForm(prevNote => ({...prevNote, ["sucursal"]: "Seleccione Sucursal"}))
      hayError = true;
    }
    if (!formGuante.negocio) {
      setErrorForm(prevNote => ({...prevNote, ["negocio"]: "Seleccione Negocio"}))
      hayError = true;
    }
    if (!formGuante.periodicidad) {
      setErrorForm(prevNote => ({...prevNote, ["periodicidad"]: "Seleccione Periodicidad"}))
      hayError = true;
    }
    if (!formGuante.estado_uso) {
      setErrorForm(prevNote => ({...prevNote, ["estado_uso"]: "Seleccione Estado Uso"}))
      hayError = true;
    }
    //console.log('funcion handCloseGraba', hayError)
    if (!hayError) {
      //axios
      //console.log('form guante: ', JSON.stringify(formGuante));
      await axios({
        method: "POST",
        url:"/api/grabaepp",
        data: formGuante
      })
      .then((response) => {
        //console.log('axios: ', response.data.msg);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      //window.location.reload(false);
      const aleatorio = Math.floor(Math.random()*1000).toString()
      setShow(false); //cierra la ventana popup
      //console.log('Concatenacion : ', '/gueppRefresca'.concat('_',aleatorio,'/',rutaCompleta))
      props.setRuta('/gueppRefresca'.concat('_',aleatorio,'/',rutaCompleta));
    }
  }
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {"+Agregar ".concat(props.titulo)}
      </Button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title><h3>{"Agregar ".concat(props.titulo)}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Form>
          <Row>
            <Col>
            {!muestraNuevoepp?
              <h4>
              <Badge bg="secondary">{codigoEpp}</Badge>
              </h4>:null}
            </Col>
            <Col>
              <Form.Check  value="AR1" type="checkbox" id="otrovalor" >
              <Form.Check.Input name="otro" type="checkbox" isValid onChange={cambiaValorepp} />
              <Form.Check.Label>Usar otro valor para el epp</Form.Check.Label>
              </Form.Check>
            </Col>
            <Col>
            {muestraNuevoepp?
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Valor nuevo</span></Form.Label>
              <InputGroup >
              <InputGroup.Text >{props.tipo_epp}-</InputGroup.Text>
              <Form.Control
                name="cod_epp"
                type="number"
                placeholder="Valor"
                onChange={cambioEnForm}
                min={0}
                
                /></InputGroup>
                <p><span className = "mensaje-error">{errorForm?.cod_epp}</span></p>
              </Form.Group>:null  
              }
            </Col>
          </Row>
        <Container>
            <Row>
                <Col xs={12} md={8}>
                    <Form.Group className="mb-3" controlId="tipo">
                    <Form.Label><span className="campo">Seleccione Tipo</span></Form.Label>
                        <Form.Select aria-label="Default select example" name="tipo" onChange={cambioEnForm}>
                            <SelectParam parametro={props.nombre_tabla.substr(props.nombre_tabla.lastIndexOf("/")+1)} />
                        </Form.Select>
                        <p><span className = "mensaje-error">{errorForm?.tipo}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>

                </Col>
            </Row>
            <hr />
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Cliente</span></Form.Label>
                      <Form.Select aria-label="Default select example" name="cliente" onChange={cambioEnForm}>
                          <SelectParam parametro="cliente" />
                      </Form.Select>
                      <p><span className = "mensaje-error">{errorForm?.cliente}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Sucursal</span></Form.Label>
                      <Form.Select aria-label="Default select example" name="sucursal" onChange={cambioEnForm}>
                          <SelectParam parametro="sucursal" />
                      </Form.Select>
                      <p><span className = "mensaje-error">{errorForm?.sucursal}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Negocio</span></Form.Label>
                      <Form.Select aria-label="Default select example" name="negocio" onChange={cambioEnForm}>
                        <SelectParam parametro="negocio" />
                      </Form.Select>
                      <p><span className = "mensaje-error">{errorForm?.negocio}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Periodicidad</span></Form.Label>
                      <Form.Select aria-label="Default select example" name="periodicidad" onChange={cambioEnForm}>
                          <SelectParam parametro="periodicidad" />
                      </Form.Select>
                      <p><span className = "mensaje-error">{errorForm?.cliente}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">Estado Uso</span></Form.Label>
                      <Form.Select aria-label="Default select example" name="estado_uso" onChange={cambioEnForm}>
                          <SelectParam parametro="estado_uso" />
                      </Form.Select>
                      <p><span className = "mensaje-error">{errorForm?.sucursal}</span></p>
                    </Form.Group>
                </Col>
                {props.tipo_epp=='LDB'?
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label><span className="campo">Serie de Fábrica</span></Form.Label>
                  <Form.Control
                    value={formGuante.serie_fabrica}
                    name="serie_fabrica"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.serie_fabrica}</span></p>
                </Form.Group>
                </Col>:null}
                {props.tipo_epp=='CAM'?
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label><span className="campo">Serie Elevador</span></Form.Label>
                  <Form.Control
                    value={formGuante.serie_elevador}
                    name="serie_elevador"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.serie_elevador}</span></p>
                </Form.Group>
                </Col>:null}
            </Row>
            {props.tipo_epp==='CAM'?
            <Row>
              <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Label><span className="campo">Modelo</span></Form.Label>
                  <Form.Control
                    value={formGuante.modelo}
                    name="modelo"
                    type="text"
                    placeholder="Serie"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.modelo}</span></p>
                </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                <Form.Label><span className="campo">Año</span></Form.Label>
                  <Form.Control
                    value={formGuante.year}
                    name="year"
                    type="text"
                    placeholder="Año"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.year}</span></p>
                </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
                <Form.Label><span className="campo">Patente</span></Form.Label>
                  <Form.Control
                    value={formGuante.patente}
                    name="patente"
                    type="text"
                    placeholder="Patente"
                    onChange={cambioEnForm}
                    autoFocus
                    />
                  <p><span className = "mensaje-error">{errorForm?.patente}</span></p>
                </Form.Group>
                </Col>
            </Row>:null}
        </Container>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddEppGuante;