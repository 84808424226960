import React from "react";
import Ensayos from "../../data/Ensayos";
import Header from "../Header";
import Tablas from "../Tablas";
import TablasForm from "../Tablas";

function GestionaEnsayos (props) {
  /*
  const {datos, columnas, titulo, botonAgregar, nombre_tabla} = Ensayos(props.parametro);
  console.log('GestionaEnsayos - datos: ', datos);
  console.log('GestionaEnsayos - columnas: ', columnas);
  console.log('GestionaEnsayos - titulo: ', titulo);
  console.log('GestionaEnsayos - botonAgregar: ', botonAgregar);
  console.log('GestionaEnsayos - nombre_tabla: ', nombre_tabla);
  */
  return (
    <>
      <Tablas listado={Ensayos} parametro={props.parametro} setRuta={props.setRuta} />
       
    </>
  );
};

export default GestionaEnsayos;