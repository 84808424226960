import React, { useState } from "react";
import { Row, Col, Table as TablaBoot, Card, ListGroup, Button, Form, Navbar,Container, Image, InputGroup , Pagination} from "react-bootstrap";
import {ArrowDownUp, SortAlphaDown, SortAlphaUp} from 'react-bootstrap-icons';
import axios from "axios";
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table'
import fileDownload from 'js-file-download'
import f_logo from '../../imagenes/logo.bmp';
import "@fontsource/roboto";
import './IngresoClientes.css';
import SelectParamTest from "../selectores/SelectParamTest";

/*
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    )
  }
)
*/

function Table({ columns, data, token }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination
    )

  // Render the UI for your table
  return (
    <>
      
      <TablaBoot striped bordered hover {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <SortAlphaDown />
                        : <SortAlphaUp />
                      : <ArrowDownUp />}
                  </span>
                  </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </TablaBoot>
      {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
      <div className="pagination">

        <Row>
          <Col xs={5} md={4} >
                <Pagination size='md'  >
                  <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                  <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                  <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                  <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                </Pagination>
          </Col>
          <Col xs={5} md={4}>
              <span className="letras-negras">
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{' '}
              </span>
          </Col>
          
        <Col xs={5} md={4}>
        <Form.Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </Form.Select>
        </Col>
        </Row>
      </div>
    </>
  )
};

function Informe04 (props) {

  const c = new Date().toLocaleString("es-CL", {timeZone: "America/Santiago"});
  const fechoy = c.substring(6,10) + '-' + c.substring(3,5) + '-' + c.substring(0,2);
  const [formConsulta, setFormConsulta] = useState({
      'elemento': '',
      'fecha_ini': fechoy,
      'fecha_fin': fechoy,
      'cliente': '-1',
      'empresa': '',
  });
  const [nombres, setNombres] = useState({'ensayo':'', 'elemento':''})
  const [errorForm, setErrorForm] = useState();
  const [mensajeImagen, setMensajeImagen] = useState();
  const [codigoEnsayo, setCodigoEnsayo] = useState();
  const [datosTabla, setDatosTabla] = useState();
  const columnas = [
    {
      Header: 'Empresa',
      accessor: 'empresa'
    }, {
      Header: 'Elemento',
      accessor: 'elemento'
    }, {
      Header: 'Marca',
      accessor: 'marca'
    }, {
      Header: 'Clase',
      accessor: 'clase'
    }, {
      Header: 'Fecha Ensayo',
      accessor: 'fecha_ensayo'
    }, {
      Header: 'Informe Ensayo Emitido',
      accessor: 'informe_ensayo'
    }, {
      Header: 'Unidad Bajo Prueba',
      accessor: 'epp_ensayado'
    }, {
      Header: 'Días para Vencer',
      accessor: 'dias_venci'
    }, {
      Header: 'Aprobado',
      accessor: 'aprobado'
    }
  ];

  function cambioEnFecha (event) {
    const {value, name} = event.target;
    if (name === 'fecha_ini') {
      setFormConsulta(prevNote => ({...prevNote, [name]: value, ['fecha_fin']: fechoy}))
  }else {
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
  }
    setErrorForm(null);
    //setMaxFechaIn(value);
    //setValorFechaIn('');
    //console.log(value, name);
  }
  /*
  function cambioEnFechaIn (event) {
    const {value, name} = event.target;
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
    setValorFechaIn(value);
    //console.log(value, name);
  }
  */
//****************************************************************************************** */
//****************************************************************************************** */

  function cambioEnForm (event) {
    //console.log('formConsulta: ', formConsulta)
    const {value, name} = event.target;
    //console.log('cambio form encabezado', name, value);
    setErrorForm(null);
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
  }
  
//****************************************************************************************** */
//****************************************************************************************** */

  function limpia() {
    //setPdfBase64(null);
    //setNombres({'ensayo':'', 'elemento':''});

    let temp = "";
    let mySelect = document.getElementById('TipoElemento');
    for(var i, j = 0; i = mySelect.options[j]; j++) {
        if(i.value == temp) {
            mySelect.selectedIndex = j;
            break;
        }
    }
    temp = "";
    mySelect = document.getElementById('TipoEmpresa');
    for(var i, j = 0; i === mySelect.options[j]; j++) {
        if(i.value === temp) {
            mySelect.selectedIndex = j;
            break;
        }
    }

    setErrorForm(null);
    setDatosTabla(null);

    /*
    setFormConsulta({
      'elemento': '',
      'valorensayo': '',
      'valorelemento': ''
    })*/
    setFormConsulta({
      'elemento': '',
      'fecha_ini': fechoy,
      'fecha_fin': fechoy,
      'cliente': '-1',
      'empresa': '',

    });
    setMensajeImagen(null);
    setCodigoEnsayo(null);
  }
//****************************************************************************************** */
//****************************************************************************************** */

  async function get_ensayados() {
    //console.log('formConsulta: ', formConsulta);
    let formData = {};
    formData['elemento'] = formConsulta['elemento'];
    formData['fecha_ini'] = formConsulta['fecha_ini'];
    formData['fecha_fin'] = formConsulta['fecha_fin'];
    formData['cliente'] = formConsulta['cliente'];
    formData['empresa'] = formConsulta['empresa'];
    if (!formData['empresa']){
      //se deja igual
      formData['cliente'] = formConsulta['cliente'];
    }else {
      //se cambia el cliente por empresa
      formData['cliente'] = formConsulta['empresa'];
    }
    await axios.request({
      url: "/api/eppensayados",
      method: "POST",
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: formData
  }).then((res) => {
    //console.log('respuesta axios: ', res.data)
    setDatosTabla(res.data.datos)
  }).catch((error) => {
    if (error.response) {
      setErrorForm(error.response.data.msg)
      //console.log(error.response.data.msg)
      //console.log(error.response.status)
      //console.log(error.response.headers)
      }
    });
  }

//****************************************************************************************** */
//****************************************************************************************** */

  async function get_xlsx_ensayados() {
    //console.log('formConsulta: ', formConsulta);
    let formData = {};
    formData['elemento'] = formConsulta['elemento'];
    formData['fecha_ini'] = formConsulta['fecha_ini'];
    formData['fecha_fin'] = formConsulta['fecha_fin'];
    formData['cliente'] = formConsulta['cliente'];
    formData['empresa'] = formConsulta['empresa'];
    if (!formData['empresa']){
      //se deja igual
      formData['cliente'] = formConsulta['cliente'];
    }else {
      //se cambia el cliente por empresa
      formData['cliente'] = formConsulta['empresa'];
    }
    await axios.request({
      url: "/api/historial_xls",
      method: "POST",
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: formData
  }).then((res) => {
    //console.log('respuesta axios: ', res.data)
    fileDownload(res.data, formConsulta.fecha_ini + '_' + formConsulta.fecha_fin  + ".xlsx");
  }).catch((error) => {
    if (error.response) {
      //console.log(error.response.data.msg)
      //console.log(error.response.status)
      //console.log(error.response.headers)
      }
    });
  };

//****************************************************************************************** */
//****************************************************************************************** */

  
  return (
    <>
          {props.mostrarCabecera?
          <>
            <Navbar className="bg-white">
              <Container>
                <Navbar.Brand href="#home"><img  src={f_logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras-grande">Laboratorio Ensayo Equipos Dieléctricos</span></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                  <Button onClick={() => {setCodigoEnsayo(null) ;setMensajeImagen(null); setFormConsulta(null); setErrorForm(null); props.setMuestraMenu(true); props.setOpcion1(0)}} >volver</Button>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          <hr />
          </>:null}
        <Row >
          <Col md={{ span: 3, offset: 1 }} >
          <span className="letras-negras">Módulo de Historial de Elementos Ensayados</span>
          </Col>
        </Row>
        <hr />
        <Row>
              {/******************** COLUMNA INICIAL ***********************/}
              <Col xs={2} md={1}></Col>
              <Col xs={12} md={6}>
                      <p><span className = "mensaje-error">{errorForm}</span></p>
              </Col>
                    </Row>
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>

          
          <Col xs={8} md={4}>
                <Form>
                    {props.perfil=='superusuario'?<>
                      <Form.Group as={Row} className="mb-3" controlId="TipoEmpresa">
                            <Form.Label column sm="4">
                            <span className="campo">
                              Empresa
                            </span>
                            </Form.Label>
                            <Col sm="8">
                              <SelectParamTest parametro="empresa" valorDefault={formConsulta.empresa} cambioEnForm={cambioEnForm} />
                            </Col>
                          </Form.Group>
                    </>:null}
                  {/******************** COLUMNA TIPO ELEMENTO ***********************/}
                  <Form.Group as={Row} className="mb-3" controlId="TipoElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Tipo Elemento
                      </span>
                      </Form.Label>
                      <Col sm="8">
                        <SelectParamTest parametro="elemento" valorDefault={formConsulta.tipo} cambioEnForm={cambioEnForm} />
                      </Col>
                    </Form.Group>

                    {/******************** COLUMNA FECHA INICIO ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="FecIni">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Fecha Inicio
                      </span>
                      </Form.Label>
                      <Col sm="8">
                      <Form.Control value={formConsulta.fecha_ini} max={fechoy} type="date" name="fecha_ini" placeholder="" onChange={cambioEnFecha} />
                      </Col>
                      </Form.Group>

                    {/******************** COLUMNA FECHA FIN ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="FecFin">
                      <Form.Label column sm="4">
                        <span className="campo">Fecha Fin</span>
                      </Form.Label>
                      <Col sm="8">
                      <Form.Control value={formConsulta.fecha_fin} min={formConsulta.fecha_ini} max={fechoy} type="date" name="fecha_fin" placeholder="" onChange={cambioEnFecha} />
                      </Col>
                    </Form.Group>

                  </Form>
                  </Col>
        </Row>
        <Row>
                          {/******************** COLUMNA INICIAL ***********************/}
                          <Col xs={2} md={1}></Col>
                          {/******************** BOTON BUSCAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => get_ensayados()} >Buscar</Button>
                          </Col>

                          {/******************** BOTON LIMPIAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => limpia() } >Limpiar</Button>
                          </Col>
                         
        </Row>
        <hr />
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>
          <Col xs={8} md={4}>
          {mensajeImagen?
          <p><span className = "comentarios">{mensajeImagen}</span></p>:null}
          </Col>
        </Row>
        {datosTabla?
        <div className='contenedor-tabla'>
        <Table columns={columnas} data={datosTabla} token={props.token} /></div>
        :null
        }
        {datosTabla?<Button onClick={() => get_xlsx_ensayados()}  >Exportar a Excel</Button>:null}
    </>
  )
};

export default Informe04;