import React from "react";
import axios from 'axios';
import { useEffect, useState } from "react";

function SelectParam (props) {
  //console.log('props de select param: ', props.parametro);

  const encabezado = props.parametro.charAt(0).toUpperCase() + props.parametro.slice(1);

  const [datos, setDatos] = useState([]);

  useEffect(() => {
    //console.log('se ejecuta useeffect SelectParam');
    axios({
      method: "POST",
      url:"/api/selectparam",
      data: {
        "param": props.parametro,
      }
    })
    .then((response) => {
      //console.log('axios: ', response.data.datos);
      setDatos(response.data.datos);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
  }, []);

  return (
    <>
      <option key="0" value="">Seleccione {encabezado}</option>
      {datos.map((dato, idx) => {return <option key={dato.id} value={dato.id}>{dato.nombre}</option>})}
    </>
  )
}

export default SelectParam;