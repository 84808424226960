import React, { useState } from "react";
import { Row, Col, Card, ListGroup, Button, Form, Navbar,Container, Image, InputGroup } from "react-bootstrap";
import axios from "axios";
import fileDownload from 'js-file-download'
import f_logo from '../../imagenes/logo.bmp';
import "@fontsource/roboto";
import './IngresoClientes.css';
import SelectParamTest from "../selectores/SelectParamTest";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

function Informe01 (props) {

  const [pdfBase64, setPdfBase64] = useState();
  const [formConsulta, setFormConsulta] = useState({
    'elemento': '',
    'valorensayo': '',
    'valorelemento': '',
    'empresa': ''
  });
  const [nombres, setNombres] = useState({'ensayo':'', 'elemento':''})
  const [errorForm, setErrorForm] = useState();
  const [mensajeImagen, setMensajeImagen] = useState();
  const [codigoEnsayo, setCodigoEnsayo] = useState();
  
  /*  variables para el pdf   */
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function cambioEnForm (event) {
    //console.log('formConsulta: ', formConsulta)
    const {value, name} = event.target;
    console.log('cambio form encabezado', name, value);
    setErrorForm(null);
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
    if (name=='elemento'){
      if (value!=''){
      setNombres({'ensayo':'LAT-'.concat(value), 'elemento':value});
    }else {
      setNombres({'ensayo':'', 'elemento':''});
    }
    }
  }

  /*
  function pruebaBoton() {
    //console.log()
    let formData = formConsulta;
    let hay = false
    if (nombres.ensayo.substring(0,3)!=='LAT') {
      //debe seleccionar el tipo elemento
      setErrorForm('Debe seleccionar el tipo elemento');
      hay=true;
    }
    if (!hay) {
      if (!(formData.valorensayo||formData.valorelemento)){
        //debe ingresar al menos un criterio de búsqueda
        hay=true;
        setErrorForm('Debe ingresar al menos un criterio de búsqueda');
      }
    }
    if (!hay){

      formData['valorensayo'] = nombres.ensayo.concat('-',formData.valorensayo.padStart(5, 0));
      formData['valorelemento'] = nombres.elemento.concat('-', formData.valorelemento.padStart(5, 0));
      formData['email']=props.email;
      //console.log('formData: ', formData);
          
    }
  }
*/
  function limpia() {
    
    let temp = "";
    let mySelect = document.getElementById('TipoElemento');
    for(var i, j = 0; i = mySelect.options[j]; j++) {
        if(i.value == temp) {
            mySelect.selectedIndex = j;
            break;
        }
    }
    temp = "";
    mySelect = document.getElementById('TipoEmpresa');
    for(var i, j = 0; i = mySelect.options[j]; j++) {
        if(i.value == temp) {
            mySelect.selectedIndex = j;
            break;
        }
    }

    setPdfBase64(null);
    setNombres({'ensayo':'', 'elemento':''});
    setErrorForm(null);
    setFormConsulta({
      'elemento': '',
      'valorensayo': '',
      'valorelemento': ''
    })
    let valorElemento = document.getElementById('ValorElemento');
    valorElemento.value = null;
    let valorEnsayo = document.getElementById('ValorEnsayo');
    valorEnsayo.value = null;

    setMensajeImagen(null);
    setCodigoEnsayo(null);
  }
  async function bajarArchivo () {
    
    let formData = {};
    formData['valorensayo'] = formConsulta['valorensayo'];
    formData['valorelemento'] = formConsulta['valorelemento'];
    formData['empresa'] = formConsulta['empresa'];
    //console.log('bajarArchivo formData: ',formData);
    let hay = false
    if (nombres.ensayo.substring(0,3)!=='LAT') {
      //debe seleccionar el tipo elemento
      setErrorForm('Debe seleccionar el tipo elemento');
      hay=true;
    }
    if (!hay) {
      if (!(formData.valorensayo||formData.valorelemento)){
        //debe ingresar al menos un criterio de búsqueda
        hay=true;
        setErrorForm('Debe ingresar al menos un criterio de búsqueda');
      }
    }
    if (!hay){
      /*
      setFormConsulta(null); 
      setErrorForm(null);
      */
      let valor_cod_ensayo = '';
      //console.log('nombres: ',nombres.ensayo, nombres.elemento);
      formData['valorensayo'] = nombres.ensayo.concat('-',formData.valorensayo.padStart(5, 0));
      formData['valorelemento'] = nombres.elemento.concat('-', formData.valorelemento.padStart(5, 0));
      console.log('cuenta: ', formData['empresa']);
      if (!formData['empresa']) {
        formData['email']=props.email;
      }else{
        formData['email']=formData['empresa'];
      }
      
      //console.log('formData: ', formData);
        {/********** AXIOS **************** */}
        await axios.request({
          url: "/api/getencabezado_x_cliente",
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + props.token
          },
          data: formData,
        }).then((res) => {
          //console.log('respuesta:', res.data.datos.cod_ensayo);
          valor_cod_ensayo = res.data.datos.cod_ensayo;
          setCodigoEnsayo(res.data.datos.cod_ensayo);

        }).catch((error) => {
        if (error.response) {
          //console.log('ERROR: ', error.response.data)
          
          setMensajeImagen(error.response.data.msg);
          /*console.log('ERROR: ', error.response)
          console.log(error.response.status)
          console.log(error.response.headers)*/
          }
        }); 

        
      {/********** AXIOS **************** */}
        //console.log('valor_cod_ensayo: ', valor_cod_ensayo)
          axios.request({
            url: "/api/getpdf",
            //url: "/api/testpdf",
            method: "POST",
            responseType: 'blob',
            headers: {
              Authorization: 'Bearer ' + props.token
            },
            data: {
              'ensayopdf': valor_cod_ensayo,
              'verpng': true,
            },
        }).then((res) => {
          //console.log('respuesta:', res.data);
          const urlBlob = URL.createObjectURL(res.data);
          //console.log(urlBlob);
          setMensajeImagen(null);
          setPdfBase64(urlBlob);
        }).catch((error) => {
          if (error.response) {
            //console.log('ERROR: ', error.response.data)
            setPdfBase64(null);
            setMensajeImagen('No hay resultados para la búsqueda');
            //console.log('ERROR: ', error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });


        {/*********************************************** */}
    }
  }

  async function descargaPdf() {
    await axios.request({
      url: "/api/getpdf",
      method: "POST",
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: {
        'ensayopdf': codigoEnsayo,
        'verpng': false,
      },
  }).then((res) => {
    fileDownload(res.data, codigoEnsayo + ".pdf");
  }).catch(function (error) {
      //console.log(error);
  }); 
  }

  return (
    <>
        {props.mostrarCabecera?
        <>
          <Navbar className="bg-white">
          <Container>
            <Navbar.Brand href="#home"><img  src={f_logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras-grande">Laboratorio Ensayo Equipos Dieléctricos</span></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
              <Button onClick={() => {setCodigoEnsayo(null) ;setMensajeImagen(null); setFormConsulta(null); setErrorForm(null); props.setMuestraMenu(true); props.setOpcion1(0)}} >volver</Button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <hr />
        </>:null
        }
            
        <Row >
          <Col md={{ span: 3, offset: 1 }} >
          <span className="letras-negras">Módulo de búsqueda de informes Individuales por Elemento</span>
          </Col>
        </Row>
        <hr />
        <Row>
              {/******************** COLUMNA INICIAL ***********************/}
              <Col xs={2} md={1}></Col>
              <Col xs={12} md={6}>
                      <p><span className = "mensaje-error">{errorForm}</span></p>
              </Col>
                    </Row>
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>

          
          <Col xs={8} md={4}>
                <Form>
                {props.perfil=='superusuario'?<>
                  <Form.Group as={Row} className="mb-3" controlId="TipoEmpresa">
                        <Form.Label column sm="4">
                        <span className="campo">
                          Empresa
                        </span>
                        </Form.Label>
                        <Col sm="8">
                          <SelectParamTest parametro="empresa" valorDefault={formConsulta.empresa} cambioEnForm={cambioEnForm} />
                        </Col>
                      </Form.Group>
                </>:null}
                  {/******************** COLUMNA TIPO ELEMENTO ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="TipoElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Tipo Elemento
                      </span>
                      </Form.Label>
                      <Col sm="8">
                        <SelectParamTest parametro="elemento" valorDefault={formConsulta.tipo} cambioEnForm={cambioEnForm} />
                      </Col>
                    </Form.Group>

                    {/******************** COLUMNA ENSAYO ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="ValorEnsayo">
                      <Form.Label column sm="4">
                        <span className="campo">Ensayo</span>
                      </Form.Label>
                      <Col sm="8">
                            <InputGroup >
                              <InputGroup.Text >{nombres.ensayo}-</InputGroup.Text>
                            <Form.Control
                            min={1}
                            value={formConsulta.valorensayo}
                            name="valorensayo"
                            type="number"
                            placeholder="ensayo"
                            onChange={cambioEnForm}
                            />
                            </InputGroup>
                      </Col>
                    </Form.Group>

                    {/******************** COLUMNA ELEMENTO ***********************/}
                    <Form.Group as={Row} className="mb-3" controlId="ValorElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Elemento
                        </span>
                      </Form.Label>
                      <Col sm="8">
                          <InputGroup >
                              <InputGroup.Text >{nombres.elemento}-</InputGroup.Text>
                              <Form.Control
                                min={1}
                                value={formConsulta.valorelemento}
                                name="valorelemento"
                                type="number"
                                placeholder="elemento"
                                onChange={cambioEnForm}
                                />
                            </InputGroup>
                      </Col>
                    </Form.Group>

                  </Form>
                  </Col>
        </Row>
        <Row>
                          {/******************** COLUMNA INICIAL ***********************/}
                          <Col xs={2} md={1}></Col>
                          {/******************** BOTON BUSCAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => bajarArchivo()} >Buscar</Button>
                          </Col>

                          {/******************** BOTON LIMPIAR***********************/}
                          <Col xs={4} md={2}>
                            <Button onClick={() => limpia()} >Limpiar</Button>
                          </Col>
                         
        </Row>
        <hr />
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>
          <Col xs={8} md={4}>
          {mensajeImagen?
          <p><span className = "comentarios">{mensajeImagen}</span></p>:null}
          </Col>
        </Row>
      <Row >
      <div className="informe-content-x" >
        {pdfBase64?<><img className="mx-auto d-block" src={pdfBase64} />
        <div className="col-md-12 text-center" ><Button onClick={() => descargaPdf()} >Descargar PDF</Button></div>
        </>
        :null}
        </div>
      </Row>
      
    </>
  )
};

export default Informe01;