import React, { useState } from "react";
import { Row, Col, Table as TablaBoot, Modal, Button, Form, Navbar,Container, Image, InputGroup , Pagination, Alert} from "react-bootstrap";
import {ArrowDownUp, SortAlphaDown, SortAlphaUp, FilePdf, CloudDownload, Trash} from 'react-bootstrap-icons';
import axios from "axios";
import { useTable, usePagination, useRowSelect, useSortBy } from 'react-table'
import fileDownload from 'js-file-download'
import f_logo from '../imagenes/logo.bmp';
import "@fontsource/roboto";
import '../estilos/AddInstrumento.css';
import SelectParamTest from "./selectores/SelectParamTest"


  function Table({ columns, data, token, handleShow, perfil }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      selectedFlatRows,
      state: { pageIndex, pageSize, selectedRowIds },
    } = useTable(
      {
        columns,
        data,
      },
      useSortBy,
      usePagination
      )
  
    // Render the UI for your table
    return (
      <>
        
        <TablaBoot striped bordered hover {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSortedTable
                        ? column.isSortedDesc
                          ? <SortAlphaDown />
                          : <SortAlphaUp />
                        : <ArrowDownUp />}
                    </span>
                    </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}<td><SetBotonPdf celdas={arregloAJson(row.cells)} token={token}  />
                  <SetBotonBorra celdas={arregloAJson(row.cells)} token={token} handleShow={handleShow} perfil={perfil} /></td>
                </tr>
              )
            })}
          </tbody>
        </TablaBoot>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        <div className="pagination">
  
          <Row>
            <Col xs={5} md={4} >
                  <Pagination size='md'  >
                    <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
                    <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
                    <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
                    <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
                  </Pagination>
            </Col>
            <Col xs={5} md={4}>
                <span className="letras-negras">
                  Página{' '}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>{' '}
                </span>
            </Col>
            
          <Col xs={5} md={4}>
          <Form.Select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </Form.Select>
          </Col>
          </Row>
        </div>
      </>
    )
  };


  function arregloAJson(s) {
    let g = s.map((cell) => {return [cell.column.id, cell.value];});
    //console.log('g: ', g);
    const z = {};
    function mifunc(valor) {
      z[valor[0]]=valor[1];
    }
    g.forEach(mifunc);
    //console.log('z: ', z);
    return z;
  };

  function SetBotonPdf (props) {
    //console.log(props.celdas.id, props.celdas.nombre_archivo);
    return <button className="btn btn-outline-primary" size="sm" onClick={() => bajaArchivo(props.celdas.id, props.celdas.nombre_archivo, props.token) }><CloudDownload /></button>;
  };

  function SetBotonBorra (props) {
    //console.log(props.celdas.id, props.celdas.nombre_archivo);
    if (props.perfil == 'superusuario') {
    return <button className="btn btn-outline-danger" size="sm" onClick={() => props.handleShow(props.celdas.id) }><Trash /></button>;
    }else{
      return null;
    }
  };

  async function bajaArchivo(id_file, nombre_archivo, token) {
    console.log(id_file, nombre_archivo);
    await axios.request({
      url: "/api/archivo",
      method: "POST",
      responseType: 'blob',
      
      headers: {
        Authorization: 'Bearer ' + token
      },
      data: {
        'id_archivo': id_file
      },
    }).then((res) => {
      fileDownload(res.data, nombre_archivo);
    }).catch(function (error) {
        //console.log(error);
    }); 
    //return null;
  }


function AddInstrumento (props) {
    
  const c = new Date().toLocaleString("es-CL", {timeZone: "America/Santiago"});
  const fechoy = c.substring(6,10) + '-' + c.substring(3,5) + '-' + c.substring(0,2);
  const [idBorrar, setIdBorrar] = useState();

  // eslint-disable-next-line no-mixed-operators
  const [currentFile, setCurrentFile] = useState(null);

  const [formConsulta, setFormConsulta] = useState({
      'serie': '',
      'cliente': '-1',
      'empresa': '',
  });
  const [nombres, setNombres] = useState({'ensayo':'', 'elemento':''})
  const [errorForm, setErrorForm] = useState();
  const [mensajeImagen, setMensajeImagen] = useState();
  const [codigoEnsayo, setCodigoEnsayo] = useState();
  const [datosTabla, setDatosTabla] = useState();
  const columnas = [
    {
      Header: 'Id',
      accessor: 'id'
    }, {
      Header: 'Num_serie',
      accessor: 'num_serie'
    }, {
      Header: 'Nombre_archivo',
      accessor: 'nombre_archivo'
    }, {
      Header: 'Fecha ingreso',
      accessor: 'fecha_ingreso'
    }
  ];
  const [mensajeIngresoOk, setMensajeIngresoOk] = useState(false);
  const [glosaAlerta, setGlosaAlerta] = useState();
  const [mensajeBorrado, setMensajeBorrado] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  function handleShow (id) {
    setIdBorrar(id);
    setShow(true);
    
  }

  async function borraRegistroById (id) {
    let formData = {};
    formData['id'] = id;
    await axios.request({
      url: "/api/borraarchivoxid",
      method: "DELETE",
      headers: {
        Authorization: 'Bearer ' + props.token
      },
      data: formData
    }).then((res) => {
      //console.log(res);
      setDatosTabla(datosTabla.filter((item) => item.id !== id));
    }).catch((error) => {
      if (error.response) {
        setMensajeBorrado(error.response.data)
        console.log(error.response.data)
        }
      });
      setShow(false);
  }

  function cambioEnFecha (event) {
    const {value, name} = event.target;
    if (name === 'fecha_ini') {
      setFormConsulta(prevNote => ({...prevNote, [name]: value, ['fecha_fin']: fechoy}))
  }else {
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
  }
    setErrorForm(null);
    //setMaxFechaIn(value);
    //setValorFechaIn('');
    //console.log(value, name);
  }

//****************************************************************************************** */
//****************************************************************************************** */

  function cambioEnForm (event) {
    //console.log('formConsulta: ', formConsulta)
    const {value, name} = event.target;
    //console.log('cambio form encabezado', name, value);
    setErrorForm(null);
    setFormConsulta(prevNote => ({...prevNote, [name]: value}))
  }

//****************************************************************************************** */
//****************************************************************************************** */

  function selectArchivo (event) {
    setCurrentFile(event.target.files[0]);
  }

  async function guardarArchivo () {
    console.log('formConsulta: ', formConsulta);
    let formData = new FormData();
    let hayError=false;
    if (!formConsulta['serie']){
      //se deja igual
      setErrorForm('Debe ingresar el número de serie a ingresar');
      hayError=true;
    }
    if (!formConsulta['empresa']){
      //se deja igual
      setErrorForm('Debe seleccionar la empresa');
      hayError=true;
    }
    if (!currentFile) {
      setErrorForm('Debe seleccionar un archivo');
      hayError=true;
    }
    if (!hayError) {
      formData.append('file', currentFile);
      formData.append('serie', formConsulta['serie']);
      formData.append('cliente', formConsulta['empresa']);
      formData.append('datos', currentFile.name);
      await axios.request({
        url: "/api/ingresa_archivo",
        method: "POST",
        headers: {
          Authorization: 'Bearer ' + props.token
        },
        data: formData
      }).then((res) => {
        console.log('respuesta axios: ', res.data)
        setMensajeIngresoOk(true);
        setGlosaAlerta("Archivo serie " + formConsulta['serie'] + " ingresado correctamente")
        //limpia();
      }).catch((error) => {
      if (error.response) {
        setErrorForm(error.response.data.msg)
        }
      });
    }
  }

//****************************************************************************************** */
//****************************************************************************************** */

  function limpia() {
    //setPdfBase64(null);
    //setNombres({'ensayo':'', 'elemento':''});

    console.log('limpia');
    if (props.perfil=='superusuario'){
        let temp = "";
        let mySelect = document.getElementById('TipoEmpresa');
        for(var i, j = 0; i === mySelect.options[j]; j++) {
            if(i.value === temp) {
                mySelect.selectedIndex = j;
                break;
            }
        }
  }
    setErrorForm(null);
    setDatosTabla(null);
    setCurrentFile(undefined);
    setFormConsulta({
      'serie': '',
      'cliente': '-1',
      'empresa': '',

    });
    setMensajeImagen(null);
    setCodigoEnsayo(null);
    setMensajeIngresoOk(false);
    setGlosaAlerta(null);
  }

  async function get_allInstrumentosByCliente() {
    let formData = {};
    let hayError=false;
    if (props.perfil=='superusuario'){
      //realizar chequeo solo si es superusuario, usuario normal va a ir a consultar con -1 y el backend se 
      //encarga de cambiar el cliente
      if (!formConsulta['empresa']){
        setErrorForm('Debe seleccionar la empresa');
        hayError=true;
      }
    }

    
    if (!hayError){
      if (props.perfil=='superusuario'){
        formData['cliente'] = formConsulta['empresa']
      }else {
        formData['cliente'] = formConsulta['cliente'];
      }
      await axios.request({
        url: "/api/listaarchivo",
        method: "POST",
        headers: {
          Authorization: 'Bearer ' + props.token
        },
        data: formData
      }).then((res) => {
        if (res.data.datos.length>0){ 
          setDatosTabla(res.data.datos)
        }else{
          //poner mensaje en pantalla que no hay datos
          setMensajeIngresoOk(true);
          setGlosaAlerta("No hay datos para la búsqueda")
        }
      }).catch((error) => {
        if (error.response) {
          setErrorForm(error.response.data.msg)
          }
        });
    }
  }
//****************************************************************************************** */
//****************************************************************************************** */

  async function get_instrumentos() {
    //console.log('formConsulta: ', formConsulta);
    let formData = {};
    let hayError=false;
    formData['serie'] = formConsulta['serie'];
    formData['cliente'] = formConsulta['cliente'];
    formData['empresa'] = formConsulta['empresa'];
    if (props.perfil=='superusuario'){
      //realizar chequeo solo si es superusuario, usuario normal va a ir a consultar con -1 y el backend se 
      //encarga de cambiar el cliente
      if (!formConsulta['empresa']){
        setErrorForm('Debe seleccionar la empresa');
        hayError=true;
      }
    }
    if (!formData['serie']){
      //se deja igual
      setErrorForm('Debe ingresar el número de serie a buscar');
      hayError=true;
    }
    if (!formData['empresa']){
      //se deja igual
      formData['cliente'] = formConsulta['cliente'];
    }else {
      //se cambia el cliente por empresa
      formData['cliente'] = formConsulta['empresa'];
    }
    if (!hayError){
        await axios.request({
          url: "/api/buscaarchivoxserie",
          method: "POST",
          headers: {
            Authorization: 'Bearer ' + props.token
          },
          data: formData
      }).then((res) => {
        //console.log('respuesta axios: ', res.data)
        if (res.data.datos.length>0){
          setDatosTabla(res.data.datos)
        }else{
          //poner mensaje en pantalla que no hay datos
          setMensajeIngresoOk(true);
          setGlosaAlerta("No hay datos para la búsqueda")
        }
      }).catch((error) => {
        if (error.response) {
          setErrorForm(error.response.data.msg)
          //console.log(error.response.data.msg)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
        });
      }
  }


//****************************************************************************************** */
//****************************************************************************************** */

  
  return (
    <>
          {props.mostrarCabecera?
          <>
            <Navbar className="bg-white">
              <Container>
                <Navbar.Brand href="#home"><img  src={f_logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras-grande">Laboratorio Ensayo Equipos Dieléctricos</span></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                  <Button onClick={() => {setCodigoEnsayo(null) ;setMensajeImagen(null); setFormConsulta(null); setErrorForm(null); props.setMuestraMenu(true); props.setOpcion1(0)}} >volver</Button>
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          <hr />
          </>:null}
        <Row >
          <Col md={{ span: 3, offset: 1 }} >
          <span className="letras-negras">Ensayo de Instrumentos</span>
          </Col>
        </Row>
        <hr />
        <Row>
              {/******************** COLUMNA INICIAL ***********************/}
              <Col xs={2} md={1}></Col>
              <Col xs={12} md={6}>
                      <p><span className = "mensaje-error">{errorForm}</span></p>
              </Col>
                    </Row>
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>

          
          <Col xs={8} md={4}>
                <Form>
                    {props.perfil=='superusuario'?<>
                      <Form.Group as={Row} className="mb-3" controlId="TipoEmpresa">
                            <Form.Label column sm="4">
                            <span className="campo">
                              Empresa
                            </span>
                            </Form.Label>
                            <Col sm="8">
                              <SelectParamTest parametro="empresa" valorDefault={formConsulta.empresa} cambioEnForm={cambioEnForm} />
                            </Col>
                      </Form.Group>
                    </>:null}
                  {/******************** COLUMNA TIPO ELEMENTO ***********************/}
                  <Form.Group as={Row} className="mb-3" controlId="TipoElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Serie Instrumento
                      </span>
                      </Form.Label>
                      <Col sm="8">
                            <InputGroup >
                                <Form.Control
                                min={1}
                                value={formConsulta.serie}
                                name="serie"
                                type="text"
                                placeholder="serie"
                                onChange={cambioEnForm}
                                />
                            </InputGroup>
                      </Col>
                    </Form.Group>
                  {/******************** INGRESO DE ARCHIVO ***********************/}
                  {props.perfil=='superusuario'?<>
                  <Form.Group as={Row} className="mb-3" controlId="TipoElemento">
                      <Form.Label column sm="4">
                      <span className="campo">
                        Serie Instrumento
                      </span>
                      </Form.Label>
                      <Col sm="8">
                            <InputGroup >
                                <Form.Control
                                name="archivo"
                                type="file"
                                placeholder="archivo"
                                onChange={selectArchivo}
                                />
                            </InputGroup>
                      </Col>
                    </Form.Group></>:null}

                </Form>
          </Col>
        </Row>
        <Row>
                          {/******************** COLUMNA INICIAL ***********************/}
                          <Col xs={2} md={1}></Col>
                          <Col xs={10} md={5}>
                            {/******************** BOTON BUSCAR***********************/}
                              <Button variant="outline-primary" onClick={() => get_instrumentos()} >Buscar Serie</Button>
                            {/******************** BOTON LIMPIAR***********************/}
                              <Button variant="outline-primary" onClick={() => limpia() } >Limpiar</Button>
                            {/******************** BOTON LISTAR***********************/}
                              <Button variant="outline-primary" onClick={() => get_allInstrumentosByCliente() } >Listar Todos</Button>
                            {/******************** BOTON SUBIR***********************/}
                            {props.perfil=='superusuario'?
                            <>
                              <Button variant="outline-primary" onClick={() => guardarArchivo() } >Guardar</Button></>:null}
                          </Col>
                          

        </Row>
        <Row>
            <Col xs={2} md={1}></Col>
            <Col xs={10} md={5}>
              <Alert show={mensajeIngresoOk} variant="success">
                <Alert.Heading>Mensaje</Alert.Heading>
                <p>
                  {glosaAlerta}
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                  <Button onClick={() => limpia()} variant="outline-success">
                    Ok
                  </Button>
                </div>
              </Alert>
            </Col>
        </Row>
        <hr />
        <Row>
          {/******************** COLUMNA INICIAL ***********************/}
          <Col xs={2} md={1}></Col>
          <Col xs={8} md={4}>
          {mensajeImagen?
          <p><span className = "comentarios">{mensajeImagen}</span></p>:null}
          </Col>
        </Row>
        {datosTabla?
        <div className='contenedor-tabla'>
        <Table columns={columnas} data={datosTabla} token={props.token} handleClose={handleClose} handleShow={handleShow} perfil={props.perfil} /></div>
        :null
        }
            <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className = "comentarios">Borrar registro con Id {idBorrar}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body><span className = "comentarios">Desea ejecutar el borrado del registro?</span></Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={() => borraRegistroById(idBorrar)}>
                Si, borrar
              </Button>
            </Modal.Footer>
          </Modal>
    </>
  )
}

export default AddInstrumento;