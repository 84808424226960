import { useState } from 'react';
//import axios from 'axios';

function useToken() {

    const [token, setToken] = useState(getToken());
    const [email, setEmail] = useState(getEmail());
    const [menuInv, setMenuInv] = useState(getMenuInv());
    const [menuEns, setMenuEns] = useState(getMenuEns());
    const [perfilUser, setPerfilUSer] = useState(getPerfilUser());
    /*
    function ValidaToken() {
      console.log('entro en valida token')
      axios({
        method: "POST",
        url:"/api/valida",
      })
      .then((response) => {
        console.log('token validado');
      }).catch((error) => {
        if (error.response) {
          removeToken()
          console.log(error.response)
          console.log(error.response.status)
          console.log(error.response.headers)
          }
      })}
      */
    function getToken() {
      //console.log('entro en getToken')
      const userToken = localStorage.getItem('token');
      const hoy = localStorage.getItem('fecha_tkn');
      if (hoy) {
        //console.log(parseFloat(Date.now().toString()) - parseFloat(hoy.toString()));
        if ((parseFloat(Date.now().toString()) - parseFloat(hoy.toString()) > 6000000))
        {
          localStorage.removeItem("token");
          localStorage.removeItem('fecha_tkn');
        } 
      }
      return userToken && userToken
    }

    function getEmail() {
      const valorId = localStorage.getItem('id');
      if (!valorId) {
        return '';
      }else {
        const jsonEmail = JSON.parse(valorId);
      const valorEmail = jsonEmail.email;
      return valorEmail && valorEmail; 
      }
    }

    function getMenuInv() {
      const valorId = localStorage.getItem('menu_inv');
      if (!valorId) {
        return '';
      }else {
        const jsonMenu = JSON.parse(valorId);
        const valorMenu = jsonMenu.menu;
        return valorMenu && valorMenu; 
      }
    }

    function getMenuEns() {
      const valorId = localStorage.getItem('menu_ens');
      if (!valorId) {
        return '';
      }else {
        const jsonMenu = JSON.parse(valorId);
        const valorMenu = jsonMenu.menu;
        return valorMenu && valorMenu; 
      }
    }
    function getPerfilUser() {
      const valorId = localStorage.getItem('perfil');
      if (!valorId) {
        return '';
      }else {
        return valorId && valorId; 
      }
    }
  
    function saveToken(userToken, email, menuinventario, menuensayo, perfilUser ) {
      //const fecha = new Date();
      //console.log('saveToken->menu_inventario: ', menuinventario);
      const datoMenu = {"menu":menuinventario};
      const datoMenuEns = {"menu": menuensayo};
      const fecha = Date.now();
      const hoy = fecha.toString();
      //const tiempo = new Date(fecha);
      //console.log('entro en saveToken')
      //localStorage.setItem('tk', tiempo.toDateString());
      localStorage.setItem('token', userToken);
      localStorage.setItem('fecha_tkn', hoy);
      const tkn_json = {'token': userToken, 'fecha_tkn': hoy, 'email': email};
      localStorage.setItem('id', JSON.stringify(tkn_json));
      localStorage.setItem('menu_inv', JSON.stringify(datoMenu));
      localStorage.setItem('menu_ens', JSON.stringify(datoMenuEns));
      localStorage.setItem('perfil', perfilUser);
      //console.log(hoy);
      setEmail(email);
      setToken(userToken);
      setMenuInv(menuinventario);
      setMenuEns(menuensayo);
      setPerfilUSer(perfilUser);
    };
  
    function removeToken() {
      //console.log('entro en removeToken')
      localStorage.removeItem("token");
      localStorage.removeItem('fecha_tkn');
      localStorage.removeItem('menu_inv');
      localStorage.removeItem('menu_ens');
      localStorage.removeItem('perfil');
      setToken(null);
    }
  
    return {
      setToken: saveToken,
      token,
      removeToken,
      email,
      menuInv,
      menuEns,
      perfil: perfilUser
    }
  
  }
  export default useToken;