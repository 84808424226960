import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Container, Row, Col, Badge} from 'react-bootstrap';
import {PencilSquare} from 'react-bootstrap-icons';
import EditaCodEnsayo from "../EditaCodEnsayo";
import SelectParamTest from "../selectores/SelectParamTest";

function EditarEnsayo (props) {

    const rutaCompleta = props.nombre_tabla;
    const today = new Date();
    const [show, setShow] = useState(false);
    const [labelCodEnsayo, setLabelCodEnsayo] = useState(props.celdas.codigo);
    const [maxFechaIn, setMaxFechaIn] = useState(formateaFecha(today));
    const [valorFechaIn, setValorFechaIn] = useState('');
//**************************************************************************** */
  const [formCliente, setFormCliente] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'tipo_ensayo': props.nombre_tabla.split("/")[2],
    'orden_compra': '',
    'dir_ensayo': 'Leonidas Vial 1109, San Miguel, Santiago.',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
    'estado': '2'
  });
  //**************************************************************************** */
  const [errorForm, setErrorForm] = useState({
    'cod_ensayo': '',
    'cliente': '',
    'sucursal': '',
    'negocio': '',
    'temperatura': '',
    'humedad': '',
    'tecnico': '',
    'patron': '',
    'orden_compra': '',
    'dir_ensayo': '',
    'fecha_ejecucion': '',
    'fecha_ingreso': '',
  });

  function cambioEnForm (event) {
    //console.log('cambio form encabezdo')
    setErrorForm({
      'cod_ensayo': '',
      'cliente': '',
      'sucursal': '',
      'negocio': '',
      'temperatura': '',
      'humedad': '',
      'tecnico': '',
      'patron': '',
      'orden_compra': '',
      'dir_ensayo': '',
      'fecha_ejecucion': '',
      'fecha_ingreso': '',
    });
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
  }
//**************************************************************************** */
function formateaFecha (fecha) {
    
    let dia = fecha.getDate();
    let mes = fecha.getMonth() +1;
    let yearX = fecha.getFullYear();

    let diaStr = "";
    if (dia < 10) {
      diaStr = diaStr.toString().concat("0", dia.toString());
    }else {
      diaStr = dia.toString();
    }
    //console.log(diaStr)
    let mesStr = "";
    if (mes < 10) {
      mesStr = mesStr.toString().concat("0", mes.toString())
    } else {
      mesStr = mes.toString();
    }
    //console.log(mesStr)
    //let todo = diaStr.concat("-", mesStr, "-", yearX.toString())
    let todo = yearX.toString().concat("-", mesStr, "-", diaStr);
    //console.log(todo)
    return todo;
    
   //return "hola";
  }
  //**************************************************************************** */
  function cambioEnFecha (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value, ['fecha_ingreso']: ''}))
    setMaxFechaIn(value);
    setValorFechaIn('');
    //console.log(value, name);
  }
  //**************************************************************************** */
  function cambioEnFechaIn (event) {
    const {value, name} = event.target;
    setFormCliente(prevNote => ({...prevNote, [name]: value}))
    setValorFechaIn(value);
    //console.log(value, name);
  }
  //**************************************************************************** */
    async function handleShow () {
        setErrorForm({
            'cod_ensayo': '',
            'cliente': '',
            'sucursal': '',
            'negocio': '',
            'temperatura': '',
            'humedad': '',
            'tecnico': '',
            'patron': '',
            'orden_compra': '',
            'dir_ensayo': '',
            'fecha_ejecucion': '',
            'fecha_ingreso': '',
          });
      
          await axios({
            method: "POST",
            url:"/api/formensayos",
            data: {
              "id_batea": props.celdas.id,
            }
          })
          .then((response) => {
            //setValorTec({"valor": response.data.datos[0].tecnico})
            //console.log('respuesta form: ',response.data.datos);
            setErrorForm({
            'cod_ensayo': '',
            'cliente': '',
            'sucursal': '',
            'negocio': '',
            'temperatura': '',
            'humedad': '',
            'tecnico': '',
            'patron': '',
            'orden_compra': '',
            'dir_ensayo': '',
            'fecha_ejecucion': '',
            'fecha_ingreso': '',
          });
            setFormCliente({
              'cod_ensayo': props.celdas.codigo,
              'cliente': response.data.datos[0].cliente,
              'sucursal': response.data.datos[0].sucursal,
              'negocio': response.data.datos[0].negocio,
              'temperatura': response.data.datos[0].temperatura,
              'humedad': response.data.datos[0].humedad,
              'tecnico': response.data.datos[0].tecnico,
              'patron': response.data.datos[0].patron,
              'tipo_ensayo': props.nombre_tabla.split("/")[2],
              'fecha_ejecucion': response.data.datos[0].fecha_ejecucion,
              'fecha_ingreso': response.data.datos[0].fecha_ingreso,
              'orden_compra': response.data.datos[0].orden_compra,
              'dir_ensayo': response.data.datos[0].dir_ensayo,
              'estado': '2',
            })
            setMaxFechaIn(response.data.datos[0].fecha_ejecucion);
          }).catch((error) => {
            if (error.response) {
              //console.log(error.response)
              //console.log(error.response.status)
              //console.log(error.response.headers)
              }
          });
        setShow(true);
    }
  //**************************************************************************** */
    async function handCloseGraba () {
      let se_hizo_bien = await grabaEncabezado();
      //console.log('se hizo bien: ', se_hizo_bien);
      if (se_hizo_bien) {
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
        //window.location.reload(false);
      }
    }
  //**************************************************************************** */
    async function grabaEncabezado () {
        //graba la parte de encabezado si es que hubo algún cambio
        //console.log('form encabezado: ', formCliente)
        setErrorForm({
          'cod_ensayo': '',
          'cliente': '',
          'sucursal': '',
          'negocio': '',
          'temperatura': '',
          'humedad': '',
          'tecnico': '',
          'patron': '',
          'orden_compra': '',
          'dir_ensayo': '',
          'fecha_ejecucion': '',
          'fecha_ingreso': '',});
          //console.log(formCliente);
        let hayError = false;
        if (!formCliente.cod_ensayo) {
          setErrorForm(prevNote => ({...prevNote, ["cod_ensayo"]: "Código de ensayo no definido"}))
          hayError = true;
          //console.log('error en cod_ennsayo: ', formCliente.cod_ensayo)
        }
        if (!formCliente.cliente) {
          if (formCliente.cliente == "0"){
            // esta ok el cliente codigo 0
          } else {
            setErrorForm(prevNote => ({...prevNote, ["cliente"]: "Seleccione Cliente"}))
            hayError = true;
            //console.log('error en cliente: ', formCliente.cliente)
          }
        }
        if (!formCliente.sucursal) {
          setErrorForm(prevNote => ({...prevNote, ["sucursal"]: "Seleccione Sucursal"}))
          hayError = true;
          //console.log('error en sucursal: ', formCliente.sucursal)
        }
        if (!formCliente.negocio) {
          setErrorForm(prevNote => ({...prevNote, ["negocio"]: "Seleccione Negocio"}))
          hayError = true;
          //console.log('error en negocio: ', formCliente.negocio)
        }
        if (!formCliente.temperatura) {
          setErrorForm(prevNote => ({...prevNote, ["temperatura"]: "Ingrese Temperatura"}))
          hayError = true;
          //console.log('error en temperatura: ', formCliente.temperatura)
        }else {
          if (isNaN(formCliente.temperatura)) {
            setErrorForm(prevNote => ({...prevNote, ["temperatura"]: "Temperatura debe ser un número"}))
            hayError = true;
            //console.log('error en temperatura: ', formCliente.temperatura)
          }
        }
        if (!formCliente.humedad) {
          setErrorForm(prevNote => ({...prevNote, ["humedad"]: "Ingrese Humedad"}))
          hayError = true;
          //console.log('error en humedad: ', formCliente.humedad)
        }else {
          if (isNaN(formCliente.humedad)) {
            setErrorForm(prevNote => ({...prevNote, ["humedad"]: "Humedad debe ser un número"}))
            hayError = true;
            //console.log('error en humedad: ', formCliente.humedad)
          }
        }
        if (!formCliente.tecnico) {
          setErrorForm(prevNote => ({...prevNote, ["tecnico"]: "Seleccione Técnico"}))
          hayError = true;
          //console.log('error en tecnico: ', formCliente.tecnico)
        }
        if (!formCliente.patron) {
          setErrorForm(prevNote => ({...prevNote, ["patron"]: "Seleccione Patrón"}))
          hayError = true;
          //console.log('error en patron: ', formCliente.patron)
        }
        if (!formCliente.fecha_ingreso) {
    
          //console.log('fecha in: ', formCliente.fecha_in)
          setErrorForm(prevNote => ({...prevNote, ["fecha_in"]: "Seleccione fecha ingreso"}))
          hayError = true;
          //console.log('error en fecha_in: ', formCliente.fecha_ingreso)
        }
        if (!formCliente.fecha_ejecucion) {
          //console.log('fecha ej: ', formCliente.fecha_ej)
          setErrorForm(prevNote => ({...prevNote, ["fecha_ej"]: "Seleccione fecha ejecución"}))
          hayError = true;
          //console.log('error en fecha_ej: ', formCliente.fecha_ejecucion)
        }
        if (!formCliente.orden_compra) {
          //console.log('fecha ej: ', formCliente.fecha_ej)
          setErrorForm(prevNote => ({...prevNote, ["orden_compra"]: "Seleccione Orden de compra"}))
          hayError = true;
          //console.log('error en fecha_ej: ', formCliente.fecha_ejecucion)
        }
        if (!formCliente.dir_ensayo) {
          setErrorForm(prevNote => ({...prevNote, ["dir_ensayo"]: "Ingrese dirección del ensayo"}))
          hayError = true;
          //console.log('error en fecha_ej: ', formCliente.fecha_ejecucion)
        }
        if (!hayError) {
          //console.log('llama a guradar form ' + JSON.stringify(formCliente));
          await axios({
            method: "POST",
            url:"/api/grabaensayo",
            data: formCliente
          })
          .then((response) => {
            //console.log('axios grabaensayo: ', response.data.msg);
          }).catch((error) => {
            if (error.response) {
              //console.log('error axios: ', error.response.data.msg)
              setErrorForm(prevNote => ({...prevNote, ["orden_compra"]: error.response.data.msg}))
              hayError = true;
              //console.log(error.response.status)
              //console.log(error.response.headers)
              }
          });
          if (hayError) {
            console.log('retorna falso 1');
            return false;
            
          }else {
            console.log('retorna true');
            return true;
          }
        } else {
          //console.log('hubo error en graba encabezado')
          console.log('retorna falso 2');
          return false;
        }
      };
  //**************************************************************************** */
    function handleClose () {
        const aleatorio = Math.floor(Math.random()*1000).toString()
        setShow(false); //cierra la ventana popup
        props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
      }
  //**************************************************************************** */
    function EditarCodEnsayo(props){

        return <EditaCodEnsayo 
          codEnsayo={props.codEnsayo} 
          setLabelCodEnsayo = {props.setLabelCodEnsayo}
          rutaCompleta={props.rutaCompleta}
          setRuta={props.setRuta}
          setFormCliente={props.setFormCliente}
           />;
      };
//**************************************************************************** */
async function emitir () {
    await axios.request({
      url: "/api/emitecert",
      method: "POST",
      data: {"cod_ensayo": props.celdas.codigo}
      }).then((response) => {
        //setValorTec({"valor": response.data.datos[0].tecnico})
        //setDetalleEnsayo(response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      //window.location.reload(false);
      const aleatorio = Math.floor(Math.random()*1000).toString()
      setShow(false); //cierra la ventana popup
      props.setRuta('/refrescaEnsayoGuantes'.concat('_',aleatorio,'/',rutaCompleta));
  }
    return (
    <>
    <button 
    className="btn btn-outline-primary" 
    size="sm" 
    data-toggle="tooltip" 
    data-placement="top"
    title="Editar encabezado ensayo"
    onClick={handleShow}><PencilSquare /></button>
    <Modal show={show} fullscreen={true} onHide={handleClose} size="lg" scrollable={true}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Editar {props.titulo}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="form-principal" >
            <Container>
            <Row>
            <Col xs={15} md={10} >
                <h4>
                  {/* Texto donde va el codigo ensayo */}
                  <Badge bg="secondary">{labelCodEnsayo}</Badge>
                  </h4>
                  <EditarCodEnsayo 
                  codEnsayo={labelCodEnsayo} 
                  setLabelCodEnsayo={setLabelCodEnsayo} 
                  rutaCompleta={rutaCompleta} 
                  setRuta={props.setRuta} 
                  setFormCliente={setFormCliente}
                  />
                  
            </Col>
              <Col xs={3} md={2} >
                <Button variant="primary" onClick={emitir} >Emitir Certificado</Button>
              </Col>
            </Row>
            <hr />
              <Row>
                <Col xs={6} md={4} >
                    <Form.Group className="mb-3" controlId="form-14">
                    <Form.Label><span className="campo">Cliente</span></Form.Label>
                      
                        <SelectParamTest parametro="cliente" valorDefault={formCliente.cliente} cambioEnForm={cambioEnForm} />
                     
                    <p><span className = "mensaje-error">{errorForm?.cliente}</span></p>
                    </Form.Group>
                    
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group  className="mb-3" controlId="form-16">
                    <Form.Label><span className="campo">Sucursal</span></Form.Label>
                        <SelectParamTest parametro="sucursal" valorDefault={formCliente.sucursal} cambioEnForm={cambioEnForm} />
                    <p><span className = "mensaje-error">{errorForm?.sucursal}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-17">
                    <Form.Label><span className="campo">Negocio</span></Form.Label>
                        <SelectParamTest parametro="negocio" valorDefault={formCliente.negocio} cambioEnForm={cambioEnForm} />
                    <p><span className = "mensaje-error">{errorForm?.negocio}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-1">
                    <Form.Label><span className="campo">Temperatura</span></Form.Label>
                    <Form.Control
                      defaultValue={formCliente.temperatura}
                      name="temperatura"
                      type="text"
                      placeholder="T°"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.temperatura}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-2">
                    <Form.Label><span className="campo">Humedad</span></Form.Label>
                    <Form.Control
                      defaultValue={formCliente.humedad}
                      name="humedad"
                      type="text"
                      placeholder="°C"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.humedad}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="form-3">
                    <Form.Label><span className="campo">Técnico</span></Form.Label>
                        <SelectParamTest parametro="tecnico" valorDefault={formCliente.tecnico} cambioEnForm={cambioEnForm} />
                      <p><span className = "mensaje-error">{errorForm?.tecnico}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                    <Form.Group className="mb-3" controlId="clase">
                    <Form.Label><span className="campo">Seleccione Patrón</span></Form.Label>
                        <SelectParamTest parametro="patron" valorDefault={formCliente.patron} cambioEnForm={cambioEnForm} />
                    <p><span className = "mensaje-error">{errorForm?.patron}</span></p>
                    </Form.Group>
                </Col>
                {/******************************************************** */}
                {/*********************   FECHA EJECUCION***************** */}
                <Col xs={6} md={4}>
                  <Form.Label><span className="campo">Fecha ejecución</span></Form.Label>
                    <Form.Group  controlId="fec_ej">
                      <Form.Control value={formCliente.fecha_ejecucion} type="date" name="fecha_ejecucion" placeholder="" onChange={cambioEnFecha} />
                      <p><span className = "mensaje-error">{errorForm?.fecha_ej}</span></p>
                    </Form.Group>
                </Col>
                {/******************************************************** */}
                <Col xs={6} md={4}>
                  <Form.Label><span className="campo">Fecha ingreso</span></Form.Label>
                  <Form.Group  controlId="fec_in">
                      <Form.Control value={formCliente.fecha_ingreso} max={maxFechaIn} type="date" name="fecha_ingreso" placeholder="Date of Birth" onChange={cambioEnFechaIn} />
                      <p><span className = "mensaje-error">{errorForm?.fecha_in}</span></p>
                  </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col xs={6} md={4}>
                  <Form.Group className="mb-3" controlId="form-4">
                    <Form.Label><span className="campo">Documento de Referencia</span></Form.Label>
                    <Form.Control
                     
                      defaultValue={formCliente.orden_compra}
                      name="orden_compra"
                      type="text"
                      placeholder="Documento"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.orden_compra}</span></p>
                    </Form.Group>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="form-5">
                    <Form.Label><span className="campo">Dirección del ensayo</span></Form.Label>
                    <Form.Control
                      
                      defaultValue={formCliente.dir_ensayo}
                      name="dir_ensayo"
                      type="text"
                      placeholder="Dirección"
                      onChange={cambioEnForm}
                      />
                      <p><span className = "mensaje-error">{errorForm?.dir_ensayo}</span></p>
                    </Form.Group>
                </Col>
            </Row>
            </Container>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Grabar
          </Button>
        </Modal.Footer>
    </Modal>
    </>
    )
}

export default EditarEnsayo;