import React, { useState } from "react";
import ClienteNegocio from "../../data/ClienteNegocio";
import {Dropdown, ButtonGroup, Button} from 'react-bootstrap';
import Header from "../Header";
import Tablas from "../Tablas";
import Guantes from "../../data/Guantes";
import AddGuante from "../AddGuante";
import TipoEpp from "../../data/TipoEpp";
import Ensayos from "../../data/Ensayos";
import TablasForm from "../TablasForm";

function GestorEpp (props) {

  console.log('GestorEpp - props.parametro: ', props.parametro)
  const {datos, columnas, titulo, botonAgregar, nombre_tabla} = TipoEpp(props.parametro);
  //const datos = [{"uno":"1"}, {"uno":"2"}];
  console.log('GestorEpp - datos: ', datos);
  console.log('GestorEpp - columnas: ', columnas);
  console.log('GestorEpp - titulo: ', titulo);
  console.log('GestorEpp - botonAgregar: ', botonAgregar);
  console.log('GestorEpp - nombre_tabla: ', nombre_tabla);
  

  console.log('props.tipo_epp', props.tipo_epp);
  console.log('props.parametro', props.parametro);
  
  return (
    <>
      {columnas&&titulo&&botonAgregar&&nombre_tabla?<TablasForm datos={datos} columnas={columnas} titulo={titulo} botonAgregar={botonAgregar} nombre_tabla={nombre_tabla} setRuta={props.setRuta} />:null}
    </>
  );
};

export default GestorEpp;