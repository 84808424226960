import React, { useState } from 'react';
import '../estilos/Home.css';

import Toast from 'react-bootstrap/Toast';
//import Container from 'react-bootstrap/Container';
import { Row, Col, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
//import Col from 'react-bootstrap/Col';
//import Row from 'react-bootstrap/Row';

import Login from '../componentes/Login';
import Inicio from '../componentes/Inicio';
import useToken from '../componentes/UseToken';
import '../estilos/Login.css'
import f_logo from '../imagenes/logo.bmp';
import logosm1 from '../imagenes/logosm1';


function Home() {

  const [logeado, setLogeado] = useState(false);
  const {token, removeToken, setToken, email, menuInv , menuEns, perfil} = useToken();
  const { logo } = logosm1();
  //console.log('en home: ', email, perfil);

  return (
    <>
    {!token && token!=="" &&token!== undefined?
        <Login cambiarLogin={setLogeado} setToken={setToken} />
    :<Inicio removeToken={removeToken} email={email} menuInv={menuInv} menuEns={menuEns} perfil={perfil} token={token} />}
    </>
  );
};

export default Home;