import React from "react";
import Header from "../Header";
import Tablas from "../Tablas";
//import EppGuantes from "../../data/EppGuantes";
import ElementoEpp from "../../data/ElementoEpp";

export default function AltasEpp (props) {
  console.log('AltasEpp', props.parametro)
  return (
    <>
        <Tablas listado={ElementoEpp} parametro={props.parametro} setRuta={props.setRuta} />
    </>
  );
};
