import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';

function ClienteLista () {

  const [datos, setDatos] = useState([]);

  useEffect(() => {
    console.log('se ejecuta useeffect ClienteLista');
    axios({
      method: "POST",
      url:"/api/listacliente",
    })
    .then((response) => {
      console.log('axios: ', response.data.datos);
      setDatos(response.data.datos);
    }).catch((error) => {
      if (error.response) {
        console.log(error.response)
        console.log(error.response.status)
        console.log(error.response.headers)
        }
    })
  }, []);


  const columnas = [
    {
      Header: 'Id_cliente',
      accessor: 'id_cliente'
    }, {
      Header: 'Nombre',
      accessor: 'nombre'
    }, {
      Header: 'Dirección',
      accessor: 'direccion'
    }
  ];

  const titulo="Listado de Clientes";
  const botonAgregar = "cliList";

  return {
    columnas,
    datos,
    titulo,
    botonAgregar,
  };
};

export default ClienteLista;