import React, { useState } from "react";
import ClienteNegocio from "../../data/ClienteNegocio";
import {Dropdown, ButtonGroup, Button} from 'react-bootstrap';
import Header from "../Header";
import Tablas from "../Tablas";
import Guantes from "../../data/Guantes";
import AddGuante from "../AddGuante";
import TipoEpp from "../../data/TipoEpp";
import Ensayos from "../../data/Ensayos";
import TablasForm from "../TablasForm";
import TiposGenerales from "../../data/TiposGenerales";

function GestorParametros (props) {

  //console.log('GestorParametros - props.parametro: ', props.parametro)
  const {datos, columnas, titulo, botonAgregar, nombre_tabla} = TiposGenerales(props.parametro);
  //const datos = [{"uno":"1"}, {"uno":"2"}];
  /*
  console.log('GestorParametros - datos: ', datos);
  console.log('GestorParametros - columnas: ', columnas);
  console.log('GestorParametros - titulo: ', titulo);
  console.log('GestorParametros - botonAgregar: ', botonAgregar);
  console.log('GestorParametros - nombre_tabla: ', nombre_tabla);
  

  console.log('props.tipo_epp', props.tipo_epp);
  console.log('props.parametro', props.parametro);
  */
  return (
    <>
      {datos&&columnas&&titulo&&botonAgregar&&nombre_tabla?<TablasForm datos={datos} columnas={columnas} titulo={titulo} botonAgregar={botonAgregar} nombre_tabla={nombre_tabla} setRuta={props.setRuta} />:null}
    </>
  );
};

export default GestorParametros;