import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Dropdown, ButtonGroup} from 'react-bootstrap';
import '../estilos/AddNegocio.css';
import SelectParam from "./selectores/SelectParam";

function AddTipoEpp (props) {

  let elemento = "";
  let id_epp = "";
  let nombre_epp = "";
  let tabla_tipo_epp = "";
  if (props.nombre_tabla.indexOf("/") >= 0) {
    var k = props.nombre_tabla.split("/")
    elemento = k[0];
    id_epp = k[1];
    nombre_epp = k[2];
    tabla_tipo_epp = k[3];
  };

  let valor = '';
  props.columnas.map((e) => {return e.accessor;}).forEach(function(e, idx) {
    //console.log('tColumna', response.data.headers[idx])
    if (valor === ''){
      valor=valor.concat('"',e,'":""');
    }else {
      valor=valor.concat(',"',e,'":""');
    }
  })
  valor="{".concat(valor, "}")

  const [formEpp, setFormEpp] = useState(JSON.parse(valor));
  const [formEppError, setFormEppError] = useState(JSON.parse(valor));
  const [formConError, setFormConError] = useState();

  const campos = props.columnas.map((e) => {return e.accessor;}).filter((e) => {return e.substring(0,3) !== "max";}).map((f) => {return f;});

  //console.log('AddTipoEpp', props.columnas);
  const [show, setShow] = useState(false);

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);

  function cambioEnForm (event) {
    const {value, name} = event.target;
    setFormEpp(prevNote => ({...prevNote, [name]: value}))
    setFormConError(null);
    //console.log('cambio en form')
  }

  function handleClose () {
    setShow(false);
  }
  function handleShow () {
    setFormEpp(JSON.parse(valor));
    setFormEppError(JSON.parse(valor));
    setFormConError(null);
    setShow(true);
  }

  function grabaForm () {
    //console.log(formEpp);
    let hay = false;
    for (const [key, value] of Object.entries(formEpp)) {
      //console.log(`${key}: ${value}`);
      if (key !== 'num'){
        if (!value) {
          hay = true;
          setFormConError(key);
        }
      }
    }
    if (!hay) {
      //no hay errores, grabar el dato
      const dataForm = {
        tabla_tipo: tabla_tipo_epp,
        datos: JSON.stringify(formEpp).trim(),
      }
      //console.log(dataForm);
      axios({
        method: "POST",
        url:"/api/grabatipoepp",
        data: dataForm
      })
      .then((response) => {
        //console.log('axios: ', response.data.datos);
      }).catch((error) => {
        if (error.response) {
          //console.log(error.response)
          //console.log(error.response.status)
          //console.log(error.response.headers)
          }
      });
      const aleatorio = Math.floor(Math.random()*1000).toString()
      setShow(false); //cierra la ventana popup
      props.setRuta('/gesepprefresca'.concat('_',aleatorio,'/',props.nombre_tabla));

    }
    //setShow(false);
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        ++Agregar {nombre_epp}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><h3>Agregar {nombre_epp}</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
            {campos.map((e) => (
              e == 'num'?null:
                <>
                <Form.Group className="mb-3" controlId={e}>
                  <Form.Label><span className="campo">Seleccione {e} {nombre_epp}</span>
                  </Form.Label>
                <Form.Select aria-label="Default select example" name={e}  onChange={cambioEnForm}>
                  {e === "largo"?<SelectParam parametro={e.concat("_", nombre_epp.charAt(0).toLowerCase() + nombre_epp.slice(1))} />:
                  <SelectParam parametro={e} />}
                </Form.Select>
                </Form.Group>
                </>
              ))
              }
              {nombre_epp === 'Guante'?
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label><span className="campo">Max Corriente fuga</span></Form.Label>
                <Form.Control
                  name="max_i_fuga"
                  type="text"
                  placeholder=""
                  onChange={cambioEnForm}
                  autoFocus
                />
              </Form.Group>:null
              }
            
          </Form>
          {formConError?<p><span className = "mensaje-error">{"Complete el valor de ".concat(formConError)}</span></p>:null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={grabaForm}>
            Grabar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default AddTipoEpp;