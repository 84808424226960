import React, { useState } from 'react';
import {Modal, Table as TablaBoot} from 'react-bootstrap';
import {Form, Container, Row, Col, InputGroup, Button, Card} from 'react-bootstrap';
import { Trash, PlusSquare, PencilSquare } from 'react-bootstrap-icons';
import SelectUnidadResistencia from './selectores/SelectUnidadResistencia';
import axios from 'axios';

function ListadoDetalleIngresoTpe (props) {
  //console.log(props.datos)
  const [showFormCorriente, setShowFormCorriente] = useState(false);
  const [errorEnIfuga, setErrorEnIfuga] = useState(null);
  //const [valorSerie, setValorSerie] = useState(null);
  // props trae el deshabilita boton props.deshabilitaBotonGrabar
  // props el hook setDeshabilitaBotonGrabar props.setDeshabilitaBotonGrabar

   
  if (props.datos.length >= 6) {
    //llego al máximo valor del listado
    props.setListaDetalleCompleta(true);
  } else {
    props.setListaDetalleCompleta(false);
  }
  


  
  let detalleEnsayo=props.detalleEnsayo;
  let setDetalleEnsayo=props.setDetalleEnsayo;
  
//************************************************************************************ */
//*********************************************************************************** */
  const [formClienteDetalleIngreso, setFormClienteDetalleIngreso] = useState({
    'serie': '',
    'parches': '',
    'tension': '',
    'fuga1': '',
    'resultado': 'RECHAZADO',
    'resistencia_AB': '',
    'resistencia_AC': '',
    'resistencia_AD': '',
    'resistencia_BC': '',
    'resistencia_BD': '',
    'resistencia_CD': '',
    'UnidadResistenciaAB': '',
    'UnidadResistenciaAC': '',
    'UnidadResistenciaAD': '',
    'UnidadResistenciaBC': '',
    'UnidadResistenciaBD': '',
    'UnidadResistenciaCD': '',
    'seccion': '',
  });
//************************************************************************************ */
//*********************************************************************************** */
  const [errorformClienteDetalleIngreso, setErrorformClienteDetalleIngreso] = useState({
    'serie': '',
    'parches': '',
    'tension': '',
    'fuga1': '',
    'resistencia_AB': '',
    'resistencia_AC': '',
    'resistencia_AD': '',
    'resistencia_BC': '',
    'resistencia_BD': '',
    'resistencia_CD': '',
    'UnidadResistenciaAB': '',
    'UnidadResistenciaAC': '',
    'UnidadResistenciaAD': '',
    'UnidadResistenciaBC': '',
    'UnidadResistenciaBD': '',
    'UnidadResistenciaCD': '',
    'seccion': '',
  });
//************************************************************************************ */
//*********************************************************************************** */
  //const [listaDetalleCompletaIngreso, setListaDetalleCompletaIngreso] = useState(false);
  const [ivisualAprobado, setIvisualAprobado] = useState(false);

 

async function validarSerie () {
    let hayError = false;
    let formParaLista = formClienteDetalleIngreso;
    if (!formClienteDetalleIngreso.serie) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalleIngreso.serie <= 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalleIngreso.serie.padStart(5, '0')
          if (a.length > 5) {
            console.log('error serie 2:', a);
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
              //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              /*TODO OK*/
              console.log('concatena 1');
              //formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      }
        if (!hayError) {
            /** Generar el codigo de serie */
            let a = formClienteDetalleIngreso.serie.padStart(5, '0')
            formParaLista['serie'] = props.cod_epp.concat('-', a);
    
            let fuga_max = 5; //default
            await axios({
              method: "POST",
              url:"/api/chequea_epp",
              data: {
                "serie_epp": formParaLista['serie'],
                "cliente": props.formCliente.cliente,
                "negocio": props.formCliente.negocio,
                "sucursal": props.formCliente.sucursal,
              }
            })
            .then((response) => {
              //setValorTec({"valor": response.data.datos[0].tecnico})
              //console.log('llega respuesta de axios')
    
              if (isNaN(response.data.datos)) {
                //console.log('no es numero')
                setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: response.data.datos}))
                hayError = true;
                //console.log('error en guante: ')
              } else {
                  //console.log('Es numero')
                  fuga_max = response.data.datos
                  // boton debe habilitarse
                  props.setDeshabilitaBotonGrabar(false);
              }
    
    
            }).catch((error) => {
              if (error.response) {
                if (error.response.data.msg) {
                  setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
                } else if (error.response.data.datos) {
                  setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
                }
                hayError = true;
                //console.log(error.response)
                //console.log(error.response.status)
                //console.log(error.response.headers)
                }
            });
          }
}

//************************************************************************************ */
//*********************************************************************************** */
  function cambioEnFormDetalleIngreso (event) {
    const {value, name} = event.target;
    if (name === 'serie') {
        //al modificar serie se debe deshabilitar el boton para que se vuelva a validar
        props.setDeshabilitaBotonGrabar(true);
    }
    setErrorEnIfuga(null);
    setErrorformClienteDetalleIngreso({
      'serie': '',
      'parches': '',
      'tension': '',
      'fuga1': '',
      'resistencia_AB': '',
        'resistencia_AC': '',
        'resistencia_AD': '',
        'resistencia_BC': '',
        'resistencia_BD': '',
        'resistencia_CD': '',
        'UnidadResistenciaAB': '',
        'UnidadResistenciaAC': '',
        'UnidadResistenciaAD': '',
        'UnidadResistenciaBC': '',
        'UnidadResistenciaBD': '',
        'UnidadResistenciaCD': '',
        'seccion': '',
    });
    setFormClienteDetalleIngreso(prevNote => ({...prevNote, [name]: value}))
  }
 
//************************************************************************************ */
//*********************************************************************************** */
  async function agregaDetalle () {
    //chequear los datos
    //primero limpiar los errores los errores 
    //console.log('inicia agrega detalle en ListaDetalleIngreso')
    let formParaLista = formClienteDetalleIngreso;
    let hayError = false;
    if (ivisualAprobado) {
      //console.log('aprueba visual')
      if (!formClienteDetalleIngreso.serie) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalleIngreso.serie <= 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalleIngreso.serie.padStart(5, '0')
          if (a.length > 5) {
            console.log('error serie 2:', a);
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
              //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              /*TODO OK*/
              console.log('concatena 1');
              //formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      }
      if (!formClienteDetalleIngreso.parches) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["parches"]: "Ingrese cantidad de parches (0 si no tiene)"}))
        hayError = true;
      } else {
        if (formClienteDetalleIngreso.parches < 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["parches"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }
      if (!formClienteDetalleIngreso.tension) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["tension"]: "Ingrese tensión de ensayo"}))
        hayError = true;
      } else {
        if (formClienteDetalleIngreso.tension < 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["tension"]: "Debe ser mayor o igual a 0"}))
          hayError = true;
        }
      }


    } else {
      console.log('No aprueba visual')
      if (!formClienteDetalleIngreso.serie) {
        setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Ingrese la Serie"}))
        hayError = true;
      }else {
        if (formClienteDetalleIngreso.serie <= 0) {
          setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Debe ser mayor que 0"}))
          hayError = true;
        } else {
          let a = formClienteDetalleIngreso.serie.padStart(5, '0')
          if (a.length > 5) {
            console.log('error serie 1');
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "Deben ser 5 dígitos como máximo"}))
            hayError = true;
          } else {
            //console.log('verifica: ', props.cod_epp.concat('-', a))
            if (detalleEnsayo.filter(e => e.serie == props.cod_epp.concat('-', a)).length) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: "La serie ya se encuentra en la lista"}))
              hayError = true;               
            } else {
              console.log('concatena 2');
              formParaLista['serie'] = props.cod_epp.concat('-', a);
            }
          }
        }
      } 
      if (!hayError) {
        //console.log('no hubo error')
        formParaLista['parches'] = '--';
        formParaLista['tension'] = '--';
        formParaLista['fuga1'] = '--';
        formParaLista['resultado'] = 'RECHAZADO';
      }
    }
      //console.log('encontro error:', hayError, errorformClienteDetalleIngreso, formClienteDetalleIngreso);
      if (!hayError) {
        /** Generar el codigo de serie */
        let a = formClienteDetalleIngreso.serie.padStart(5, '0')
        formParaLista['serie'] = props.cod_epp.concat('-', a);

        let fuga_max = 5; //default
        await axios({
          method: "POST",
          url:"/api/chequea_epp",
          data: {
            "serie_epp": formParaLista['serie'],
            "cliente": props.formCliente.cliente,
            "negocio": props.formCliente.negocio,
            "sucursal": props.formCliente.sucursal,
          }
        })
        .then((response) => {
          //setValorTec({"valor": response.data.datos[0].tecnico})
          //console.log('llega respuesta de axios')

          if (isNaN(response.data.datos)) {
            //console.log('no es numero')
            setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: response.data.datos}))
            hayError = true;
            //console.log('error en guante: ')
          } else {
              //console.log('Es numero')
              fuga_max = response.data.datos
          }


        }).catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: error.response.data.msg}))
            } else if (error.response.data.datos) {
              setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["serie"]: error.response.data.datos}))
            }
            hayError = true;
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        });

        /*
        if (!hayError) {
          if (ivisualAprobado) {

            formParaLista['resultado'] = formClienteDetalleIngreso['resultado']
          }
          const datosNew = [formParaLista, ...detalleEnsayo]
          setDetalleEnsayo(datosNew);
          //setShowDetalle(false);

          setFormClienteDetalleIngreso({})
        } else {
          //console.log('Hubo error');
        }
        */
      }
  }
//************************************************************************************ */
//*********************************************************************************** */
function agregaDetalleAListado (){

  console.log(formClienteDetalleIngreso);

  let hayError = false;
  if (!formClienteDetalleIngreso.seccion) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["seccion"]: "Ingrese la Sección"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.resistencia_AB) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_AB"]: "Complete resistencia AB"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.resistencia_AC) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_AC"]: "Complete resistencia AC"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.resistencia_AD) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_AD"]: "Complete resistencia AD"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.resistencia_BC) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_BC"]: "Complete resistencia BC"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.resistencia_BD) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_BD"]: "Complete resistencia BD"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.resistencia_CD) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_CD"]: "Complete resistencia CD"}))
    hayError = true;
  }

  if (!formClienteDetalleIngreso.UnidadResistenciaAB) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_AB"]: "Complete resistencia AB"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.UnidadResistenciaAC) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_AC"]: "Complete resistencia AC"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.UnidadResistenciaAD) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_AD"]: "Complete resistencia AD"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.UnidadResistenciaBC) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_BC"]: "Complete resistencia BC"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.UnidadResistenciaBD) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_BD"]: "Complete resistencia BD"}))
    hayError = true;
  }
  if (!formClienteDetalleIngreso.UnidadResistenciaCD) {
    setErrorformClienteDetalleIngreso(prevNote => ({...prevNote, ["resistencia_CD"]: "Complete resistencia CD"}))
    hayError = true;
  }
  if (!hayError){
          const datosNew = [formClienteDetalleIngreso, ...detalleEnsayo]
          setDetalleEnsayo(datosNew);
          //setShowDetalle(false);

          setFormClienteDetalleIngreso({'serie': '',
          'parches': '',
          'tension': '',
          'fuga1': '',
          'resultado': 'RECHAZADO',
          'resistencia_AB': '',
          'resistencia_AC': '',
          'resistencia_AD': '',
          'resistencia_BC': '',
          'resistencia_BD': '',
          'resistencia_CD': '',
          'UnidadResistenciaAB': formClienteDetalleIngreso.UnidadResistenciaAB,
          'UnidadResistenciaAC': formClienteDetalleIngreso.UnidadResistenciaAC,
          'UnidadResistenciaAD': formClienteDetalleIngreso.UnidadResistenciaAD,
          'UnidadResistenciaBC': formClienteDetalleIngreso.UnidadResistenciaBC,
          'UnidadResistenciaBD': formClienteDetalleIngreso.UnidadResistenciaBD,
          'UnidadResistenciaCD': formClienteDetalleIngreso.UnidadResistenciaCD,
          'seccion': '',})
  }
}


//************************************************************************************ */
//*********************************************************************************** */
  function GrabaCorriente (){
    //console.log('graba: ', formClienteDetalleIngreso);
    const formData = formClienteDetalleIngreso;
    //formData['fuga1'] = '5.4';
    //formData['resultado'] = 'APROBADO';
    if (formData.resultado=='RECHAZADO'){
      console.log('resulta1: ', formData.resultado);
      if (!formData.fuga1 || isNaN(formData.fuga1)){
        console.log('fuga1: ', formData.fuga1);
        //setErrorEnIfuga('El valor de la corriente no puede ser menor a 0')
        formData['fuga1'] = '';
        const indice = props.datos.findIndex((elemento,idx) => {
          if (elemento.serie == formData['serie']){
            return true;
          }
        });
        let arregloDatos = props.datos;
        arregloDatos[indice] = formData;
        setDetalleEnsayo(arregloDatos);
        setShowFormCorriente(false)
        console.log('uno');
      }else {
        if (formData.fuga1<0){ 
          console.log('fuga1: ', formData.fuga1);
          setErrorEnIfuga('El valor de la corriente no puede ser menor a 0, o deje el campo vacío si no es necesario')
        }else {
          console.log('fuga1: ', formData.fuga1);
          const indice = props.datos.findIndex((elemento,idx) => {
            if (elemento.serie == formData['serie']){
              return true;
            }
          });
          let arregloDatos = props.datos;
          arregloDatos[indice] = formData;
          setDetalleEnsayo(arregloDatos);
          setShowFormCorriente(false)
          console.log('dos');
        }
      }
    }else {
      /** ES APROBADO */
      console.log('resulta2: ', formData.resultado);
      if (!formData.fuga1) {
        console.log('fuga1: ', formData.fuga1);
        setErrorEnIfuga('Debe indicar un valor de corriente')
      }else {
        if (isNaN(formData.fuga1)) {
          console.log('fuga1: ', formData.fuga1);
          setErrorEnIfuga('El valor de la corriente no es válido')
        }else {
          if (formData.fuga1<0){
            console.log('fuga1: ', formData.fuga1);
            setErrorEnIfuga('El valor de la corriente no puede ser menor a 0')
          }else {
            console.log('fuga1: ', formData.fuga1);
            const indice = props.datos.findIndex((elemento,idx) => {
              if (elemento.serie == formData['serie']){
                return true;
              }
            });
            let arregloDatos = props.datos;
            arregloDatos[indice] = formData;
            setDetalleEnsayo(arregloDatos);
            setShowFormCorriente(false)
            console.log('tres');
          }
        }
      }
      
    }
  }
//************************************************************************************ */
//*********************************************************************************** */
  return (
    <>
    {/*LLenar formulario de ingreso datos*/}
    
                  <Container>
                  <Row >
                  <Col xs={15} md={10}>
                    <Form as={Row} >
                    
                        <Col >
                          {/* ************  SERIE EPP *****************/}
                          {/*******************************************/}
                            <Form.Label><span className="campo">Serie Epp</span></Form.Label>
                            <InputGroup >
                              <InputGroup.Text >{props.cod_epp}-</InputGroup.Text>
                                <Form.Control
                                onChange={cambioEnFormDetalleIngreso}
                                name="serie"
                                type="number"
                                placeholder="Serie epp"
                                value={formClienteDetalleIngreso?.serie}
                                autoFocus/>
                            </InputGroup>
                            <p><span className = "msg-ok">{formClienteDetalleIngreso?.serie}</span></p>
                            <p><span className = "mensaje-error">{errorformClienteDetalleIngreso?.serie}</span></p>
                        </Col>
                        
                    </Form>
                    </Col>
                      <Col xs={3} md={2}>
                          {/* ************  BOTON AGREGA DETALLE*****************/}
                          {/*******************************************/}
                          <label >........................................</label>
                          <button disabled={props.listaDetalleCompleta||!props.deshabilitaBotonGrabar} className="btn btn-outline-primary" 
                            onClick={validarSerie}>
                            Validar
                          </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} md={4}>
                          <Form.Label><span className="campo">SECCIÓN (mm)</span></Form.Label>
                          {errorformClienteDetalleIngreso.seccion?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.seccion}</span></p>:null}
                          <InputGroup className="mb-3" >
                                  <Form.Control controlId="seccion" name="seccion" type="text" defaultValue="" 
                                  onChange={cambioEnFormDetalleIngreso} disabled={props.deshabilitaBotonGrabar} 
                                  value={formClienteDetalleIngreso.seccion} />
                          </InputGroup>
                      </Col>
                    </Row>
        <Row >
                <Card >
                    <Card.Header><span className="titulo-card">RESISTENCIA CONTACTO</span></Card.Header>
                </Card>
                </Row>
        <Row>
            <Col >
                    <Form.Label><span className="campo">A - B</span></Form.Label>
                    {errorformClienteDetalleIngreso.resistencia_AB?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.resistencia_AB}</span></p>:null}
                    <InputGroup className="mb-3">
                            <Form.Control disabled={props.deshabilitaBotonGrabar} name="resistencia_AB" value={formClienteDetalleIngreso.resistencia_AB}  type="text" defaultValue="" onChange={cambioEnFormDetalleIngreso} />
                            <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="ab" name="UnidadResistenciaAB" value={formClienteDetalleIngreso.UnidadResistenciaAB} onChange={cambioEnFormDetalleIngreso} >
                                <SelectUnidadResistencia parametro="Unidad" />
                            </Form.Select>
                    </InputGroup>
            </Col>
            <Col >
                    <Form.Label><span className="campo">A - C</span></Form.Label>
                    {errorformClienteDetalleIngreso.resistencia_AC?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.resistencia_AC}</span></p>:null}
                    <InputGroup className="mb-3">
                            <Form.Control disabled={props.deshabilitaBotonGrabar} name="resistencia_AC" value={formClienteDetalleIngreso.resistencia_AC}  type="text" defaultValue="" onChange={cambioEnFormDetalleIngreso} />
                            <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="ac" name="UnidadResistenciaAC" onChange={cambioEnFormDetalleIngreso} >
                                <SelectUnidadResistencia parametro="Unidad" />
                            </Form.Select>
                    </InputGroup>
            </Col>
            <Col >
                    <Form.Label><span className="campo">A - D</span></Form.Label>
                    {errorformClienteDetalleIngreso.resistencia_AD?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.resistencia_AD}</span></p>:null}
                    <InputGroup className="mb-3">
                            <Form.Control disabled={props.deshabilitaBotonGrabar} name="resistencia_AD" value={formClienteDetalleIngreso.resistencia_AD}  type="text" defaultValue="" onChange={cambioEnFormDetalleIngreso} />
                            <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="ad" name="UnidadResistenciaAD" onChange={cambioEnFormDetalleIngreso} >
                                <SelectUnidadResistencia parametro="Unidad" />
                            </Form.Select>
                    </InputGroup>
            </Col>
            <Col >
                    <Form.Label><span className="campo">B - C</span></Form.Label>
                    {errorformClienteDetalleIngreso.resistencia_BC?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.resistencia_BC}</span></p>:null}
                    <InputGroup className="mb-3">
                            <Form.Control disabled={props.deshabilitaBotonGrabar} name="resistencia_BC" value={formClienteDetalleIngreso.resistencia_BC}  type="text" defaultValue="" onChange={cambioEnFormDetalleIngreso} />
                            <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="bc" name="UnidadResistenciaBC" onChange={cambioEnFormDetalleIngreso} >
                                <SelectUnidadResistencia parametro="Unidad" />
                            </Form.Select>
                    </InputGroup>
            </Col>
            <Col >
                    <Form.Label><span className="campo">B - D</span></Form.Label>
                    {errorformClienteDetalleIngreso.resistencia_BD?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.resistencia_BD}</span></p>:null}
                    <InputGroup className="mb-3">
                            <Form.Control disabled={props.deshabilitaBotonGrabar} name="resistencia_BD" value={formClienteDetalleIngreso.resistencia_BD}  type="text" defaultValue="" onChange={cambioEnFormDetalleIngreso} />
                            <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="bd" name="UnidadResistenciaBD" onChange={cambioEnFormDetalleIngreso}  >
                                <SelectUnidadResistencia parametro="Unidad" />
                            </Form.Select>
                    </InputGroup>
            </Col>
            <Col >
                    <Form.Label><span className="campo">C - D</span></Form.Label>
                    {errorformClienteDetalleIngreso.resistencia_CD?<p><span className = "mensaje-error">{errorformClienteDetalleIngreso.resistencia_CD}</span></p>:null}
                    <InputGroup className="mb-3">
                            <Form.Control disabled={props.deshabilitaBotonGrabar} name="resistencia_CD" value={formClienteDetalleIngreso.resistencia_CD}  type="text" defaultValue="" onChange={cambioEnFormDetalleIngreso} />
                            <Form.Select disabled={props.deshabilitaBotonGrabar} aria-label="cd" name="UnidadResistenciaCD" onChange={cambioEnFormDetalleIngreso} >
                                <SelectUnidadResistencia parametro="Unidad" />
                            </Form.Select>
                    </InputGroup>
            </Col>
        </Row>
        <Row>
            <Col xs={6} md={4}>
            {props.listaDetalleCompleta?<p><span className = "msg-error">Lista Completa</span></p>:
            <button disabled={props.listaDetalleCompleta||props.deshabilitaBotonGrabar} className="btn btn-outline-primary" 
                onClick={agregaDetalleAListado}>
                Agregar a la Lista
                </button>}
            </Col>
        </Row>
                  </Container>
                  
                          
      {/* *************    TABLA    ************* */}
      <Container>            
      <TablaBoot striped bordered hover >
        <thead>
        <tr>
            <th>
              num_serie
            </th>
            <th>
              seccion
            </th>
            <th>
              A - B
            </th>
            <th>
              A - C
            </th>
            <th>
              A - D
            </th>
            <th>
              B - C
            </th>
            <th>
              B - D
            </th>
            <th>
              C - D
            </th>
          </tr>
        </thead>
        <tbody>
          {props.datos.map((detalle) => 
            <tr key={detalle.serie} >
            <td>{detalle.serie}</td>
            <td>{detalle.seccion}</td>
            <td>{detalle.resistencia_AB+detalle.UnidadResistenciaAB}</td>
              <td>{detalle.resistencia_AC+detalle.UnidadResistenciaAC}</td>
              <td>{detalle.resistencia_AD+detalle.UnidadResistenciaAD}</td>
              <td>{detalle.resistencia_BC+detalle.UnidadResistenciaBC}</td>
              <td>{detalle.resistencia_BD+detalle.UnidadResistenciaBD}</td>
              <td>{detalle.resistencia_CD+detalle.UnidadResistenciaCD}</td>
            <td> 
              <button onClick={() => {props.borraElementoDetalle(detalle.serie)}} className="btn btn-outline-primary" size="sm"><Trash /></button> 
            </td>
          </tr>
            )}
        </tbody>
      </TablaBoot>
      </Container>
      <Modal show={showFormCorriente} >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              <h3>Ingresar Corriente de fuga</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{formClienteDetalleIngreso.serie}</h3>
            {/* ************  CORRIENTE FUGA *****************/}
            {/*******************************************/}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label><span className="campo">Corriente fuga </span></Form.Label>
            <Form.Control
              value={formClienteDetalleIngreso.fuga1}
              onChange={cambioEnFormDetalleIngreso}
              name="fuga1"
              type="number"
              placeholder="I(mA)"
              autoFocus/>
            </Form.Group>
            <p><span className = "mensaje-error">{errorEnIfuga?errorEnIfuga:null}</span></p>
            {/* ************  APROBADO RECHAZADO*****************/}
            {/*******************************************/}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label><span className="campo">Resultado</span></Form.Label>
              <Form.Select defaultValue={formClienteDetalleIngreso.resultado} name="resultado"  aria-label="Default select example" onChange={cambioEnFormDetalleIngreso} >
                <option value="RECHAZADO">RECHAZADO</option>
                <option value="APROBADO">APROBADO</option>
              </Form.Select>
            </Form.Group>
          </Modal.Body>
        <Modal.Footer>
        <Button variant="primary" onClick={()=>{GrabaCorriente();}}>
              Grabar
          </Button>
          <Button variant="secondary" onClick={()=>{setShowFormCorriente(false); console.log('cinco');}}>
              Cancelar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ListadoDetalleIngresoTpe;