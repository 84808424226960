import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Container, Row, Col} from 'react-bootstrap';
import {Trash} from 'react-bootstrap-icons';
import '../estilos/AddNegocio.css';

function DelCliente (props) {

  //console.log('props delcliente: ', props.celdas);
  const datos = {};
  function cambiaJson () {

  }
  const [show, setShow] = useState(false);

  function handCloseCancel () {
    setShow(false);
  }
  function handCloseGraba () {
    //setShow(false);
    //console.log('funcion guardaForm ' + JSON.stringify(formCliente))
    
    //window.location.reload(false);
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button class="btn btn-outline-primary" size="sm" onClick={handleShow}><Trash /></button>
      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title><h3>Confirma Eliminar Cliente</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form><Container>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">{props.celdas.nombre} </span></Form.Label>
                  </Form.Group>
              </Col>
            </Row>
            </Container></Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handCloseCancel}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handCloseGraba}>
            Si
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

};

export default DelCliente;