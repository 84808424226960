import React, { useState } from "react";
import { Container, Row, Col, Card, ListGroup, Button, Stack } from "react-bootstrap";
//import f_logo from '../../imagenes/logo.bmp';
import logosm1 from '../../imagenes/logosm1';
import axios from 'axios';
import "@fontsource/roboto";
import './IngresoClientes.css';
import Informe01 from "./Informe01";
import Informe02 from "./Informe02";
import Informe03 from "./Informe03";
import Informe04 from "./Informe04";
import AddInstrumento from "../AddInstrumento";

function PasaAmenu (props) {
    if (props.opcion1 == 1) {
        return <Informe01 
        mostrarCabecera={true}
        setMuestraMenu={props.setMuestraMenu} 
        setOpcion1={props.setOpcion1} 
        email={props.email} 
        token={props.token}
        perfil={props.perfil} />
    }else if (props.opcion1 == 2) {
        return <Informe02 
        mostrarCabecera={true}
        setMuestraMenu={props.setMuestraMenu} 
        setOpcion1={props.setOpcion1} 
        email={props.email} 
        token={props.token}
        perfil={props.perfil} />
    }else if (props.opcion1 == 3) {
        return <Informe03 
        mostrarCabecera={true}
        setMuestraMenu={props.setMuestraMenu} 
        setOpcion1={props.setOpcion1} 
        email={props.email} 
        token={props.token}
        perfil={props.perfil} />
    }else if (props.opcion1 == 4) {
        return <Informe04 
        mostrarCabecera={true}
        setMuestraMenu={props.setMuestraMenu} 
        setOpcion1={props.setOpcion1} 
        email={props.email} 
        token={props.token}
        perfil={props.perfil} />
    }else if (props.opcion1 == 5) {
        return <AddInstrumento 
        mostrarCabecera={true}
        setMuestraMenu={props.setMuestraMenu} 
        setOpcion1={props.setOpcion1} 
        email={props.email} 
        token={props.token}
        perfil={props.perfil} />
    }else {
        //console.log('opcion :', props.opcion1)
        return null;
    }
}

function IngresoClientes (props) {
    const { logo } = logosm1();
    const [opcion1, setOpcion1]=useState(0);
    const [muestraMenu, setMuestraMenu] = useState(true);

    function logMeOut() {
        //console.log('entro en logMeOut')
        axios({
          method: "POST",
          url:"/api/logout",
        })
        .then((response) => {
          props.removeToken()
        }).catch((error) => {
          if (error.response) {
            //console.log(error.response)
            //console.log(error.response.status)
            //console.log(error.response.headers)
            }
        })};


    return (
        <>
        {muestraMenu?
        <>
        <Row>
            <Col md={{ span: 8, offset: 4 }}>
            <Card className="text-center" style={{ width: '30rem' }}>
                <Card.Header ></Card.Header>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item><img  src={logo} className='f-logo' alt='Logo inicial' /><span className="letras-negras">Laboratorio Ensayo Equipos Dieléctricos</span></ListGroup.Item>
                </ListGroup>
                <Card.Body>
                    <Card.Title><span className="letras-negras">SISTEMA GESTIÓN LABORATORIO</span></Card.Title>
                    <Card.Text>
                    <span className="letras-negras">MÓDULO CONSULTA CLIENTES</span>
                    </Card.Text>
                    <Stack gap={3} className="col-md-8 mx-auto">
                        <Button onClick={() => {setMuestraMenu(false); setOpcion1(1)}} variant="primary"><span className="letras-blancas">Descargar Informe por elemento</span></Button>
                        <Button onClick={() => {setMuestraMenu(false); setOpcion1(2)}} variant="primary"><span className="letras-blancas">Descarga Masiva de Informes</span></Button>
                        <Button onClick={() => {setMuestraMenu(false); setOpcion1(3)}} variant="primary"><span className="letras-blancas">Estadísticas</span></Button>
                        <Button onClick={() => {setMuestraMenu(false); setOpcion1(4)}} variant="primary"><span className="letras-blancas">Historial de Elementos Ensayados  </span></Button>
                        
                        <Button onClick={() => {setMuestraMenu(false); setOpcion1(5)}} variant="primary"><span className="letras-blancas">Informes de Instrumentos  </span></Button>
                            
                        <Button onClick={logMeOut} variant="primary"><span className="letras-blancas">Salir  </span></Button>
                    </Stack>
                </Card.Body>
                <Card.Footer className="text-muted"></Card.Footer>
            </Card>
            </Col>
        </Row>
        </>
        :null}
        {opcion1?
        <PasaAmenu setMuestraMenu={setMuestraMenu} setOpcion1={setOpcion1} email={props.email} token={props.token} opcion1={opcion1} perfil={props.perfil} />
        :null}
        </>
    )
};

export default IngresoClientes;