import React from "react"
import {useState} from 'react';
import axios from 'axios';
import {Button, Modal, Form, Container, Row, Col} from 'react-bootstrap';
import {Trash} from 'react-bootstrap-icons';
import '../estilos/AddNegocio.css';



function DelParam (props) {

    //console.log('props: ', props);

    const [msgError, setMsgError] = useState();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    let glosa_eliminar = '';
    if (props.nombreTabla === 'tenico'){
        glosa_eliminar = props.celdas.tecnico_ensayo;
    }else if (props.nombreTabla === 'patron') {
        glosa_eliminar = props.celdas.nombre_marca.concat('-', props.celdas.modelo);
    }else if (props.nombreTabla === 'marca') {
      glosa_eliminar = props.celdas.nombre_marca;
    }else if (props.nombreTabla === 'cuerpos_aterramiento') {
      glosa_eliminar = props.celdas.cuerpos;
    }else if (props.nombreTabla === 'cuerpos_pertiga') {
      glosa_eliminar = props.celdas.cuerpos;
    }else if (props.nombreTabla === 'largo_guante') {
      glosa_eliminar = props.celdas.largo_guante;
    }else if (props.nombreTabla === 'modelo_cubrelinea') {
      glosa_eliminar = props.celdas.modelo_cubrelinea;
    }else if (props.nombreTabla === 'largo_manta') {
      glosa_eliminar = props.celdas.largo_manta;
    }else if (props.nombreTabla === 'largo_cubreposte') {
      glosa_eliminar = props.celdas.largo_cubreposte;
    }else if (props.nombreTabla === 'negocio') {
      glosa_eliminar = props.celdas.nombre_negocio;
    }else if (props.nombreTabla === 'cliente') {
      glosa_eliminar = props.celdas.nombre_corto;
    }else if (props.nombreTabla === 'usuario') {
      glosa_eliminar = props.celdas.nombre_usuario;
    }else if (props.nombreTabla === 'modelo_aterramiento') {
      glosa_eliminar = props.celdas.tipo;
    }else if (props.nombreTabla === 'modelo_pertiga') {
      glosa_eliminar = props.celdas.tipo;
    }
    function handCloseCancel () {
        setShow(false);
      }
      function handCloseGraba () {
        //setShow(false);
        //console.log('funcion guardaForm ' + JSON.stringify(formCliente))
        let hayError = false;
        axios.request({
            url: "/api/eliminaparam",
            method: "POST",
            data: {
                "tipo_tabla": props.nombre_tabla.substring(props.nombre_tabla.indexOf("/",1) + 1),
                "id_tabla": props.celdas.num,
                }
            }).then((response) => {
              //setValorTec({"valor": response.data.datos[0].tecnico})
              //setDetalleEnsayo(response.data.datos);
            }).catch((error) => {
              if (error.response) {
                hayError=true;
                setMsgError(error.response.msg)
                //console.log(error.response)
                //console.log(error.response.status)
                //console.log(error.response.headers)
                }
            });
            if (!hayError) {
                //console.log('refresca: ', '/paramrefresca'.concat('_',aleatorio,props.nombre_tabla))
                //console.log('no hubo error', props.nombre_tabla)
                const aleatorio = Math.floor(Math.random()*1000).toString()
                setShow(false); //cierra la ventana popup
                props.setRuta('/paramrefresca'.concat('_',aleatorio,props.nombre_tabla));
            
            } else {
                //console.log('hubo error')
            }
        //window.location.reload(false);
      }

    return (
        <>
        <button className="btn btn-outline-primary" size="sm" onClick={handleShow}><Trash /></button>  
        <Modal show={show} onHide={handleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title><h3>Confirma Eliminar {props.nombreTabla} </h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form><Container>
            <Row>
              <Col  xs={12} md={8}>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label><span className="campo">{glosa_eliminar} </span></Form.Label>
                  </Form.Group>
              </Col>
            </Row>
            </Container></Form>
            <p><span className = "mensaje-error">{msgError?msgError:null}</span></p>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handCloseCancel}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handCloseGraba}>
              Eliminar
            </Button>
        </Modal.Footer>
      </Modal>
      </>
    )   
}

export default DelParam;