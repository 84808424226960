import React from "react";
import axios from 'axios';
import GestorEpp from "./acciones/GestorEpp";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import FormControl from 'react-bootstrap/FormControl';
import Container from "react-bootstrap/esm/Container";
import Button from 'react-bootstrap/Button';
import {Dropdown, ButtonGroup} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {HouseDoorFill} from 'react-bootstrap-icons';
import {GearFill} from 'react-bootstrap-icons';
import {InputCursorText} from 'react-bootstrap-icons';
import {Printer} from 'react-bootstrap-icons';
import {JournalPlus} from 'react-bootstrap-icons';
import {Binoculars} from 'react-bootstrap-icons';
import {CloudDownload} from 'react-bootstrap-icons';
import {PersonCircle} from 'react-bootstrap-icons';
import {BoxArrowDownRight} from 'react-bootstrap-icons';
import {WrenchAdjustable} from 'react-bootstrap-icons';
import {Lightbulb} from 'react-bootstrap-icons';
import {CalendarCheck} from 'react-bootstrap-icons';
import {CloudUpload} from 'react-bootstrap-icons';
import TestNav from "../testcomponentes/TestNav";


function Header (props) {

  //console.log('Header', props.email, props.menuInv);
  //const {dataMenu} = MenuInventario();
  //console.log('datos menu: ', dataMenu);
  //console.log('entro en Header, props.perfil: ',props.perfil);
  function logMeOut() {
    //console.log('entro en logMeOut')
    axios({
      method: "POST",
      url:"/api/logout",
    })
    .then((response) => {
      props.removeToken()
    }).catch((error) => {
      if (error.response) {
        //console.log(error.response)
        //console.log(error.response.status)
        //console.log(error.response.headers)
        }
    })};

  return (
    <>
      <Navbar bg="light" expand="xl">
        <Container fluid>
            < HouseDoorFill color="black" />
          <Navbar.Brand href="/home">Inicio</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-nav-bar" />
          <Navbar.Collapse id="responsive-nav-bar">
            <Nav
              className="me-auto"
              //className="me-auto my-2 my-lg-0"
              //style={{ maxHeight: '100px' }}
              //navbarScroll
            >
                {/* ************************ MANTENEDORES ****************** */}
                {props.perfil=='superusuario'?<>
                          <Nav.Link href="#" disabled>
                          <WrenchAdjustable />
                      </Nav.Link>
                    <NavDropdown title="Mantenedores" id="navbarScrollingDropdown" >
                      <Dropdown as={ButtonGroup} drop="end">
                        <Button variant="light">Epp/Dieléctricos</Button>
                        <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />
                          <Dropdown.Menu>
                            {props.menuInv.map((e) => (
                              <Dropdown.Item key={e.nombre_menu} onClick={() => {props.setRuta("/gesepp".concat('/',e.cod_serie,'/',e.id,'/',e.nombre.charAt(0).toUpperCase() + e.nombre.slice(1), '/' ,e.tabla_detalle))}}>{e.nombre_menu}</Dropdown.Item>
                            )) }
                          </Dropdown.Menu>
                      </Dropdown>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/cliente/cliente")}} >Clientes</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/negocio/negocio")}} >Negocios</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/usuario/usuario")}} >Usuarios</NavDropdown.Item>
                      {/*<NavDropdown.Item onClick={() => {props.setRuta("/negocio")}} >Cliente/Negocio/Sucursal</NavDropdown.Item>*/}
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/tecnico/tecnicos_ensayo")}} >Técnicos</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/patron/patron")}} >Patron</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/marca/marca")}} >Marcas</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/cuerpos_aterramiento/cuerpos_aterramiento")}} >Cuerpos Aterramiento</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/modelo_aterramiento/caracteristica_aterramiento")}} >Modelo Aterramiento</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/cuerpos_pertiga/cuerpos_pertiga")}} >Cuerpos Pertiga</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/modelo_pertiga/caracteristica_pertiga")}} >Modelo Pertiga</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/largo_guante/largo_guante")}} >Largo Guante</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/modelo_cubrelinea/largo_cubrelinea")}} >Modelo Cubrelinea</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/largo_manta/largo_manta")}} >Largo Mantas</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => {props.setRuta("/param/largo_cubreposte/largo_cubreposte")}} >Largo Cubreposte</NavDropdown.Item>
                      
                      <NavDropdown.Divider />
                    </NavDropdown></>:null
                    }


                




              {/**************  INVENTARIOS ********************* */}
              {props.perfil=='superusuario'?<>
              <Nav.Link href="#" disabled>
                    <JournalPlus />
                </Nav.Link>
              <NavDropdown title="Inventarios" id="navbarScrollingDropdown" >
                <Dropdown as={ButtonGroup} drop="end">
                  <Button variant="light">Tipo Epp</Button>
                  <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />
                    <Dropdown.Menu>
                      {props.menuInv.map((e) => (
                        <Dropdown.Item key={e.nombre_menu} onClick={() => {props.setRuta("/guepp".concat('/',e.cod_serie,'/',e.id,'/',e.nombre.charAt(0).toUpperCase() + e.nombre.slice(1), '/' ,e.tabla_detalle))}}>{e.nombre_menu}</Dropdown.Item>
                      )) }
                    </Dropdown.Menu>
                </Dropdown>
                <NavDropdown.Divider />
              </NavDropdown></>:null
                    }





              {/**************** ENSAYOS ************************ */}
              {props.perfil=='superusuario'?<>
              <Nav.Link href="#" disabled>
                <Lightbulb />
              </Nav.Link>
              <NavDropdown title="Ensayos" id="navbarScrollingDropdown" >
                  <Dropdown as={ButtonGroup} drop="end">
                    <Button variant="light">Dieléctrico</Button>
                    <Dropdown.Toggle split variant="light" id="dropdown-split-basic" />
                      <Dropdown.Menu>
                        {props.menuEns.map((e) => (
                          <Dropdown.Item key={e.nombre_menu} onClick={() => {props.setRuta("/EnsayoGuantes".concat('/',e.cod_informe, '/', e.cod_serie,'/',e.id,'/',e.nombre.charAt(0).toUpperCase() + e.nombre.slice(1), '/' ,e.tabla_detalle))}}>{e.nombre_menu}</Dropdown.Item>
                        )) }
                      </Dropdown.Menu>
                  </Dropdown>
                <NavDropdown.Divider />
              </NavDropdown></>:null
                    }




              {/**********************  MENU CLIENTES *******************/}
              <Nav.Link href="#" disabled>
                <CalendarCheck />
              </Nav.Link>
                <NavDropdown title="Menú Clientes" id="navbarScrollingDropdown" >
                  <NavDropdown.Item key="informe01" onClick={() => {props.setRuta('/informe01')}} >Descargar Informes por Elemento</NavDropdown.Item>
                  <NavDropdown.Divider />
                
                  {/*<NavDropdown.Item href="/testnav" >Listado de ensayos</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => {props.setRuta("/param/lista_ensayos/lista_ensayos")}} >Listado de ensayos</NavDropdown.Item>*/}
                  <NavDropdown.Item key="informe02" onClick={() => {props.setRuta('/informe02')}} >Descarga Masiva de Informes</NavDropdown.Item>
                  <NavDropdown.Divider />


                  <NavDropdown.Item key="informe03" onClick={() => {props.setRuta('/informe03')}} >Estadísticas</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item key="informe04" onClick={() => {props.setRuta('/informe04')}} >Historial de elementos ensayados</NavDropdown.Item>
                  <NavDropdown.Divider />
                  
                  <NavDropdown.Item key="informe05" onClick={() => {props.setRuta('/instrumentos')}} >Informe de Instrumentos</NavDropdown.Item>
                <NavDropdown.Divider />
                </NavDropdown>



                <Nav.Link href="#" disabled>
                  <CloudUpload />
                </Nav.Link>
                <NavDropdown title="Instrumentos" id="navbarScrollingDropdown" >
                  <NavDropdown.Item key="Instrumentos" onClick={() => {props.setRuta('/instrumentos')}} >Informe de Instrumentos</NavDropdown.Item>
                </NavDropdown>


            </Nav>
            


            <PersonCircle color="black" size={30} />
            <NavDropdown title={props.email} id="navbarScrollingDropdown" >
               <NavDropdown.Item onClick={logMeOut} ><BoxArrowDownRight />Salir</NavDropdown.Item>
                <NavDropdown.Divider />
              </NavDropdown>
            
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;